import { store } from '@/app/store/store.js';
import { betObj } from '@/components/cashdesk/bet/print/createObj/betObj';

export const getPlaceBet = async (stateData) => {
  let sistemNum = null;
  const variant = store.getters['oddsBasketStore/GET_BET_VARIANT']
  if (Number.isInteger(variant)) {
    sistemNum = variant;
  }

  const betData = betObj({
    betAmount: stateData.betAmount.value,
    betType: store.getters['oddsBasketStore/GET_BET_TYPE'],
    arr: store.getters['oddsBasketStore/ODDS_BASKET'],
    sistemNum: sistemNum,
    acceptOddsChanges: stateData.changeCoefficientVariant.value
  });

  await store.dispatch('oddsBasketStore/SEND_BET', betData);
}
