<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const cashdeskData = computed(() => store.getters["balanceState/BALANCE"]);
const shortMonitorData = computed(() => store.getters['cashdeskConfigStore/SWITCH_MONITOR_VIEW']);

</script>

<template>
  <div class="cashdesk-name-id-container">
    <b>{{ cashdeskData?.name }}</b>
    <span v-if="!shortMonitorData">
      {{ $t("cash_desk_id") }} {{ cashdeskData?.id }}
    </span>
  </div>
</template>
