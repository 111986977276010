
  const betObj = data => {
    const outcomes = data.arr.reduce((arr, item) => {
      const outcomeObj = item.market.reduce((obj, market) => {
        if (market.outcomes.status == 'ACTIVE') {
          obj.push({
            odds: market.outcomes.odds,
            outcomeId: market.outcomes.id
          })
        }
        return obj;
      }, [])
      
      arr.push(...outcomeObj)
      return arr;
    }, [])
    
    const obj = {
      bets: [
        {
          acceptOddsChanges: data.acceptOddsChanges,
          acceptParameterChanges: data.acceptOddsChanges,
          betType: data.betType,
          outcomes: [...outcomes],
          locale: localStorage['lang'],
          betMoney: {
            betAmount: data.betAmount * 100,
            currency: localStorage['currency']
          },
          requestId: 1
        }
      ]
    }
    if (data.sistemNum != null) {
      obj.bets[0].winCombinationSize = data.sistemNum;
    }

    return obj;
  }

  export { betObj }