<script setup>
import { ref, watch, computed, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const invalidBetSlipText = ref(null);
const { t } = useI18n();
const store = useStore();
const betType = computed(() => store.getters['oddsBasketStore/GET_BET_TYPE']);
const outcomeLength = computed(() => store.getters['oddsBasketStore/GET_OUTCOME_LENGTH']);
const emit = defineEmits(['validBetTypeResult']);

const validBetType = () => {
  const sportsbookConfig = store.getters['sportsbookConfigStore/SPORTSBOOK_CONFIG'];
  const maxNumOfOutcomes = sportsbookConfig[betType.value.toLowerCase()]?.maxOutcomes || Infinity;
  const result = store.getters['oddsBasketStore/ODDS_BASKET'].length <= maxNumOfOutcomes;
  console.log('store.getters[ODDS_BASKET]', store.getters['oddsBasketStore/ODDS_BASKET'].length, maxNumOfOutcomes);
  if (result) {
    invalidBetSlipText.value = null;
  } else {
    invalidBetSlipText.value = t('invalid_betslip_text', { num: maxNumOfOutcomes })
  }

  emit('validBetTypeResult', result);
}

watch(
  () => [betType.value, outcomeLength.value, store.getters['oddsBasketStore/ODDS_BASKET']],
  () => {
    validBetType()
  }
)

</script>

<template>
  <p v-if="invalidBetSlipText" class="color-red">{{ invalidBetSlipText }}</p>
</template>
