import { store } from '@/app/store/store';

import { computed } from 'vue';

export const outcomes = computed(() => {
  return store.getters["oddsBasketStore/ODDS_BASKET"].reduce((arr, item) => {
    const outcomeObj = item.market.reduce((obj, market) => {
      if (market.outcomes.status === 'ACTIVE') {
        obj.push({
          odds: market.outcomes.odds,
          outcomeId: market.outcomes.id
        })
      }
      return obj;
    }, [])

    arr.push(...outcomeObj)
    return arr;
  }, [])
})
