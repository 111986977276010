<script setup>
  import { computed, ref, onMounted, defineProps, defineEmits, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { errorCodeObject } from '@/entities';
  import { OutcomeService } from "@atlas/core";

  import { useI18n } from 'vue-i18n';

  import shared from '@/shared';

  const {Cashdesk} = shared.api;

  const {getErrorCode, amountInDollars} = inject('sharedModel');
  const props = defineProps(['lastBet']);
  const emit = defineEmits(['close']);

  const {t} = useI18n();
  const couponData = ref(null);
  const store = useStore();
  const route = useRoute();
  const id = route.params.id;
  const showModal = ref(false);
  const err404 = ref(false);
  const cancellationReason = ref('');
  const betOutcomes = ref([]);
  const cancelTimeout = ref(0);
  const uiSerchValue = ref('');


  const close = () => {
    emit('close')
  };

  const oddsFormat = (odds) => {
    const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
    return OutcomeService.formatOdds(odds, { oddsFormat })//, { zeroes }
  }

  onMounted(async () => {
    if (props.lastBet) {
      uiSerchValue.value = props.lastBet;
      await findBet(props.lastBet)
    }
  });

  let interval;

  const findBet = async (id) => {
    window.clearInterval(interval);

    try {
      await store.dispatch('bet/FIND_CUPON', id);
      couponData.value = store.getters['bet/CUPON_DATA'];
      betOutcomes.value = couponData.value.details.outcomes;
      err404.value = false;
      const timestamp = couponData.value.cancelTime;
      cancelBetTimer(id, timestamp);
    } catch(err) {
      if (getErrorCode(err) == '404') {
        err404.value = true;
        couponData.value = false;
      } else {
        errorCodeObject(err);
      }
    }
  }

  const cancelBet = () => {
    const cashdeskData = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
    Cashdesk.cancelBet({betRequestId: couponData.value.requestId}, cashdeskData.id)
    .then(() => {
      store.dispatch('balanceState/GET_BALANCE_DATA', { id: id, currency: localStorage['currency'] });
      showModal.value = false;
      close();
    })
    .catch(err => {
      errorCodeObject(err);
    })

  }

  const possibleWin = data => {
    if (data.betType != 'SYSTEM') {
      const allOdds = data.outcomes.reduce((odds, item) => {
        return odds = odds * item.odds;
      }, 1)

      return amountInDollars(allOdds * (data.amount))
    } else {
      return amountInDollars(couponData.value.details.betOdds * couponData.value.details.betMoney.betAmount)
    }
  }

  const disabledBtn = computed(() => {
    return couponData.value == null ||
      couponData.value.settlementStatus == null ||
      couponData.value.payoutInfoDto != null ||
      !cancelTimeout.value
  })

  const btnText = computed(
    () => `${ t('cancel_bet') } ${(disabledBtn.value) ? '' : `(${ cancelTimeout.value})`}`
  );

  const cancelBetTimer = (id, timestamp = 0) => {
    const millisToMinutesAndSeconds = (millis) => {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }

    interval = window.setInterval(() => {
      let now = new Date().getTime();
      let timer = timestamp - now;

      if (timer > 0) {
        cancelTimeout.value = millisToMinutesAndSeconds(timer);
      } else {
        cancelTimeout.value = 0;
        window.clearInterval(interval);
      }
    }, 1000);
  }

  const btnsConfig = [
    {
      text: computed(() => t('close')),
      disabled: false,
      variant: 'btn-danger',
      func: () => close(),
      style: { 'width': '100%' },
      icon: null,
      fullW: true,
    },
    {
      text: btnText,
      disabled: computed(() => disabledBtn.value),
      variant: 'btn-brand',
      func: () => showModal.value = true,
      style: { 'width': '100%' },
      icon: null,
      fullW: true,
    },
  ]

  const searchBetConfig = computed(() => ({
    labelText: t('bet_id'),
    validation(value) {
      return  !isNaN(+value);
    },
    model: uiSerchValue,
    autofocus: true
  }));
</script>

<template>
  <div class="body-container body-container-terminal">
      <ui-search
        class="termial-field"
        :config="searchBetConfig"
        @search="findBet"
      />

      <b v-if="err404" class="err-404-container">
        {{ $t('bet_not_found_check_bet_id') }}
      </b>

      <div class="pay-data-container-terminal" v-if="couponData" data-testid="pay-data-container-terminal">
        <h3>{{ $t('bet_info') }}</h3>
        <h5>
          {{  $t(`coupon.${couponData.details.betType.toLowerCase()}`) }} {{ couponData.details.betSize ? couponData.details.betSize : '' }}
          <b :style="couponData.settlementStatus && couponData.settlementStatus === 'WIN' ? {'color': 'green'} : {'color': 'red'}">
            {{ couponData.settlementStatus ? $t(`coupon.${couponData.settlementStatus.toLowerCase()}`) : 'No status' }}
          </b>
        </h5>
        <hr>
        <div class="money-container">
          <div>
            <span>{{ $t('bet_amount') }}</span>  <b>{{ amountInDollars(couponData.details.betMoney.betAmount) }}</b>
          </div>
          <div style="padding: 0 20px">
            <span>
              {{ couponData.settlementStatus == 'UNSETTLED' ? $t('possible_win') : $t('pay_out') }}
            </span>
            <b>
              <template v-if="couponData?.details?.betMoney?.payout != null">
                {{ amountInDollars(couponData.details.betMoney.payout) }}
              </template>
              <template v-else>
                {{ possibleWin(couponData.details) }}
              </template>
            </b>
          </div>
        </div>

        <div class="outcome-container" v-for="outcome in couponData.details.outcomes" :key="outcome.outcomeId">
          <div>
            <div class="flex-between">
              <div>
                <b>{{ outcome.outcomeInfo.localizedOutcomeName ? outcome.outcomeInfo.localizedOutcomeName : outcome.outcomeInfo.outcomeName }}</b>
              </div>
              <b style="padding: 0 20px">{{ oddsFormat(outcome.odds) }}</b>
            </div>

            <div>{{ outcome.outcomeInfo.localizedMarketName ? outcome.outcomeInfo.localizedMarketName : outcome.outcomeInfo.marketName }}</div>

            <div>
              <span>{{ $d(new Date(outcome.outcomeInfo.eventStartTime), 'couponDate') }}&nbsp;</span>
              <b style="color: #6868fd;">{{ outcome.outcomeInfo.localizedEventName ? outcome.outcomeInfo.localizedEventName : outcome.outcomeInfo.eventName }}</b>
            </div>
            <h6 v-if="couponData.details.betType != 'SINGLE'">
              {{ $t('status') }}:
              <span :style="outcome.outcomeInfo.outcomeStatus === 'WIN' ? {'color': 'green'} : {'color': 'red'}">
                {{ $t(`coupon.${outcome.outcomeInfo.outcomeStatus.toLowerCase()}`) }}
              </span>
            </h6>
          </div>
          <div>
            {{ outcome.outcomeInfo.localizedSportName }} |
            {{ outcome.outcomeInfo.localizedCategoryName }} |
            {{ outcome.outcomeInfo.localizedTournamentName }}
          </div>
        </div>

        <hr>
        <div v-if="couponData.details.betType != 'SYSTEM'">{{ $t('total_odds') }}: <b class="grey">{{ oddsFormat(couponData.details.betOdds) }}</b></div>
        <div>{{ $t('bet_time') }}: <b class="grey">{{ $d(new Date(couponData.createTime), 'couponDate') }}</b></div>
        <div>{{ $t('bet_id') }}: <b class="grey">{{ couponData.betId }}</b></div>
        <div style="margin-bottom: 80px">
          <b v-if="couponData.payoutInfoDto">
            {{ $t('pay_out_info') }}:
            <span style="color: red">
                {{ $t('paid_out') }}
              </span>
          </b>
        </div>
      </div>
    </div>

  <div class="btn-container btn-container-terminal">
    <ui-button-factory :buttonFactoryConfig="btnsConfig" />
  </div>

  <ui-popup v-if="showModal" @y="cancelBet" @n="showModal = false">
      <template v-slot:modal-popup-body>
        <div>
          <h4><b>{{ $t("ask_cancellation_reason") }}</b></h4>
          <div class="mistakesContainer">
            <label for="clientM">
              <input class="mistake-radio" type="radio" id="clientM" value="clientMistake" v-model="cancellationReason">
              {{ $t("client_mistake") }}
            </label>
            <label for="cashierM">
              <input class="mistake-radio" type="radio" id="cashierM" value="cashierMistake" v-model="cancellationReason">
              {{ $t("cashier_mistake") }}
            </label>
          </div>
        </div>
      </template>
    </ui-popup>
</template>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
  .print-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
  .pay-data-container {
    padding-top: 10px;
    padding-left: 7px;
  }
  .grey {
    color: grey;
  }
  .body-container {
    overflow: auto;
    height: calc(100% - 37px - 85px);
  }
  .btn-container {
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    border-top: 1px solid #ced4da;
  }
  .btn-container > button {
    width: 100%;
  }
  .body-container-terminal {
    padding: 1rem;
  }
  .btn-container-terminal {
    padding: 1rem;
    width: 100%;
    border: none;
  }

  h3, h5 {
    margin: 0;
  }
  .sunmi-sc-inputs-container {
    padding: 1rem 10px;
  }
  .scan-coupon-container {
    padding: 1px;
  }
  .pay-data-container-terminal {
    background-color: var(--material);
    color: var(--text-for-material);
  }
  .money-container {
    /*width: 50%;*/
    display: flex;
  }
  .money-container > div {
    display: flex;
    flex-direction: column;
  }
  .outcome-container {
    /*padding: 10px 0;*/
    background-color: var(--bg);
    margin-bottom: 8px;
  }
  .outcome-container > div {
    /*background: #f8f9fa;*/
    padding: 5px;
  }
  .bottom-btn-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 5px 0;
    background: #1c1e2b;
  }
  .err-404-container {
    color: #f66;
    font-weight: bold;
    text-align: center;
  }
  .deposit-termial-field {
    width: 100%;
  }
</style>
