<script setup>
import { computed, ref, watch, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import { getPlaceBet, getBetResp } from '../model';

const store = useStore();
const { t } = useI18n();
const props = defineProps(['isValidBetType']);
const emit = defineEmits(['betFinished']);
const loading = ref(false);

const stateData = {
  betAmount: computed(() => store.getters['oddsBasketStore/GET_BET_AMOUNT']),
  outcomeLength: computed(() => store.getters['oddsBasketStore/GET_OUTCOME_LENGTH']),
  changeCoefficientVariant: computed(() => store.getters['oddsBasketStore/GET_CHANGE_COEFFICIENT']),
  inactiveOdds: computed(() => store.getters['oddsBasketStore/GET_HAS_INACTIVE_ODDS']),
  betVariant: computed(() => store.getters['oddsBasketStore/GET_BET_VARIANT']),
  couponData: computed(() => store.getters['couponStore/COUPON_DATA']),
  error: computed(() => store.getters['notificationsStore/ERROR_DATA'])
}

const placeBetBtnMessage = computed(() => {
  let message;
  const changedCoefficientArr = store.getters['oddsBasketStore/CHANGE_COEFFICIENT_ARR'];
  const findDown = changedCoefficientArr.find((i) => i === 'DOWN');

  if (stateData.betAmount.value === 0) {
    message = t('enter_amount_to_bet');
  } else if (stateData.changeCoefficientVariant.value === 'NO' && changedCoefficientArr.length) {
    message = t('accept_odds_to_bet');
  } else if (stateData.changeCoefficientVariant.value === 'UP' && findDown) {
    message = t('accept_odds_to_bet');
  } else if (loading.value) {
    message = '';
  } else {
    message = t('place_bet');
  }

  return message;
})

const amountState = computed(() => {
  const t = /^(?!0\d)(\d{0,6}(?:\.\d{1,2})?)$/g;
  return !!stateData.betAmount.value === true
    && stateData.betAmount.value !== '0'
    && t.test(stateData.betAmount.value);
})

const disabledButton = computed(
  () =>
    loading.value
    || stateData.outcomeLength.value === 0
    || stateData.inactiveOdds.value
    || !amountState.value
    || !props.isValidBetType
);

const startPlaceBet = async () => {
  if (amountState.value && store.getters["oddsBasketStore/ODDS_BASKET"].length) {
    loading.value = true;
    await getPlaceBet(stateData);
    await getBetResp();
  }
}

watch(
  () => [stateData.couponData.value, stateData.error.value],
  () => {
    if (!stateData.couponData.value || stateData.error.value) {
      loading.value = false;
      emit('betFinished');
    }
})

const baseBtnConfig = {
  text: computed(() => placeBetBtnMessage.value),
  variant: 'btn-brand',
  func: startPlaceBet,
  disabled: computed(() => disabledButton.value)
}
</script>

<template>
  <ui-button :baseBtnConfig="baseBtnConfig" >
    <template v-if="loading" v-slot:button-body>
      <ui-sm-spinner></ui-sm-spinner>
    </template>
  </ui-button>
</template>
