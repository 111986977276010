import { store } from '@/app/store/store';
import { getSiteAdress } from './getSiteAdress';
import i18n from '@/app/i18n.js';
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';

const dateTimeFormat = new Intl.DateTimeFormat('eu', {
  year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"
});

export const createWithdrawTemplate = (data) => {
  const auth = store.getters['cashierStore/AUTH'];
  const cashdeskData = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
  const logo = store.getters['cashdeskConfigStore/GET_LOGO'];
  const rows = [];

  if(logo && logo.logo) {
    rows.push(
      {
        type: 'logo',
        content: logo,
        yPadding: 0,
      }
    )
  }

  rows.push(
    {
      type: 'text',
      content: getSiteAdress(auth.sessionProject),
      prevContentHeight: logo && logo.height,
      yPadding: 1,
      xPadding: 1,
      font: 'titleBold',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: cashdeskData.betShopDto.name + ', ' + cashdeskData.betShopDto.address,
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: i18n.global.t('withdrawal_receipt').toUpperCase(),//i18n.global.t('recharge_rec').toUpperCase(),
      yPadding: 2,
      xPadding: 1,
      font: 'fontBold',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('transaction_id')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: data.respData.correlationId
    }
  )

  rows.push(
    {
      type: 'text',
      content: data.respData.correlationId,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  const date = dateTimeFormat.format(new Date());

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('date')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: date
    }
  )

  rows.push(
    {
      type: 'text',
      content: date,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('client_id')}:`,
      yPadding: 4,
      xPadding: 1,
      font: 'fontBold',
      position: null,
      sameRowData: data.respData.clientId
    }
  )

  rows.push(
    {
      type: 'text',
      content: data.respData.clientId,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('withdrawal_amount')}:`,
      yPadding: 4,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: amountInDollars(data.respData.amount)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(data.respData.amount),
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('tax')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: amountInDollars(tax)
    }
  )

  //TODO
  const tax = data.tax || 0;

  rows.push(
    {
      type: 'text',
      content: amountInDollars(tax),
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('paid_out')}:`,
      yPadding: 4,
      xPadding: 1,
      font: 'fontBold',
      position: null,
      sameRowData: amountInDollars(data.respData.amount - tax)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(data.respData.amount - tax),
      yPadding: 0,
      xPadding: 1,
      font: 'fontBold',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: '',
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: null
    }
  )


  return rows;
}
