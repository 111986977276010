<script setup>
 import { useStore } from 'vuex';
 import { login } from '@/widgets';

 const store = useStore();

 store.commit('balanceState/BALANCE_DATA', null);
 store.commit('oddsBasketStore/CLEAR_ODDS_BASKET', null);
 store.commit('oddsBasketStore/BOOKING_CHANGE', false);
 store.commit('cashierStore/AUTH_DATA', {});
</script>

<template>
  <component :is="login" />
</template>
