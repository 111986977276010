<script setup>
import {
  computed,
  defineEmits
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { d } = useI18n();
const store = useStore();
const eventData = computed(() => store.getters['eventState/GET_EVENT_DATA']?.eventData);
const emit = defineEmits(['goBack'])
const formatDate = (date) => {
  const dat = new Date(date);
  return `<div>${d(dat, 'shortDate')}</div><div><b>${d(dat, 'time')}</b></div>`;
}

const goBack = () => emit('goBack');
</script>

<template>
  <div v-if="eventData">
      <div class="items-base-container">
        <div class="header-data_left_side flex-between">
          <div class="go-back-to-list" @click="goBack">
            <svg>
              <use xlink:href="../../../shared/assets/back-arrow.svg#back-arrow"></use>
            </svg>
          </div>
          <div>
            <h6>{{ `${eventData.sportName}-${eventData.categoryName}-${eventData.tournamentName}` }}</h6>
            <h5>
              {{ eventData.name }}
            </h5>
          </div>
        </div>

        <div v-if="eventData.sportService === 'LIVE'">
          <span class="color-red">{{ eventData.sportService }}</span>
          <br>
          <span class="color-blue">{{ eventData.info.score.liveStatus }}</span>
        </div>
        <span v-else v-html="formatDate(eventData.startTime)"></span>
      </div>

      <div v-if="eventData.status !== 'ACTIVE'">
        <h3 class="color-red">{{ $t('event') }} {{ eventData.status.toLowerCase() }}</h3>
      </div>
    </div>
</template>

<style scoped>
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
</style>


