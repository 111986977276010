import { store } from '@/app/store/store';
import { getSiteAdress } from './getSiteAdress';
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';
import i18n from '@/app/i18n.js';

const dateTimeFormat = new Intl.DateTimeFormat('eu', {
  year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"
});

export const createDepositTemplate = (data) => {
  const auth = store.getters['cashierStore/AUTH'];
  const cashdeskData = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
  const logo = store.getters['cashdeskConfigStore/GET_LOGO'];
  const rows = [];

  if (logo?.logo) {
    rows.push(
      {
        type: 'logo',
        content: logo,
        yPadding: 0,
      }
    )
  }

  rows.push(
    {
      type: 'text',
      content: getSiteAdress(auth.sessionProject),
      prevContentHeight: logo && logo.height,
      yPadding: 1,
      xPadding: 1,
      font: 'titleBold',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: cashdeskData.betShopDto.name + ', ' + cashdeskData.betShopDto.address,
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: i18n.global.t('recharge_rec').toUpperCase(),
      yPadding: 2,
      xPadding: 1,
      font: 'fontBold',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('recharge_rec')} №:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: data.completePaymentId
    }
  )

  rows.push(
    {
      type: 'text',
      content: data.completePaymentId,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  const date = dateTimeFormat.format(new Date());

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('date')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: date
    }
  )

  rows.push(
    {
      type: 'text',
      content: date,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('client_id')}:`,
      yPadding: 4,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: data.respData.clientId
    }
  )

  rows.push(
    {
      type: 'text',
      content: data.respData.clientId,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  if (data.respData.mail) {
    rows.push(
      {
        type: 'text',
        content: `${i18n.global.t('client_email')}:`,
        yPadding: 2,
        xPadding: 1,
        font: 'fontNormal',
        position: null,
        sameRowData: data.respData.mail
      }
    )

    rows.push(
      {
        type: 'text',
        content: data.respData.mail,
        yPadding: 0,
        xPadding: 1,
        font: 'fontNormal',
        position: 'right'
      }
    )
  }

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('recharge_value')}:`,
      yPadding: 4,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: amountInDollars(data.amount)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(data.amount),
      yPadding: 0,
      xPadding: 1,
      font: 'fontBold',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: '',
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: null
    }
  )


  return rows;
}
