<script setup>
  import { ref, computed, onMounted, onBeforeUnmount, watch, inject, defineEmits } from 'vue';
  import { errorCodeObject, couponPrint } from '@/entities';
  import { createCuoponObj } from '@/components/cashdesk/bet/print/createObj/cuoponObj.js';
  import dateFormat from 'dateformat';

  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';

  const { amountInDollars, getErrorCode} = inject('sharedModel');

  const { t } = useI18n();
  const store = useStore();
  const searchInput = ref(null);
  const completeModal = ref(false);
  const printWithdrawRec = ref(false);
  const correlationSearchValue = ref('');
  const creationDate = ref(null);
  const err403 = ref(false);
  const emit = defineEmits(['close'])

  const formatDate = (date) => dateFormat(date, 'mmm dS h:MM TT');

  const findWithdrawalById = async (id) => {
    store.commit('clientState/WITHDRAWAL_INFO', null);
    store.commit('clientState/CLIENT_DATA', null);
    store.commit('clientState/CORRELATION_ID', id);
    try {
      await store.dispatch('clientState/GET_WITHDRAWAL_INFO_BY_ID', id);
      creationDate.value = formatDate(jsonData.value.creationDate);
      findClientById();
      err403.value = false;
    } catch(err) {
      if (getErrorCode(err) == '403') {
        console.dir(err);
        err403.value = true;
      } else {
        errorCodeObject(err);
      }
    }
  }

  watch(() => store.getters['couponStore/COUPON_DATA'], () => {
    if (store.getters['couponStore/COUPON_DATA']) {
      couponPrint(store.getters['couponStore/COUPON_DATA']);
    }
  })

  const jsonData = computed(() => store.getters['clientState/WITHDRAWAL_INFO']);

  const findClientById = async () => {
    try {
      const {clientId} = jsonData.value;
      await store.dispatch('clientState/FIND_CLIENT_BY_ID', clientId);
    } catch(err) {
      errorCodeObject(err);
    }
  }

  const clientInfo = computed(() => store.getters['clientState/CLIENT_DATA']);

  onMounted(() => {
    //clearData();
    printWithdrawRec.value = localStorage.printWithdraw ? JSON.parse(localStorage.printWithdraw) : false;
  })

  onBeforeUnmount(() => {
    clearData();
  })

  const showWithdrowModal = () => completeModal.value = true;

  const clearData = () => {
    try {
      searchInput.value.clear();
    } catch(err) {
      console.log(err);
    }
    correlationSearchValue.value = '';

    store.commit('clientState/WITHDRAWAL_INFO', null);
    store.commit('clientState/CORRELATION_ID', null);
  }

  const printReceipt = (data) => {
    const printData = createCuoponObj({
      respData: data,
      cuponType: 'withdraw',
    })

    store.commit('couponStore/ADD_COUPON_DATA', printData);
  };

  const complete = async () => {
    try {
      const data = await store.dispatch('clientState/WITHDRAWAL_COMPLETE');
      if (printWithdrawRec.value) {
        printReceipt(data);
      }

      await store.dispatch('balanceState/GET_BALANCE_DATA', {
        id: store.getters['balanceState/BALANCE'].id,
        currency: localStorage['currency']
      });

      completeModal.value = false;
      clearData();
      emit('close');
    } catch(err) {
      errorCodeObject(err);
      completeModal.value = false;
    }
  }

  const togglePrint = (value) => {
    localStorage.setItem('printWithdraw', value);
  }

  const newSearchConfig = computed(() => ({
    validation(value) {
      return  !isNaN(+value) && (!value.toString().includes('.'))
    },
    labelText: t('correlation_id'),
    model: correlationSearchValue,
    autofocus: true
  }));

  const btnsConfig = computed(() => [
    {
      text: t('clear'),
      disabled: false,
      variant: 'btn-danger',
      func: () => clearData(),
      style: { 'width': '100%' },
      icon: null,
      fullW: true,
    },
    {
      text: t('withdraw'),
      disabled: computed(() => !jsonData.value || jsonData.value.status == 'COMPLETED'),
      variant: 'btn-brand',
      func: () => showWithdrowModal(),
      style: { 'width': '100%' },
      icon: null,
      fullW: true,
    }
  ])

  const clientNameField = computed(() => ({
    disabled: true,
    model: ref(clientInfo.value.firstName),
    type: 'text',
    labelText: t('client_name'),
  }));

  const clientSurnameField = computed(() => ({
    disabled: true,
    model: ref(clientInfo.value.lastName),
    type: 'text',
    labelText: t('client_surname'),
  }));

  const clientIdField = computed(() => ({
    disabled: true,
    model: ref(jsonData.value.clientId),
    type: 'text',
    labelText: t('client_id'),
  }));

  const amountField = computed(() => ({
    disabled: true,
    model: ref(jsonData.value.amount),
    type: 'text',
    labelText: t('amount'),
  }));

  const currencyField = computed(() => ({
    disabled: true,
    model: ref(jsonData.value.currency),
    type: 'text',
    labelText: t('currency'),
  }));

  const creationDateField = computed(() => ({
    disabled: true,
    model: ref(creationDate.value),
    type: 'text',
    labelText: t('request_date_time'),
  }));

  const statusField = computed(() => ({
    disabled: true,
    model: ref(jsonData.value.status),
    type: 'text',
    labelText: t('status'),
  }));
</script>

<template>
  <div class="body-container body-container-terminal">
      <div style="height: calc(100% - 25px) !important; overflow: auto">
        <ui-search
          class="withdrawal-termial-field"
          :config="newSearchConfig"
          @search="findWithdrawalById"
        />

        <b v-if="err403" class="grey">
          {{ $t('wrong_bet_shop') }}
        </b>

        <div v-if="jsonData && clientInfo">
          <ui-input class="withdrawal-termial-field" :baseInputConfig="clientNameField"/>

          <ui-input class="withdrawal-termial-field" :baseInputConfig="clientSurnameField"/>

          <ui-input class="withdrawal-termial-field" :baseInputConfig="clientIdField"/>

          <ui-input class="withdrawal-termial-field" :baseInputConfig="amountField"/>

          <ui-input class="withdrawal-termial-field" :baseInputConfig="currencyField"/>

          <ui-input class="withdrawal-termial-field" :baseInputConfig="creationDateField"/>

          <ui-input class="withdrawal-termial-field" :baseInputConfig="statusField"/>

          <div class="print-receipt-container">
            <div class="print-receipt-label">
              {{$t('print')}}
            </div>
            <ui-checkbox
              class="print-receipt"
              id="print-receipt"
              name="print-receipt"
              @switch="printWithdrawRec = $event, togglePrint($event)"
              :state="printWithdrawRec"
            />
          </div>
        </div>
      </div>
    </div>

  <div class="btn-container btn-container-terminal">
    <ui-button-factory :buttonFactoryConfig="btnsConfig" />
  </div>

  <ui-popup
    v-if="completeModal"
    @y="complete"
    @n="completeModal = false"
  >
    <template v-slot:modal-popup-body>
      <h5>{{ $t('complete_withdraw', {amount: amountInDollars(+jsonData.amount * 100)}) }}?</h5>
    </template>
  </ui-popup>
</template>

<style scoped>
  .body-container {
    overflow: auto;
    height: calc(100% - 37px - 85px);
    padding-top: 10px;
  }
  .btn-container {
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    border-top: 1px solid #ced4da;
  }
  .btn-container > button {
    width: 100%;
  }
  .body-container-terminal {
    padding: 1rem;
  }
  .btn-container-terminal {
    padding: 1rem;
    width: 100%;
    border: none;
  }
  .withdrawal-termial-field {
    margin-bottom: 1rem;
  }
  .print-receipt {
    width: 30px;
  }
  .print-receipt-container {
    display: flex;
    align-items: center;
  }
  .print-receipt-label {
    color: var(--text-for-material);
    padding: 10px;
  }
  .grey {
    color: grey;
  }
</style>
