<script setup>
import {
  ref, computed, watch
} from 'vue';
import { useStore } from 'vuex';

const round = (data) => Math.round(data, 2);
const store = useStore();
const eventStoreData = computed(() => store.getters['eventState/GET_EVENT_DATA']?.eventData);
const eventData = ref(null);
watch(
  () => eventStoreData.value,
  () => {
    if (eventStoreData.value.sportService === 'LIVE') {
      eventData.value = eventStoreData.value;
    } else {
      eventData.value = null;
    }
  }
)
</script>

<template>
  <div>
    <table class="base-table_table" v-if="eventData">
      <thead class="base-table_thead">
      <tr class="table-head-tr">
        <th scope="col">

          {{ eventData.info.score.matchClock != null && +eventData.info.score.matchClock > 0
          ? round(+eventData.info.score.matchClock / 60) + ' min'
          : '' }}
        </th>
        <th scope="col" v-for="period in eventData.info.stats.periodInfo" :key="period.matchStatusCode">
          {{ period.periodDescription }}
        </th>
        <th
          scope="col"
          v-if="eventData.info.stats?.home?.yellow_cards != null"
        >
          <div class="flex-center">
            <div class="yellow-card"></div>
          </div>
        </th>
        <th
          scope="col"
          v-if="eventData.info.stats?.home?.red_cards != null"
        >
          <div class="flex-center">
            <div class="red-card"></div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="base-table_tbody">
      <tr>
        <td>
          {{ eventData.participants?.home?.fullName }}
        </td>
        <td v-for="period in eventData.info.stats.periodInfo" :key="period.matchStatusCode">
          {{ period.homeScore }}
        </td>

        <td v-if="eventData.info.stats?.home?.yellow_cards != null">
          {{ eventData.info.stats?.home?.yellow_cards }}
        </td>
        <td v-if="eventData.info.stats?.home?.red_cards != null">
          {{ eventData.info.stats?.home?.red_cards }}
        </td>
      </tr>
      <tr>
        <td>
          {{ eventData.participants?.away?.fullName }}
        </td>
        <td v-for="period in eventData.info?.stats?.periodInfo" :key="period.matchStatusCode">
          {{ period.awayScore }}
        </td>
        <td v-if="eventData.info.stats?.away?.yellow_cards != null">
          {{ eventData.info.stats?.away?.yellow_cards }}
        </td>
        <td v-if="eventData.info.stats?.away?.red_cards != null">
          {{ eventData.info.stats?.away?.red_cards }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

