<template>
  <div class="home-container">
    <div class="inputs-header">{{ $t('place_bet') }}</div>
    <div class="flex-between sunmi-home-inputs-container">
      <ui-input :baseInputConfig="placeBetInputConfig" />
      <ui-button :baseBtnConfig="placeBetBtnConfig" />
    </div>
    <div class="inputs-header">{{ $t('scan_coupon') }}</div>
    <div class="flex-between sunmi-home-inputs-container">
      <ui-input :baseInputConfig="scanCouponInputConfig" />
      <ui-button :baseBtnConfig="scanCouponBtnConfig" />
    </div>

    <div class="flex-between sunmi-home-big-button-container">
      <ui-big-button :bigButtonConfig="moneyTransferBtnConfig" />
      <ui-big-button :bigButtonConfig="transactionsBtnConfig" />
      <ui-big-button :bigButtonConfig="depositBtnConfig" />
      <ui-big-button :bigButtonConfig="withdrawalBtnConfig" />
    </div>

  </div>
  <footer class="footer-logout">
    <ui-button class="btn-primary" :baseBtnConfig="logoutBtnConfig" />
  </footer>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

  export default {
    props: ['componentData'],
    emits: ['goTo'],

    setup(props, { emit }) {
      const store = useStore();
      const { t } = useI18n();

      const placeBetInputConfig = {
        labelText: computed(() => t('enter_booking_id')),//t('place_bet')),
        validation: (value) => !isNaN(value),
        disabled: false,
        inputData: '',
        width: 'calc(100vw - 155px)',
        model: '',
        type: 'number'
      };
      const placeBetBtnConfig = {
        text: computed(() => t('submit')),
        variant: 'btn-brand',
        style: {width: '130px'},
        func: () => {
          if (placeBetInputConfig.validation(placeBetInputConfig.model) && placeBetInputConfig.model !== '') {
            emit('goTo', {
              component: 'sunmi-place-bet',
              inputID: placeBetInputConfig.model,
              componentName: 'place_bet'
            })
          }
        }
      }

      const scanCouponInputConfig = {
        labelText: computed(() => t('bet_id')),
        validation: (value) => !isNaN(value),
        disabled: false,
        model: '',
        width: 'calc(100vw - 155px)',
        type: 'number'
      };

      const scanCouponBtnConfig = {
        text: computed(() => t('scan')),
        variant: 'btn-brand',
        style: {width: '130px'},
        func: () => {
          if (scanCouponInputConfig.validation(scanCouponInputConfig.model) && scanCouponInputConfig.model !== '') {
            emit('goTo', {
              component: 'sunmi-scan-coupon',
              inputID: scanCouponInputConfig.model,
              componentName: 'scan_coupon'
            })
          }
        },
      }

      const logoutBtnConfig = {
        text: computed(() => t('logout')),
        func: () => {
          store.dispatch('cashierStore/LOGOUT_CASHIER');
        },
        style: { width: '130px' },
      }

      const moneyTransferBtnConfig = {
        text: computed(() => t('money_transfer')),
        variant: 'btn-brand',
        img: computed(() => require('@/shared/assets/money_transfer.png')),
        style: { width: 'calc(100vw / 2 - 20px)', 'margin-bottom': '10px' },
        func: () => {
          emit('goTo', {
            component: 'sunmi-money-transfer',
            inputID: '',//scanCouponInputConfig.model,
            componentName: 'money_transfer'
          })
        }
      }

      const transactionsBtnConfig = {
        text: computed(() => t('transaction')),
        variant: 'btn-brand',
        img: computed(() => require('@/shared/assets/transaction_white.png')),
        style: { width: 'calc(100vw / 2 - 20px)', 'margin-bottom': '10px' },
        func: () => {
          emit('goTo', {
            component: 'sunmi-transactions',
            inputID: '',
            componentName: 'transaction'
          })
        }
      }

      const depositBtnConfig = {
        text: computed(() => t('deposit')),
        variant: 'btn-brand',
        img: computed(() => require('@/shared/assets/deposit_image.png')),
        style: { width: 'calc(100vw / 2 - 20px)' },
        func: () => {
          emit('goTo', {
            component: 'deposit',
            inputID: '',
            componentName: 'deposit'
          })
        }
      }

      const withdrawalBtnConfig = {
        text: computed(() => t('withdraw')),
        variant: 'btn-brand',
        img: computed(() => require('@/shared/assets/withdrawal_icon.png')),
        style: { width: 'calc(100vw / 2 - 20px)' },
        func: () => {
          emit('goTo', {
            component: 'withdraw',
            inputID: '',
            componentName: 'withdraw'
          })
        }
      };

      return {
        placeBetInputConfig,
        placeBetBtnConfig,
        scanCouponInputConfig,
        scanCouponBtnConfig,
        logoutBtnConfig,
        moneyTransferBtnConfig,
        transactionsBtnConfig,
        depositBtnConfig,
        withdrawalBtnConfig
      }
    }
  }
</script>

<style>
  .home-container {
    padding: 10px;
  }
  .sunmi-home-inputs-container {
    padding: 1rem 0;
  }
  .footer-logout {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
  }
  .sunmi-home-big-button-container {
    padding: 10px 0;
    flex-wrap: wrap;
  }
  .inputs-header {
    color: #fff;
    font-size: 20px;
    /*padding: 0 10px;*/
    font-weight: bold;
  }
</style>
