import { getSiteAdress } from './getSiteAdress';
import dateFormat from "dateformat";
import couponPhrases from '@/components/cashdesk/bet/print/createObj/couponPhrases.js';
import { store } from '@/app/store/store';
import { OutcomeService } from '@atlas/core';
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';
const JsBarcode = require('jsbarcode');

const oddsFormat = (odds) => {
  const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
  return OutcomeService.formatOdds(odds, { oddsFormat })//, { zeroes }
}

const dateTimeFormat = new Intl.DateTimeFormat('eu', {
  year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"
});

function getEventStartTime(dataLeng, startTime) {
  const time = typeof startTime == 'number'
    ? dateTimeFormat.format(new Date(startTime))
    : startTime;

  return dataLeng.EVENT_TIME + time;
}

function getTournament(outcome) {
  return outcome.sportName
    + " - " + outcome.categoryName
    + " - " + outcome.tournamentName
}

function possibleWin(data) {
  return data.getLang.POSSIBLE_WIN + amountInDollars(data.possibleWin);
}

function betAmount(data) {
  return data.getLang.BET_AMOUNT + amountInDollars(data.betAmount);
}

function beTime(data) {
  return data.getLang.BET_TIME + dateTimeFormat.format(new Date(data.betTime));
}

export const createPlaceBetTemplate = (data) => {
  const lang = localStorage['lang'];

  if (!data.respData) {
    data.respData = {};
    data.respData.details = data.details;
    delete data.details;
  }

  const l = couponPhrases();

  const outcomesArr = data.respData.details.outcomes.reduce((outcome, item) => {
    const eventTime = item.outcomeInfo.eventStartTime ? item.outcomeInfo.eventStartTime : item.outcomeInfo.eventName.replace(/[^/\d]/g, '');
    let score = '';

    if (item.homeScore != null && item.awayScore != null) {
      score = `(${ item.homeScore }:${ item.awayScore })`;
    }

    outcome.push({
      outcomeName: item.outcomeInfo.localizedOutcomeName ? item.outcomeInfo.localizedOutcomeName : item.outcomeInfo.outcomeName,
      marketName: item.outcomeInfo.localizedMarketName ? item.outcomeInfo.localizedMarketName : item.outcomeInfo.marketName,
      eventName: item.outcomeInfo.localizedEventName ? item.outcomeInfo.localizedEventName : item.outcomeInfo.eventName,
      sportName: item.outcomeInfo.localizedSportName ? item.outcomeInfo.localizedSportName : item.outcomeInfo.sportName,
      categoryName:  item.outcomeInfo.localizedCategoryName ? item.outcomeInfo.localizedCategoryName : item.outcomeInfo.categoryName,
      tournamentName: item.outcomeInfo.localizedTournamentName ? item.outcomeInfo.localizedTournamentName : item.outcomeInfo.tournamentName,
      odds: oddsFormat(item.odds),
      eventTime: dateFormat(eventTime, 'dd.mm.yyyy h:MM TT'),
      eventId: item.outcomeInfo.eventId,
      score: score,
      sportService: item.outcomeInfo.sportService
    })
    return outcome
  }, [])
  const cashdeskData = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
  const auth = store.getters['cashierStore/AUTH'];

  if (!localStorage['betId'])
    localStorage.setItem('betId', JSON.stringify(data.respData.betId));
  else
    localStorage['betId'] = JSON.stringify(data.respData.betId);

  const obj = {
    getLang: l,
    betName: data.respData.details.betType ,
    betSize: data.respData.details.betSize ? data.respData.details.betSize : '',
    outcomes: outcomesArr,
    language: lang,
    possibleWin: data.respData.details.amount * data.respData.details.betOdds,
    currency: data.respData.details.betMoney.currency,
    betAmount: data.respData.details.betMoney.betAmount,
    betId: data.respData.betId,
    betTime: data.respData.createTime,
    cashierId: data.userId,
    showTotalOdds:  data.respData.details.betType == 'MULTIPLE' ? true : false,
    totalOdds: data.respData.details.odds,
    betShopNameAndAdress: cashdeskData.betShopDto.name + ', ' + cashdeskData.betShopDto.address,
    siteAdress: getSiteAdress(auth.sessionProject),
    logo: store.getters['cashdeskConfigStore/GET_LOGO'],
  }

  if (data.betInfo) {
    obj.betInfo = l.BET_INFO;
    obj.status = data.respData.details.betStatus
  }

  const rows = [];

  if(obj.logo && obj.logo.logo) {
    const base64 = obj.logo?.base64.split('data:image/png;base64,')[1];
    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'NORMAL_SIZE', 'CENTER'],
          data: base64
        },
        type: 'logo',
        content: obj.logo,
        yPadding: 0,
      }
    )
  }

  rows.push(
    {
      textPrintingSettings: {
        styles: ['NORMAL', 'NORMAL_SIZE', 'CENTER'],
        data: obj.betShopNameAndAdress
      },
      type: 'text',
      content: obj.betShopNameAndAdress,
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: 'center'
    }
  )

  if (obj.betInfo) {
    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'BOLD', 'LEFT'],
          data: obj.betInfo
        },
        type: 'text',
        content: obj.betInfo,
        yPadding: 1,
        xPadding: 1,
        font: 'fontBold',
        position: null
      }
    )
  }

  const type = obj.betInfo ? obj.getLang.TYPE : '';

  rows.push(
    {
      textPrintingSettings: {
        styles: ['NORMAL', 'BOLD', 'LEFT'],
        data: type + obj.getLang[obj.betName] + ' ' + obj.betSize,
      },
      type: 'text',
      content: type + obj.getLang[obj.betName] + ' ' + obj.betSize,
      yPadding: 1,
      xPadding: 1,
      font: 'fontBold',
      position: null
    }
  )

  if (obj.status) {
    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'BOLD', 'LEFT'],
          data: obj.getLang.STATUS + obj.getLang[obj.status],
        },
        type: 'text',
        content: obj.getLang.STATUS + obj.getLang[obj.status],
        yPadding: 1,
        xPadding: 1,
        font: 'fontBold',
        position: null
      }
    )
  }

  for (const outcome of obj.outcomes) {
    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'BOLD', 'LEFT'],
          data: outcome.outcomeName,
        },
        type: 'text',
        content: outcome.outcomeName,
        yPadding: 1,
        xPadding: 1,
        font: 'fontBold',
        position: null,
        sameRowData: outcome.odds
      }
    );

    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'BOLD', 'RIGHT'],
          data: outcome.odds,
        },
        type: 'text',
        content: outcome.odds,
        yPadding: 0,
        xPadding: 1,
        font: 'fontNormal',
        position: 'right'
      }
    );

    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
          data: outcome.marketName,
        },
        type: 'text',
        content: outcome.marketName,
        yPadding: 1,
        xPadding: 1,
        font: 'fontNormal',
        position: null
      }
    )

    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
          data: `${ outcome.score }${ outcome.eventName }`,
        },
        type: 'text',
        content: `${ outcome.score }${ outcome.eventName }`,
        yPadding: 1,
        xPadding: 1,
        font: 'fontNormal',
        position: null
      }
    );

    if (store.getters['PROJECT_ID'] !== 121) {
      rows.push(
        {
          textPrintingSettings: {
            styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
            data: `(${ outcome.eventId })`,
          },
          type: 'text',
          content: `(${ outcome.eventId })`,
          yPadding: 1,
          xPadding: 1,
          font: 'fontNormal',
          position: null
        }
      )
    }

    rows.push(
      {
        type: 'line',
        content: '',
        yPadding: 0.7,
        xPadding: 1,
        font: 'fontBold',
        position: null
      }
    )

    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
          data: getTournament(outcome),
        },
        type: 'text',
        content: getTournament(outcome),
        yPadding: 0.7,
        xPadding: 1,
        font: 'fontNormal',
        position: null
      }
    );

    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
          data: getEventStartTime(obj.getLang , outcome.eventTime),
        },
        type: 'text',
        content: getEventStartTime(obj.getLang , outcome.eventTime),
        yPadding: 1,
        xPadding: 1,
        font: 'fontNormal',
        position: null
      }
    )

    if (outcome.sportService == 'LIVE') {
      rows.push(
        {
          textPrintingSettings: {
            styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
            data: outcome.sportService,
          },
          type: 'text',
          content: outcome.sportService,
          yPadding: 1,
          xPadding: 1,
          font: 'fontNormal',
          position: null
        }
      )
    }

    rows.push(
      {
        type: 'line',
        content: '',
        yPadding: 0.7,
        xPadding: 1,
        font: 'fontBold',
        position: null
      }
    )
  }


  if(obj.showTotalOdds) {
    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
          data: obj.getLang.TOTAL_ODDS + oddsFormat(obj.totalOdds),
        },
        type: 'text',
        content: obj.getLang.TOTAL_ODDS + oddsFormat(obj.totalOdds),
        yPadding: 0.7,
        xPadding: 1,
        font: 'fontNormal',
        position: null
      }
    )

    rows.push(
      {
        type: 'line',
        content: '',
        yPadding: 0.7,
        xPadding: 1,
        font: 'fontBold',
        position: null
      }
    )
  }


  rows.push(
    {
      textPrintingSettings: {
        styles: ['H1', 'BOLD', 'LEFT'],
        data: possibleWin(obj),
      },
      type: 'text',
      content: possibleWin(obj),
      yPadding: 0.7,
      xPadding: 1,
      font: 'titleBold',
      position: null
    }
  )

  rows.push(
    {
      textPrintingSettings: {
        styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
        data: betAmount(obj),
      },
      type: 'text',
      content: betAmount(obj),
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: null
    }
  )

  rows.push(
    {
      type: 'line',
      content: '',
      yPadding: 0.7,
      xPadding: 1,
      font: 'fontBold',
      position: null
    }
  )

  rows.push(
    {
      textPrintingSettings: {
        styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
        data: beTime(obj),
      },
      type: 'text',
      content: beTime(obj),
      yPadding: 0.7,
      xPadding: 1,
      font: 'fontNormal',
      position: null
    }
  )

  rows.push(
    {
      textPrintingSettings: {
        styles: ['NORMAL', 'LEFT', 'BOLD'],
        data: obj.getLang.BET_ID + obj.betId,
      },
      type: 'text',
      content: obj.getLang.BET_ID + obj.betId,
      yPadding: 1,
      xPadding: 1,
      font: 'fontBold',
      position: null
    }
  )

	rows.push(
    {
      textPrintingSettings: {
        styles: ['NORMAL', 'NORMAL_SIZE', 'LEFT'],
        data: obj.getLang.CASHIER_ID + obj.cashierId,
      },
      type: 'text',
      content: obj.getLang.CASHIER_ID + obj.cashierId,
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: null
    }
  )

  if (!obj.betInfo) {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, obj.betId, {format: 'code39', displayValue: false});
    const base64Barcode = canvas.toDataURL('image/png').split('data:image/png;base64,')[1];

    rows.push(
      {
        textPrintingSettings: {
          styles: ['NORMAL', 'NORMAL_SIZE', 'CENTER'],
          data: base64Barcode,
        },
        type: 'barcode',
        content: obj.betId,
        yPadding: 1,
        xPadding: 1,
      }
    )

    if (store.getters['PROJECT_ID'] !== 121) {//121
      rows.push(
        {
          textPrintingSettings: {
            styles: ['NORMAL', 'NORMAL_SIZE', 'CENTER'],
            data: obj.getLang.BOTTOM_TEXT,
          },
          type: 'text',
          content: obj.getLang.BOTTOM_TEXT,
          yPadding: 6,
          xPadding: 1,
          font: 'fontNormal',
          position: 'center'
        }
      )
    } else {
      rows.push(
        {
          textPrintingSettings: {
            styles: ['NORMAL', 'NORMAL_SIZE', 'CENTER'],
            data: ' ',
          },
          type: 'text',
          content: ' ',
          yPadding: 5,
          xPadding: 1,
          font: 'fontNormal',
          position: 'center'
        }
      )
    }
  }

  return rows;
}
