import { useStore } from 'vuex';
import { computed } from 'vue';

export function formatData(data)  {
  const store = useStore();
  const allMainTypes = computed(() => {
    return store.getters['tournamentsStore/GET_SPORT_FORMS']
  });

  return data.reduce((arr, tournamentItem) => {
    arr.push(
      tournamentItem.reduce((eventArr, eventItem) => {
        if (!Object.values(eventItem).length) {
          return eventArr;
        }

        for (let key in eventItem) {
            let obj = {}

            obj.id = key;
            obj.tournamentName = eventItem[key].tournamentName;
            obj.events = Object.values(eventItem[key].events)
              .reduce((eArr, eItem) => {
                const findTournamentByID = store.getters["printLineStore/PRINT_LINE_TOURNAMENT_ARR"]
                    .find(t => t.id === eItem.tournamentId);

                const sortMainMarkets = () => {
                  const markets = allMainTypes.value[findTournamentByID.sportForm.id].MAIN
                    .sort((a, b) => {
                      return a.line - b.line;
                    });

                  return markets.slice(0, 6);
                }

                const sortByOrderPosition = () => {
                    const obj = {};

                    sortMainMarkets().forEach(item => {
                        if (obj[`line${ item.line }`]) {
                            obj[`line${ item.line }`].push(item);
                            obj[`line${ item.line }`].sort((a, b) => a.orderPosition - b.orderPosition);
                        } else {
                            obj[`line${ item.line }`] = [];
                            obj[`line${ item.line }`].push(item);
                        }
                    });

                    return Object.values(obj).reduce((arr, obj) => {
                        arr.push(...obj);
                        return arr;
                    }, []);
                }

                obj.sportFormTemplate = sortByOrderPosition();

                const getMarkets = sortByOrderPosition().reduce((market, item) => {
                  if (eItem.marketTypes[item.marketTypeId]) {
                      const findIsMain = Object.values(eItem.marketTypes[item.marketTypeId].markets)
                          .find(({ isMain }) => isMain);
                      if (findIsMain) {
                          eItem.marketTypes[item.marketTypeId].markets = {
                              [findIsMain.id]: findIsMain
                          }
                      }
                      market.push(eItem.marketTypes[item.marketTypeId]);
                  }
                  return market;
                }, [])

                eArr.push({
                  outright: eItem.outright,
                  name: eItem.name,
                  participants: eItem.participants,
                  sportName: eItem.sportName,
                  categoryName: eItem.categoryName,
                  startTime: eItem.startTime,
                  tournamentName: eItem.tournamentName,
                  id: eItem.id,
                  markets: getMarkets
                })
                return eArr;
              }, [])
          eventArr.push(obj);
      }
        eventArr.forEach((item) => {
            item.events.sort((a, b) => a.startTime - b.startTime)
        })
        return eventArr
      }, [])
    )
    return arr
  }, []);
}
