<script setup>
import {
  ref,
  defineEmits,
  onBeforeUnmount,
  onMounted,
  computed
} from 'vue';
import { useStore } from 'vuex';

import layout from './layout.vue';
import filterEvent from './filterEvent.vue';
import eventHeadInfo from './eventHeadInfo.vue';
import liveScoreTable from './liveScoreTable.vue';

const emit = defineEmits(['goBackToList']);
const store = useStore();

const selectedFilter = ref('all');

const eventId = computed(() =>
  store.getters['eventState/GET_SELECTED_EVENT_ID'] || store.getters['eventState/GET_EVENT_DATA']?.eventData?.id
)

let eventTimeOut = true;

const getEventData = async () => {
  if (eventId.value) {
    await store.dispatch('eventState/GET_EVENT_BY_ID', eventId.value)

    if (eventTimeOut) {
      eventTimeOut = setTimeout( () => getEventData(), 5000);
    }
  }
}

const useFilter = (filter) => selectedFilter.value = filter;

const goBackToList = () => emit('goBackToList');

onMounted(() => {
  getEventData()
});
onBeforeUnmount(() => {
  window.clearTimeout(eventTimeOut);
  eventTimeOut = false;
  store.commit('eventState/SET_EVENT_DATA', null);
  store.commit('eventState/SET_SELECTED_EVENT_ID', null);
})

</script>

<template>
  <div class="event-details-container">
    <eventHeadInfo @goBack="goBackToList" />
    <liveScoreTable />
    <filterEvent @useFilter="useFilter" />
    <layout :selectedFilter="selectedFilter" />
  </div>
</template>
