<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { constants } from '@atlas/core';

const { t } = useI18n();
const store = useStore();
const defaultType = localStorage.getItem('oddsFormat') || 'decimal';

onMounted(() => {
  store.commit('cashdeskConfigStore/ODDS_FORMAT', defaultType);
})

const changeType = (type) => {
  if (type) {
    const isType = type.value ? type.value : type;
    store.commit('cashdeskConfigStore/ODDS_FORMAT', isType);
    localStorage.setItem('oddsFormat', isType);
  }
}
const dropdownConfig = {
  options: computed(() => Object.values(constants.ODDS_FORMAT)
    .sort()
    .map(format => ({ name: t(format), id: format, value: format }))),
  model: ref(defaultType),
}

</script>

<template>
  <ui-dropdown
    :baseDropDown="dropdownConfig"
    @selectedItem="changeType"
  />
</template>
