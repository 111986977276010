<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  headerBalance,
  logoContainer,
  switchMonitorType,
  switchLang,
  logoutButton,
  switchOddsType,
  cashdeskNameId,
  shiftTime,
  themeSwitch
} from '@/features';

//import Header from '@/components/header/Header.vue';

const store = useStore();

const shortMonitorData = computed(() => store.getters['cashdeskConfigStore/SWITCH_MONITOR_VIEW']);

//const appType = computed(() => store.getters['APP_TYPE']);
</script>

<template>
  <div class="dashboard-header-container">
    <div class="dashboard-header__logo-and-name-container">
      <logoContainer />
      <cashdeskNameId />
    </div>

    <div v-if="!shortMonitorData">
      <headerBalance />
    </div>

    <div class="dashboard-header__right_side">
      <shiftTime />
      <ui-navbar>
        <template v-slot:ui-navbar-body>
          <themeSwitch />
          <switchMonitorType />
          <switchLang />
          <switchOddsType />
          <logoutButton  />
        </template>
      </ui-navbar>
    </div>
  </div>
</template>
