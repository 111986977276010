import ERROR_BET from '@/components/cashdesk/bet/print/createObj/errorBet.js';

export const errObj = data => {
  const bodyArr = data.bets[0].violations.reduce((errArr, item) => {
    const findBet = data.bets[0].details.outcomes.find(bet => bet.outcomeId == item.violationInfo.outcomeId);
    if (findBet) {
      errArr.push({
        outcome: findBet
      })
    }
    const m = () => {
      let str;
      switch(item.code) {
        case 'MAX_BET_AMOUNT_EXCEEDED':
          str = `${ ERROR_BET.MAX_BET_AMOUNT_EXCEEDED } ${ (item.violationInfo.maxBetAmount / 100).toFixed(2) }`;
          break;
        case 'MIN_BET_AMOUNT_EXCEEDED':
          str = `${ ERROR_BET.MIN_BET_AMOUNT_EXCEEDED } ${ (item.violationInfo.minBetAmount / 100).toFixed(2) }`;
          break;
        case 'OUTCOME_ODDS_CHANGED':
          str = `${ item.message }, ${ ERROR_BET.NEW_ODDS } ${ item.violationInfo.newOdds }`;
          break;
        case 'OUTCOME_INACTIVE':
          str = ERROR_BET.OUTCOME_INACTIVE;
          break;
        case 'ONLY_SINGLE_OUTCOME':
          str = ERROR_BET.ONLY_SINGLE_OUTCOME;
          break;
        case 'MAX_ODDS_AMOUNT_EXCEEDED':
          str = ERROR_BET.MAX_ODDS_AMOUNT_EXCEEDED;
          break;
        case 'MAX_OUTCOMES_SIZE_EXCEEDED':
          str = ERROR_BET.MAX_OUTCOMES_SIZE_EXCEEDED;
          break;
        case 'MAX_OUTCOMES_SIZE_IN_BLOCK_EXCEEDED':
          str = ERROR_BET.MAX_OUTCOMES_SIZE_IN_BLOCK_EXCEEDED;
          break;
        case 'MAX_BLOCK_SIZE_EXCEEDED':
          str = ERROR_BET.MAX_BLOCK_SIZE_EXCEEDED;
          break
        case 'CLIENT_SELF_EXCLUDED':
          str = ERROR_BET.CLIENT_SELF_EXCLUDED;
          break;
        case 'BET_TYPE_NOT_ALLOWED':
          str = ERROR_BET.BET_TYPE_NOT_ALLOWED;
          break;
        case 'PREMATCH_DELAY_EXCEEDED':
          str = ERROR_BET.PREMATCH_DELAY_EXCEEDED;
          break;
        case 'CASH_OUT_AMOUNT_CHANGED':
          str = ERROR_BET.CASH_OUT_AMOUNT_CHANGED;
          break;
        case 'MAX_CLIENT_WIN_PER_EVENT_EXCEEDED':
          str = ERROR_BET.MAX_CLIENT_WIN_PER_EVENT_EXCEEDED;
          break
        case 'CASH_OUT_NOT_ALLOWED':
          str = ERROR_BET.CASH_OUT_NOT_ALLOWED;
          break;
        case 'EDIT_NOT_ALLOWED':
          str = ERROR_BET.EDIT_NOT_ALLOWED;
          break;
        case 'NOT_ENOUGH_FUNDS':
          str = ERROR_BET.NOT_ENOUGH_FUNDS;
          break;
        case 'ODDS_VALUE_LESS_THEN_MIN':
          str = ERROR_BET.ODDS_VALUE_LESS_THEN_MIN;
          break;
        default:
          str = item.message;
      }
      return str
    }

    errArr.push({
      message: m()
    })
    return errArr;
  }, [])

  return  {
    header: data.rejectMessage,
    body: bodyArr
  }
}
