export function gridFormatData(mainTypes, events) {
  const firstLine = mainTypes.reduce((arr, item) => {
    if (item.line === 1)
      arr.push(item);
    return arr;
  }, []);

  const sortedFirstLine = firstLine.sort((a, b) => a.orderPosition - b.orderPosition);

  const formatOutcome = (obj, betGroups, id, name) => {
    let outcome;
    const findIsMainMarket = Object.values(obj).find(i => i.isMain);

    const sortedOutcomes = (arr) => {
      return arr.sort((a, b) => {
        return a.orderPosition - b.orderPosition;
      });
    }

    if (findIsMainMarket) {
      outcome = sortedOutcomes(Object.values(findIsMainMarket.outcomes));
    } else {
      outcome = sortedOutcomes(Object.values(obj[Object.keys(obj)[0]].outcomes))
    }


    outcome.forEach((o) => {
      let mId;
      const isMain = Object.values(obj).find(i => i.isMain);

      if (isMain) {
        mId = isMain.id;
      } else {
        mId = Object.values(obj)[0].id;
      }
      o.marketId = id;
      o.market_id = mId
      o.marketName = name;
      o.bet_groups = Object.values(betGroups)
    });

    return outcome;
  }

  const formatHeader = (data) => {
    const obj = {
      name: data.name,
      outcomes: formatOutcome(data.markets, data.betGroups, data.id, data.name)
    }
    return obj;
  }

  const formatData = events.reduce((arr, item) => {
    let gridArr = [];
    let headerArr = [];

    sortedFirstLine.forEach((firstLineItem) => {
      const findItemMarketType = Object.values(item.marketTypes)
        .find((market) => {
          return market.id === firstLineItem.marketTypeId
        });

      if (findItemMarketType) {
        headerArr.push(formatHeader(findItemMarketType));
        gridArr.push(...formatOutcome(
          findItemMarketType.markets,
          findItemMarketType.betGroups,
          findItemMarketType.id,
          findItemMarketType.name))
      }
    })

    item.greedHeader = headerArr;
    item.grid = gridArr;

    arr.push(item);
    return arr;
  }, [])
  return formatData;
}
