<script setup>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { locale } = useI18n();

onMounted(() => {
  store.dispatch('cashdeskConfigStore/GET_LANGS')
})

const changeLang = lang => {
  if (typeof lang === 'object') {
    locale.value = lang.name;
  } else {
    locale.value = lang;
  }

  localStorage.setItem('lang', locale.value);
  store.commit('cashdeskConfigStore/SET_NEW_LANG', locale.value);
}

let langs = computed(() => store.getters['cashdeskConfigStore/LANGUAGES']);

const dropdownConfig = {
  options: computed(() => langs.value.map(obj => ({name: obj.language, id: obj.regionId}))),
  baseText: locale.value
}
</script>

<template>
  <ui-dropdown :baseDropDown="dropdownConfig" @selectedItem="changeLang"/>
</template>

