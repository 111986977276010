<script setup>
import { computed } from 'vue';

import { useStore } from 'vuex';

const store = useStore();

const logoSrc = computed(() => store.getters["cashdeskConfigStore/HEADER_LOGO_SRC"])

</script>

<template>
  <div v-if="logoSrc" id="logo">
    <img class="logo" alt="logo" :src="logoSrc"/>
  </div>
</template>
