<template>
  <div class="printcontent">
    <formsPrintData :selectedPeriod="props.selectedPeriod" :arrData="props.printData" />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { useStore } from 'vuex';

import formsPrintData from './formsPrintData.vue';
export default {
  props: ['printData', 'selectedPeriod'],
  emits: ['close'],
  components: {
    formsPrintData
  },
  setup(props, { emit }) {
    const store = useStore();

    nextTick(() => {
      nextTick(() => {
        window.print();
        store.commit('printLineStore/CHANGE_PRINT_LINE_TOURNAMENT_ARR', null);
        emit('close');
      })
    })

    return {
      props
    }
  },
}
</script>

<style scoped>
  @media screen {
    .printcontent{
      display: none;
    }
  }
  @media print {
    .printcontent {
      display: show !important;
    }
  }
</style>
