const getSiteAdress = num => {
  let adress;
  switch(num) {
    case 1: 
      adress = 'Nashbet';
      break;
    case 2:
      adress = 'Jadesportsbet';
      break;
    case 8:
      adress = 'Goodwin';
      break;
    default:
      adress = '';
  }
  return adress;
}

export { getSiteAdress }