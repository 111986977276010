import { gridFormatData } from './gridFormatData';

export function watchData(data, eventsListData, oldListData, mainTypes) {
  // console.log('watchData', data, eventsListData, JSON.parse(JSON.stringify(oldListData)), mainTypes)
  if (eventsListData.value[0].tournamentId !== oldListData.value[0].tournamentId) {
    data.value = oldListData.value;
    return
  }

  const newListData = gridFormatData(
    mainTypes,
    eventsListData.value
  );

  // console.log('newListData', JSON.parse(JSON.stringify(newListData)))

  newListData.forEach((event, index) => {
    const inOld = oldListData.value.find(e => e.id === event.id);
    if (!inOld) {
      oldListData.value.splice(index, 0, event);
    }
  });

  oldListData.value.forEach(oldListDataItem => {
    const findItemId = newListData.find(i => i.id === oldListDataItem.id);
    if (findItemId) {
      oldListDataItem.info = findItemId.info;
      for (let key in findItemId) {
        if (key !== 'grid') {
          oldListDataItem[key] = findItemId[key];
        }
      }
    } else {
      const index = oldListData.value.findIndex(i => i.id === oldListDataItem.id);
      oldListData.value.splice(index, 1);
    }

    oldListDataItem.grid.forEach(oddsObj => {
      if (oddsObj.up) delete oddsObj.up;
      if (oddsObj.down) delete oddsObj.down;
      if (findItemId) {
        let newOdds;

        newOdds = findItemId.marketTypes[oddsObj.marketId]
          ?.markets[oddsObj.market_id]
          ?.outcomes[oddsObj.id];

        // EXPERIMENTAL
        let marketWithIsMain;
        
        try {
          marketWithIsMain = Object.values(findItemId?.marketTypes[oddsObj?.marketId]?.markets).find(m => m.isMain)
        } catch {
          marketWithIsMain = null
        }

        if (marketWithIsMain && oddsObj.market_id !== marketWithIsMain.id) {
          const findOutcomeByOrderPosition = Object.values(marketWithIsMain.outcomes)
            .find(i => i.orderPosition === oddsObj.orderPosition);

          oddsObj.odds = findOutcomeByOrderPosition.odds;
          oddsObj.orderPosition = findOutcomeByOrderPosition.orderPosition;
          oddsObj.status = findOutcomeByOrderPosition.status;
          oddsObj.market_id = marketWithIsMain.id;
          oddsObj.id = findOutcomeByOrderPosition.id;
          oddsObj.specifiers = findOutcomeByOrderPosition.specifiers;
          oddsObj.marketName = findItemId.marketTypes[oddsObj.marketId].name;
          oddsObj.name = findOutcomeByOrderPosition.name;
        }

        if (findItemId.marketTypes[oddsObj.marketId] && !oddsObj.market_id && oddsObj.status === 'NONE') {

          const findIsMain = Object.values(findItemId.marketTypes[oddsObj.marketId]
            .markets).find(i => i.isMain);

          const findOutcomeByOrderPosition = Object.values(findIsMain.outcomes)
            .find(i => i.orderPosition === oddsObj.orderPosition );

          oddsObj.bet_groups = findItemId.marketTypes[oddsObj.marketId].betGroups;
          oddsObj.odds = findOutcomeByOrderPosition.odds;
          oddsObj.orderPosition = findOutcomeByOrderPosition.orderPosition;
          oddsObj.status = findOutcomeByOrderPosition.status;
          oddsObj.market_id = findIsMain.id;
          oddsObj.id = findOutcomeByOrderPosition.id;
          oddsObj.specifiers = findOutcomeByOrderPosition.specifiers;
          oddsObj.marketName = findItemId.marketTypes[oddsObj.marketId].name;
          oddsObj.name = findOutcomeByOrderPosition.name;
        }

        if (newOdds) {
          if (newOdds.odds > oddsObj.odds) {
            oddsObj.up = true;
          } else if (newOdds.odds < oddsObj.odds) {
            oddsObj.down = true;
          }
          oddsObj.odds = newOdds.odds;
          oddsObj.status = newOdds.status;
        } else if (!newOdds && !findItemId.marketTypes[oddsObj.marketId]) {
          oddsObj.status = 'NONE';
        }
      }
    })
  })

  // console.log('oldListData.value', JSON.parse(JSON.stringify(oldListData.value)));

  data.value = oldListData.value
}
