<script setup>
import { computed, watch, onMounted, nextTick, ref } from 'vue';
import { useStore } from 'vuex';
import { OutcomeService } from "@atlas/core";
import { SET_TIMEOUT } from "@/shared/constants/constants";

const store = useStore();
const outcomes_overflow = ref(null);

const betSlipData = computed(() => {
  return store.getters["oddsBasketStore/ODDS_BASKET"]
})

const oddsFormat = (odds) => {
  const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
  return OutcomeService.formatOdds(odds, { oddsFormat });
}

const deleteOdds = item => {
  store.commit('oddsBasketStore/ODDS_BASKET_DELETE_ITEM', item);
}

const buildSpecifierStr = (event, market) => {
  const outcomeType = market.outcomes.type;

  return outcomeType
    .replace('{participant2}', event.participants?.away?.shortName)
    .replace('{participant1}', event.participants?.home?.shortName)
    .replace('{specifier}', '').replace('()', '').replace('{participant}', '')
    .replace('{player}', '') + ' ' + market.outcomes.specifiers;
};

let oddsTimeOut;

const watchOdds = async () => {
  if (store.getters["oddsBasketStore/ODDS_BASKET"].length > 0) {
    const filter = store.getters['oddsBasketStore/ODDS_BASKET'].reduce((eventIdArr, item) => {
      eventIdArr.push(item.event_id);
      return eventIdArr;
    }, []);
    try {
      window.clearTimeout(oddsTimeOut);
      await store.dispatch('oddsBasketStore/SELECTED_ODDS', filter)

      oddsTimeOut = setTimeout( async () => {
       await watchOdds();
      }, SET_TIMEOUT);
    } catch(err) {
      console.log(err);
    }
  } else {
    window.clearTimeout(oddsTimeOut);
  }
}

watch(
  () => store.getters["oddsBasketStore/ODDS_BASKET"].length,
  () => {
    watchOdds();

    if (!store.getters["oddsBasketStore/ODDS_BASKET"].length) {
      store.commit('oddsBasketStore/CLEAR_ODDS_CHANGE_ARRAY');
    }

    const div = document.querySelector('.outcomes-overflow');
    if (div) {
      nextTick(() => {
        div.scrollTop = div.scrollHeight;
      })
    }
  }
)


watch(() => outcomes_overflow.value, () => {
  const div = document.querySelector('.outcomes-overflow');

  const callback = () => {
    if (div) {
      div.style.maxHeight = `calc(100vh - (462px + -${ window.scrollY }px))`;

      if ((div.offsetHeight + window.scrollY) >= (window.innerHeight - 140)) {
        const px = '310px';
        div.style.maxHeight = `calc(100vh - ${ px })`;
      }

      nextTick(() => {
        div.scrollTop = div.scrollHeight;
      })
    }
  }

  div ? document.addEventListener('scroll', callback) : document.removeEventListener('scroll', callback);
})

onMounted(() => {
  if (store.getters["oddsBasketStore/ODDS_BASKET"].length) {
    watchOdds();
  }
})


</script>

<template>
  <div
    v-if="betSlipData.length"
    ref="outcomes_overflow"
    class="outcomes-overflow outcomes-overflow_odds-container"
  >
    <div v-for="item in betSlipData" :key="item.marketId" >
      <div
        v-for="market in item.market"
        :key="market.marketId"
        class="betslip-odds-container__item-card"
      >
        <div class="flex-between betslip-odds-container__item-card_head">
          <b>{{ item.event_name }}</b>
          <div @click="deleteOdds({ item: item, marketId: market.marketId,  })">
            <img alt="close" src="../../../shared/assets/close.svg">
          </div>
        </div>
        <div
          class="betslip-odds-container__market-container flex-between"
          :class="{ 'betslip-odds-container__disabled-odds': market.outcomes.status !== 'ACTIVE' }"
        >
          <template v-if="market.outcomes.specifiers">
            <b>{{ buildSpecifierStr(item, market) }}</b>
          </template>
          <b v-else>{{ market.outcomes.name || market.outcomes.shortName || market.outcomes.type }}&nbsp;</b>

          <div>
            <template v-if="market.outcomes.initialOdds">
              <template v-if="market.outcomes.status === 'ACTIVE' && market.outcomes.initialOdds !== market.outcomes.odds">
                <b class="betslip-odds-container__booking-old-odds">
                  {{ oddsFormat(+market.outcomes.initialOdds) }}
                </b>
              </template>
            </template>
            <span v-show="market.outcomes.oddsChange">
              <div v-if="market.outcomes.oddsChange === 'UP'"  class="triangle-up"></div>
              <div v-if="market.outcomes.oddsChange === 'DOWN'" class="triangle-down"></div>
            </span>
            <b
              :class="{
                'color-green': market.outcomes.oddsChange === 'UP',
                'color-red': market.outcomes.oddsChange === 'DOWN'
              }"
            >
              {{ oddsFormat(market.outcomes.odds) }}
            </b>
          </div>
          <img
            v-if="market.outcomes.status !== 'ACTIVE'"
            class="betslip-odds-container__lock-icon"
            src="../../../shared/assets/lock.svg"
            alt="lock"
          />
        </div>
        <div>
          <span v-if="item.event_sportService === 'LIVE'">{{item.event_currentScore}}&nbsp;</span>
          <span>
          {{ market.marketName
            .replace('(Competitor1)', item.participants?.home?.fullName)
            .replace('(Competitor2)', item.participants?.away?.fullName)
            }}
            </span>
        </div>
      </div>
    </div>
  </div>
</template>

