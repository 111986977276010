<script setup>
import { ref, watch, computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import {
  eventDetails,
  eventsGrid,
  eventListByName
} from '@/features';

const store = useStore();
const componentIs = ref(null);
const lastListType = ref(null);

const sportId = computed(() => store.getters['sportsStore/GET_SELECTED_ID']);
const categoryId = computed(() => store.getters['categoriesStore/GET_SELECTED_CATEGORIES_ID']);
const tournamentId = computed(() => store.getters['tournamentsStore/GET_SELECTED_TOURNAMENT_ID']);

const searchData = computed(() => store.getters['eventState/GET_SEARCHED_DATA']);

const eventId = computed(() => store.getters['eventState/GET_SELECTED_EVENT_ID']);

const useComponents = {
  'events-grid': eventsGrid,
  'event-details': eventDetails,
  'event-list-by-name': eventListByName
}

const showGreed = () => {
  componentIs.value = null;
  nextTick(() => componentIs.value = 'events-grid');
}

watch([
  () => sportId.value,
  () => categoryId.value,
  () => tournamentId.value
],
  () => {
    sportId.value && categoryId.value && tournamentId.value
      ? showGreed()
      : componentIs.value = null
  }
)

watch(
  () => searchData.value,
  () => {
    if (searchData.value) {
      componentIs.value = 'event-list-by-name';
    }
  }
)

watch(
  () => eventId.value,
  () => {
    if (eventId.value) {
      componentIs.value = 'event-details';
    }
  }
)

const goToDetails = (id) => {
  lastListType.value = componentIs.value;
  store.commit('eventState/SET_SELECTED_EVENT_ID', id)
}

const goBackToList = () => {
  componentIs.value = lastListType.value;
}

</script>

<template>
  <template v-if="componentIs">
    <component
      :is="useComponents[componentIs]"
      @goToDetails="goToDetails"
      @goBackToList="goBackToList"
    />
  </template>
</template>
