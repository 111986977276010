<script setup>
import { computed, ref, onMounted, defineEmits, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { errorCodeObject } from '@/entities';
import { createCuoponObj } from '@/components/cashdesk/bet/print/createObj/cuoponObj.js';

import { deposit } from '@/features';

import { useI18n } from 'vue-i18n';

import shared from '@/shared';
import { OutcomeService } from "@atlas/core";

const { Cashdesk } = shared.api;

const emit = defineEmits(['close']);

const {amountInDollars, getErrorCode} = inject('sharedModel');

const {t} = useI18n();
const couponData = ref(null);
const store = useStore();
const route = useRoute();
const id = route.params.id;
const showModal = ref(false);
const showDepModal = ref(false);
const clientIdForDep = ref(null);
const err404 = ref(false);
const err404Client = ref(false);
const canBePrinted = ref(null);
const showDeposit = ref(false);
const printPayoutRec = ref(false);
const showDepConfirm = ref(false);
const showPopupModal = ref(false);

const appType = computed(() => store.getters['APP_TYPE']);

const canBePrintedF = async (id) =>  {
  try {
    await store.dispatch('bet/CHECK_PRINT_COPY_STATUS', id);
    canBePrinted.value = store.getters['bet/CAN_BE_PRINTED'];
  } catch(err) {
    console.error(err);
  }
}

onMounted(() => {
  printPayoutRec.value = localStorage.printPayout ? JSON.parse(localStorage.printPayout) : false;
})

const close = () => emit('close');

const findCoupon = async (id) => {
  try {
    await store.dispatch('bet/FIND_CUPON', id);
    couponData.value = store.getters['bet/CUPON_DATA'];
    await canBePrintedF(id);
    err404.value = false;
  } catch(err) {
    if (getErrorCode(err) == '404') {
      err404.value = true;
      couponData.value = false;
    } else {
      errorCodeObject(err);
    }
  }
}

const payoutDep = async (data) => {
  const {clientId, print, jsonData, amount} = data;
  const betId = couponData.value.betId;

  try {
    const resp = await store.dispatch('clientState/PAYOUT_TO_CLIENT_ACCOUNT', {
      clientId,
      betId
    })

    if (print) {
      printDepositReceipt(jsonData, amount, resp.data.id, clientId);
    }
    store.commit('notificationsStore/MESSAGE_INFO_MODAL', t('transaction_success'));
    findCoupon(betId);
  } catch(err) {
    errorCodeObject(err);
  }

  showDeposit.value = false;
}

const printDepositReceipt = (jsonData, amount, id, clientId) => {
  const printData = createCuoponObj({
    respData: {
      ...jsonData,
      clientId
    },
    amount: +amount * 100,
    cuponType: 'deposit',
    completePaymentId: id
  })

  store.commit('couponStore/ADD_COUPON_DATA', printData);
};

const findClient = async (id, event) => {
  try {
    await store.dispatch('clientState/FIND_CLIENT_BY_ID', +id);
    const clientData = store.getters['clientState/CLIENT_DATA'];

    if (clientData) {
      err404Client.value = false;
      showDepModal.value = false;
      showDeposit.value = true;
      showDepConfirm.value = false;
    }
  } catch(err) {
    if (getErrorCode(err) === '404') {
      clientIdForDep.value = '';
      err404Client.value = true;
      event.target.disabled = false;
    } else {
      errorCodeObject(err);
    }
  }
}

const printPayoutReceipt = (data) => {
  const printData = createCuoponObj({
    respData: couponData.value,
    amount: data.payoutAmount,
    cuponType: 'payout',
    id: data.id
  })

  store.commit('couponStore/ADD_COUPON_DATA', printData);
};

const payOut = async () => {
  try {
    const resp = await store.dispatch('clientState/PAYOUT_IN_CASH', { betId: couponData.value.betId });

    if (printPayoutRec.value) {
      printPayoutReceipt(resp.data);
    }
    store.dispatch('balanceState/GET_BALANCE_DATA', { id: id, currency: localStorage['currency'] });
    showModal.value = false;
    close();
  } catch(err) {
    console.error(err)
    errorCodeObject(err);
  }
}

const possibleWin = data => {
  if (data.betType != 'SYSTEM') {
    const allOdds = data.outcomes.reduce((odds, item) => {
      return odds = odds * item.odds;
    }, 1)

    return amountInDollars(allOdds * (data.amount))
  } else {
    return amountInDollars(couponData.value.details.betOdds * couponData.value.details.betMoney.betAmount)
  }
}

const printCoupon = (betInfo) => {
  const printData = createCuoponObj({
    respData: couponData.value,
    possibleWin: possibleWin(couponData.value.details),
    userId: store.getters["cashierStore/USER_DATA"].userId,
    betInfo: betInfo,
    cuponType: 'placeBetCopy'
  })

  store.commit('couponStore/ADD_COUPON_DATA', printData);

  if (canBePrinted.value && !betInfo && appType.value !== 'default') {
    Cashdesk.changeCanBePrinted({
      betId: couponData.value.betId,
      canBePrinted: true
    })
    .then(resp => {
      canBePrinted.value = resp.data.canBePrinted;
    })
  }
}

const goDep = async (event) => {
  let sum = (couponData.value?.details?.betMoney?.payout / 100).toFixed(2);

  if (sum && clientIdForDep.value){
    await findClient(clientIdForDep.value, event);
  } else {
    try {
      event.target.disabled = false;
    } catch(err) {
      console.error(err);
    }
  }
}

const disabledBtn = computed(() => {
  return couponData.value == null ||
    couponData.value.settlementStatus == null ||
    couponData.value.settlementStatus != 'WIN' &&
    couponData.value.settlementStatus != 'VOID' ||
    couponData.value.payoutInfoDto != null
})

const btnText = computed(() => `${ t('pay_out_cash') } ${ couponData.value?.details?.betMoney?.payout
  ? amountInDollars(couponData.value.details.betMoney.payout)
  : ''
}`);

const cashdeskData = computed(() => store.getters["balanceState/BALANCE"]);

const togglePrint = (value) => {
  localStorage.setItem('printPayout', value);
}

const depositData = computed(() => ({
  clientId: clientIdForDep.value,
  sum: (couponData.value?.details?.betMoney?.payout / 100).toFixed(2)
}));

const searchBetConfig = computed(() => ({
  labelText: t('bet_id'),
  validation(value) {
    return  !isNaN(+value);
  },
  model: ref(''),
  autofocus: true
}));

const payOutBtnConfig = {
  text: btnText,
  variant: 'btn-brand',
  disabled: computed(() => disabledBtn.value
    || +(cashdeskData.value.depositNodeBalance / 100).toFixed(2)
    < +(couponData.value?.details?.betMoney?.payout / 100).toFixed(2)),
  func: () => {
    showPopupModal.value = true
  },
  style: {width: '98%', fontSize: '1vw', marginRight: '5px'}
}

const payOutAccountBtnConfig = {
  text: t('pay_out_account'),
  disabled: disabledBtn,
  func: () => showDepConfirm.value = true,
  variant: 'btn-brand',
  style: {width: '98%', fontSize: '1vw'}
}

const printBetInfoBtn = {
  text: computed(() => t('print_bet_info')),
  disabled: computed(() => !couponData.value),
  variant: 'btn-brand',
  func: () => printCoupon(true),
  style: { 'width': '98%', fontSize: '1vw', marginRight: '5px' },
  icon: null,
  fullW: true,
}

const printCopyBtnConfig = {
  text: computed(() => t('print_betslip_copy')),
  func: () => printCoupon(false),
}

const clientIdFieldConfig = {
  labelText: computed(() => t('client_id')),
  model: clientIdForDep,
  style: 'text-align: center',
  type: 'number',
  autofocus: true
}

const oddsFormat = (odds) => {
  const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
  return OutcomeService.formatOdds(odds, { oddsFormat })//, { zeroes }
}
// https://nashbet.test.k8s-hz.atlas-iac.com/api/bettor/sport/bet:search
//https://nashbet.test.k8s-hz.atlas-iac.com/api/bettor/sport/public/bet/870848
</script>

<template>

  <div class="body-container body-container-terminal">
    <div class="flex-between">
      <ui-search
        class="deposit-termial-field"
        :config="searchBetConfig"
        @search="findCoupon"
      />

      <ui-button
        style="width: 75%; margin-left: 20px"
        class="btn-brand-outline"
        :baseBtnConfig="printCopyBtnConfig"
        v-if="canBePrinted"
      />
    </div>

    <div v-if="err404" class="err-404-container">
      {{ $t('bet_not_found_check_bet_id') }}
    </div>

    <div class="pay-data-container-terminal" v-if="couponData" data-testid="pay-data-container-terminal">
      <h3>{{ $t('bet_info') }}</h3>
      <h5>
        {{  $t(`coupon.${couponData.details.betType.toLowerCase()}`) }} {{ couponData.details.betSize ? couponData.details.betSize : '' }}
        <b :style="couponData.settlementStatus && couponData.settlementStatus === 'WIN' ? {'color': 'green'} : {'color': 'red'}">
          {{ couponData.settlementStatus ? $t(`coupon.${couponData.settlementStatus.toLowerCase()}`) : 'No status' }}
        </b>
      </h5>
      <hr>
      <div class="money-container">
        <div>
          <span>{{ $t('bet_amount') }}</span>  <b>{{ amountInDollars(couponData.details.betMoney.betAmount) }}</b>
        </div>
        <div style="padding: 0 20px">
          <span>
            {{ couponData.settlementStatus == 'UNSETTLED' ? $t('possible_win') : $t('pay_out') }}
          </span>
          <b>
            <template v-if="couponData?.details?.betMoney?.payout != null">
              {{ amountInDollars(couponData.details.betMoney.payout) }}
            </template>
            <template v-else>
              {{ possibleWin(couponData.details) }}
            </template>
          </b>
        </div>
      </div>

      <div class="outcome-container" v-for="outcome in couponData.details.outcomes" :key="outcome.outcomeId">
        <div>
          <div class="flex-between">
            <div>
              <b>{{ outcome.outcomeInfo.localizedOutcomeName ? outcome.outcomeInfo.localizedOutcomeName : outcome.outcomeInfo.outcomeName }}</b>
            </div>
            <b style="padding: 0 20px">{{ oddsFormat(outcome.odds) }}</b>
          </div>

          <div>{{ outcome.outcomeInfo.localizedMarketName ? outcome.outcomeInfo.localizedMarketName : outcome.outcomeInfo.marketName }}</div>

          <div>
            <span>{{ $d(new Date(outcome.outcomeInfo.eventStartTime), 'couponDate') }}&nbsp;</span>
            <b style="color: #6868fd;">{{ outcome.outcomeInfo.localizedEventName ? outcome.outcomeInfo.localizedEventName : outcome.outcomeInfo.eventName }}</b>
          </div>
          <h6 v-if="couponData.details.betType != 'SINGLE'">
            {{ $t('status') }}:
            <span :style="outcome.outcomeInfo.outcomeStatus === 'WIN' ? {'color': 'green'} : {'color': 'red'}">
              {{ $t(`coupon.${outcome.outcomeInfo.outcomeStatus.toLowerCase()}`) }}
            </span>
          </h6>
        </div>
        <div>
          {{ outcome.outcomeInfo.localizedSportName }} |
          {{ outcome.outcomeInfo.localizedCategoryName }} |
          {{ outcome.outcomeInfo.localizedTournamentName }}
        </div>
      </div>

      <hr>
      <div v-if="couponData.details.betType != 'SYSTEM'">{{ $t('total_odds') }}: <b class="grey">{{ oddsFormat(couponData.details.betOdds) }}</b></div>
      <div>{{ $t('bet_time') }}: <b class="grey">{{ $d(new Date(couponData.createTime), 'couponDate') }}</b></div>
      <div>{{ $t('bet_id') }}: <b class="grey">{{ couponData.betId }}</b></div>
      <div style="margin-bottom: 80px">
        <b v-if="couponData.payoutInfoDto">
          {{ $t('pay_out_info') }}:
          <span style="color: red">
              {{ $t('paid_out') }}
            </span>
        </b>
        <div v-if="couponData" class="print-receipt-container">
          <div class="print-receipt-label">
            {{$t('print')}}
          </div>
          <ui-checkbox class="print-receipt" id="print-receipt" name="print-receipt" @switch="printPayoutRec = $event, togglePrint($event)" :state="printPayoutRec"/>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-container btn-container-terminal">
    <ui-button v-if="appType == 'default'" :baseBtnConfig="printBetInfoBtn" />
    <ui-button :baseBtnConfig="payOutBtnConfig" />
    <ui-button :baseBtnConfig="payOutAccountBtnConfig" />
  </div>

  <ui-popup v-if="showPopupModal" @y="payOut" @n="showPopupModal = false">
    <template v-slot:modal-popup-body>
      <span>{{ $t('are_you_sure_you_want_to_pay_out') }}</span>
      <div>
        {{ amountInDollars(couponData?.details?.betMoney?.payout) }}
      </div>
    </template>
  </ui-popup>

  <ui-popup v-if="showDepConfirm" @y="goDep" @n="showDepConfirm = false">
    <template v-slot:modal-popup-body>
      <div>
          <h4>
            {{ `${$t('pay_out')} ${amountInDollars(couponData?.details?.betMoney?.payout) } ${$t('to_clients_acc')}`}}
          </h4>
          <div style="display: flex; justify-content: center; align-items: center">
            <div>
              <ui-input :baseInputConfig="clientIdFieldConfig"/>
            </div>
          </div>
          <b v-if="err404Client" class="grey">
            {{ $t('client_not_found_check_client_id') }}
          </b>
        </div>
    </template>
  </ui-popup>

  <ui-dialog
    :size="'md'"
    :title="$t('deposit')"
    v-if="showDeposit"
    @close="showDeposit = false"
  >
    <template #main>
      <component :fromScanCoupon="depositData" @payout="payoutDep" @close="showDeposit = false" :is="deposit" />
    </template>
  </ui-dialog>
</template>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
  .print-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
  .pay-data-container {
    padding-top: 10px;
    padding-left: 7px;
  }
  .grey {
    color: grey;
  }
  .body-container {
    overflow: auto;
    height: calc(100% - 37px - 85px);
  }
  .btn-container {
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    border-top: 1px solid #ced4da;
  }
  .btn-container > button {
    width: 100%;
  }
  .body-container-terminal {
    padding: 1rem;
  }
  .btn-container-terminal {
    padding: 1rem;
    width: 100%;
    border: none;
  }



  h3, h5 {
  margin: 0;
}
.sunmi-sc-inputs-container {
  padding: 1rem 10px;
}
.scan-coupon-container {
  padding: 1px;
}
.pay-data-container-terminal {
  background-color: var(--material);
  color: var(--text-for-material);
}
.money-container {
  /*width: 50%;*/
  display: flex;
}
.money-container > div {
  display: flex;
  flex-direction: column;
}
.outcome-container {
  /*padding: 10px 0;*/
  background-color: var(--bg);
  margin-bottom: 8px;
}
.outcome-container > div {
  /*background: #f8f9fa;*/
  padding: 5px;
}
.bottom-btn-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 5px 0;
  background: #1c1e2b;
}
.err-404-container {
  color: #f66;
  font-weight: bold;
  text-align: center;
}
.deposit-termial-field {
  width: 100%;
}
.print-receipt-container {
  display: flex;
  align-items: center;
}
.print-receipt-label {
  color: var(--text-for-material);
  padding: 10px 10px 10px 0;
}
</style>
