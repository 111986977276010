import { store } from '@/app/store/store';
import { getSiteAdress } from './getSiteAdress';
import i18n from '@/app/i18n.js';
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';

const dateTimeFormat = new Intl.DateTimeFormat('eu', {
  year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"
});

export const createPayoutTemplate = (data) => {
  const auth = store.getters['cashierStore/AUTH'];
  const cashdeskData = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
  const logo = store.getters['cashdeskConfigStore/GET_LOGO'];
  const rows = [];

  if(logo?.logo) {
    rows.push(
      {
        type: 'logo',
        content: logo,
        yPadding: 0,
      }
    )
  }

  rows.push(
    {
      type: 'text',
      content: getSiteAdress(auth.sessionProject),
      prevContentHeight: logo && logo.height,
      yPadding: 1,
      xPadding: 1,
      font: 'titleBold',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: cashdeskData.betShopDto.name + ', ' + cashdeskData.betShopDto.address,
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: i18n.global.t('payout_of_winning').toUpperCase(),
      yPadding: 2,
      xPadding: 1,
      font: 'fontBold',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: 'Transaction ID:',
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: data.id
    }
  )

  rows.push(
    {
      type: 'text',
      content: data.id,//data.respData.details.billingTransactionId,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  const date = dateTimeFormat.format(new Date());

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('date')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: date
    }
  )

  rows.push(
    {
      type: 'text',
      content: date,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('total_win')}:`,
      yPadding: 4,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: amountInDollars(data.amount)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(data.amount),
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('tax')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: amountInDollars(tax)
    }
  )

  //TODO
  const tax = data.tax || 0;

  rows.push(
    {
      type: 'text',
      content: amountInDollars(tax),
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('paid_out')}:`,
      yPadding: 4,
      xPadding: 1,
      font: 'fontBold',
      position: null,
      sameRowData: amountInDollars(data.amount - tax)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(data.amount - tax),
      yPadding: 0,
      xPadding: 1,
      font: 'fontBold',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: '',
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: null
    }
  )


  return rows;
}
