<template>
  <div class="money-transfer-container">
    <div class="money-transfer-balance-info">
      <headerBalance />
    </div>
    <div class='money-transfer-content'>
      <ui-tabs :selectedTab="selectedTab" :tabs="tabs" @changeTab="changeTab"/>
      <component
        :is="selectedTab"
        @updateBalance="updateBalance"
      />
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';

import dateFormat from 'dateformat';

import { headerBalance } from '@/features';

import moneyIn from '@/components/cashdesk/sunmiComponents/moneyIn.vue';
import moneyOut from '@/components/cashdesk/sunmiComponents/moneyOut.vue';

export default {
  components: {
    'money-in': moneyIn,
    'money-out': moneyOut,
    headerBalance
  },
  props: ['componentData'],
  setup() {
    const store = useStore();
    const {t} = useI18n();
    const selectedTab = ref('money-in');

    onMounted(() => {
      console.log('mount')
    })

    const tabs = [
      {
        value: 'money-in',
        title: 'in'
      },
      {
        value: 'money-out',
        title: 'out'
      }
    ];

    const changeTab = (tab) => {
      console.log(tab);
      selectedTab.value = tab;
    }
    const updateBalance = () => {
      const {id} = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
      store.dispatch('balanceState/GET_BALANCE_DATA', { id, currency: localStorage['currency'] });
    }

    return {
      tabs,
      selectedTab,
      changeTab,
      updateBalance,
      dateFormat,
      amountInDollars,
      balanceInfo: computed(() => store.getters['balanceState/BALANCE']),
      t
    }
  }
}
</script>

<style scoped>
  .money-transfer-container {
    padding: 1px;
  }
  .money-transfer-content {
    /* padding: 10px; */
  }
  .money-transfer-balance-info {
    margin-bottom: 1px;
    padding: 5px 0;
    text-align: center;
    font-weight: bold;
    background-color: #222430;
    color: #e8e9ee;
  }
</style>
