<script setup>
  import { onMounted, nextTick } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/app/route/route.js';
  import PATHS from '@/app/route/PATHS.js';

  const store = useStore();
  const cashdeskData = store.getters['cashdeskDataStore/GET_CASHDESK_DATA']
  const goToPath = cashdeskData
    ? PATHS.CASHDESK_ID.replace(':id', cashdeskData.id)
    : PATHS.LOGIN_PAGE

  onMounted(() => {
    nextTick(() => {
      router.push(goToPath);
    })
  })
</script>

<template>
  <div class="container-404 full-screen-container-centered-item">
    <ui-loader />
  </div>
</template>

<style scoped>
  .container-404 {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
  }
</style>
