<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();
const store = useStore();

const logOut = () => {
  store.dispatch('cashierStore/LOGOUT_CASHIER')
}

const baseBtnConfig = {
  text: computed(() => t('logout')),
  variant: 'btn-danger',
  func: logOut,
  style: { padding: '8px 16px', 'font-size': '14px', 'min-height': 'unset' }
}
</script>

<template>
  <ui-button :baseBtnConfig="baseBtnConfig" />
</template>
