<script setup>
  import {
    ref,
    computed,
    watch,
    onMounted,
    defineProps,
    defineEmits,
    inject,
    // getCurrentInstance
  } from 'vue';
  import { errorCodeObject, couponPrint } from '@/entities';
  import { useStore } from 'vuex';

  import { createCuoponObj } from '@/components/cashdesk/bet/print/createObj/cuoponObj.js';

  import { useI18n } from 'vue-i18n';

  const { amountInDollars, getErrorCode } = inject('sharedModel');

  const props = defineProps(['fromScanCoupon']);

  const emit = defineEmits(['payout', 'goTo', 'close'])

  const store = useStore();
  const { t } = useI18n();

  const clientData = ref(null);
  const amount = ref('');
  const showModal = ref(false);
  const err404 = ref(false);
  const printDepRec = ref(false);
  const searchInput = ref(null);

  const findClient = async (id) => {
    try {
      await store.dispatch('clientState/FIND_CLIENT_BY_ID', +id);
      clientData.value = store.getters['clientState/CLIENT_DATA'];
      err404.value = false;
    } catch(err) {
      if (getErrorCode(err) === '404') {
        err404.value = true;
        clientData.value = false;
      } else {
        errorCodeObject(err);
      }
    }
  }

  watch(() => store.getters['couponStore/COUPON_DATA'], () => {
    if (store.getters['couponStore/COUPON_DATA']) {
      couponPrint(store.getters['couponStore/COUPON_DATA']);
    }
  })

  const printDepositReceipt = (id) => {
    const printData = createCuoponObj({
      respData: clientData.value,
      amount: +amount.value * 100,
      cuponType: 'deposit',
      completePaymentId: id
    })

    store.commit('couponStore/ADD_COUPON_DATA', printData);
  };

  const createDeposit = async () => {
    if (clientData.value && amount.value !== '') {
      if(props.fromScanCoupon) {
        console.log("props.fromScanCoupon", props.fromScanCoupon)
        emit('payout', {
          clientId: +clientData.value.clientId,
          print: printDepRec.value,
          clientData: clientData.value,
          amount: amount.value
        })
        showModal.value = false;
        clearData();
      } else {
        try {
          const cashdesk_data = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
          const request = {
            clientId: +clientData.value.clientId,
            currency: cashdesk_data.currency,
            amount: +amount.value * 100
          }

          await store.dispatch('clientState/CREATE_CLIENT_DEPOSIT', request);

          const {completePaymentId} = store.getters['clientState/DEPOSIT_DATA'];

          if (printDepRec.value) {
            printDepositReceipt(completePaymentId);
          }

        await  store.dispatch('balanceState/GET_BALANCE_DATA', {
            id: store.getters['balanceState/BALANCE'].id,
            currency: localStorage['currency']
          });

          showModal.value = false;
          clearData();
          store.commit('notificationsStore/MESSAGE_INFO_MODAL', t('deposit_successfully'));
          emit('close');
          try {
            emit('goTo', { component: 'sunmi-home' });
          } catch(err) {
            console.log(err);
          }
        } catch(err) {
          showModal.value = false;
          errorCodeObject(err);
        }
      }
    }
  }

  const clearData = () => {
    // const instance = getCurrentInstance();
    // instance?.proxy?.$forceUpdate();
    clientData.value = null;
    amount.value = '';
    newSearchConfig.value.model.value = '';
  }

  const showDepositModal = () => {
    showModal.value = true;
  }

  watch(() => amount.value, () => {
    amount.value = String(amount.value).replace(',', '.')
  })

  const amountState = computed(() => {
    const t = /[^\d.]/g;
    const findDot = String(amount.value).indexOf('.');
    let afterDot = ''
    if (findDot !== -1) {
      afterDot = amount.value.substring(findDot + 1, amount.value.length)
    }
    return amount.value !== '' && !t.test(+amount.value) && afterDot.length <= 2;
  })

  const togglePrint = (value) => {
    localStorage.setItem('printDeposit', value);
  }

  onMounted(() => {
    printDepRec.value = localStorage.printDeposit ? JSON.parse(localStorage.printDeposit) : false;

    if (props.fromScanCoupon) {
      const {clientId, sum} = props.fromScanCoupon;

      findClient(clientId);
      amount.value = sum;
    }
  })

  const cashdeskBalance = computed(() => store.getters["balanceState/BALANCE"].currency);

  const newSearchConfig = computed(() => ({
    labelText: t('client_id'),
    validation(value) {
      return  !isNaN(+value) && (!value.toString().includes('.'))
    },
    disabled: !!props.fromScanCoupon,
    model: ref(props?.fromScanCoupon?.clientId),
    autofocus: true
  }));

  const mailField = computed(() => ({
    disabled: true,
    model: ref(clientData.value.mail),
    type: 'text',
    labelText: t('client_email'),
  }))

  const countryField = computed(() => ({
    disabled: true,
    model: ref(clientData.value.country),
    type: 'text',
    labelText: clientData.value.country && t('counrty'),
  }))

  const currencyField = computed(() => ({
    disabled: true,
    model: ref(clientData.value.currency),
    type: 'text',
    labelText: t('currency'),
  }));

  const amountField = computed(() => ({
    disabled: !!props.fromScanCoupon,
    validaton: () => amountState.value,
    model: amount,
    type: 'text',
    labelText: t('amount'),
  }))


  const btnsConfig = computed(() => [
    {
      text: t('clear'),
      disabled: computed(() => !clientData.value || props.fromScanCoupon),
      variant: 'btn-danger',
      func: () => clearData(),
      style: { 'width': '100%' },
      icon: null,
      fullW: true,
    },
    {
      text: t('deposit'),
      disabled: computed(() => !amountState.value || clientData.value?.currency !== cashdeskBalance.value),
      variant: 'btn-brand',
      func: () => showDepositModal(),
      style: { 'width': '100%' },
      icon: null,
      fullW: true,
    }
  ]);
</script>

<template>

  <div class="body-container body-container-terminal">
      <ui-search
        class="deposit-termial-field"
        :config="newSearchConfig"
        @search="findClient"
        ref="searchInput"
      />

      <b v-if="err404" class="grey">
        {{ $t('client_not_found_check_client_id') }}
      </b>

      <template v-if="clientData">
        <div v-if="clientData.currency == cashdeskBalance">
          <ui-input class="deposit-termial-field" :baseInputConfig="mailField"/>

          <ui-input class="deposit-termial-field" :baseInputConfig="countryField"/>

          <ui-input class="deposit-termial-field" :baseInputConfig="currencyField"/>

          <ui-input class="deposit-termial-field" :baseInputConfig="amountField"/>

          <div class="print-receipt-container">
            <div class="print-receipt-label">
              {{$t('print')}}
            </div>
            <ui-checkbox class="print-receipt" id="print-receipt" name="print-receipt" @switch="printDepRec = $event, togglePrint($event)" :state="printDepRec"/>
          </div>

        </div>

        <div v-else class="wrong-currency">
          <h6>
            {{ $t('clients_balance_currency_is') }} {{ clientData.currency }}
          </h6>
          <h6>
            {{  $t('cashdesk_balance_currency_is') }} {{ cashdeskBalance }}
          </h6>
        </div>
      </template>
    </div>

  <div class="btn-container btn-container-terminal">
    <ui-button-factory :buttonFactoryConfig="btnsConfig" />
  </div>

  <ui-popup v-if="showModal" @y="createDeposit" @n="showModal = false">
    <template v-slot:modal-popup-body>
      {{ $t('deposit') }} {{ amountInDollars(+amount * 100) }}
    </template>
  </ui-popup>
</template>

<style scoped>
  .search-container {
    display: flex;
  }
  .search-container > input {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  .search-container > button {
    border-radius: 0 0.25rem 0.25rem 0;
  }
  .bottom-btn {
    width: 100%;
  }
  .bottom-btn:first-child {
    margin-right: 2px;
  }
  .bottom-btn:last-child {
    margin-left: 2px;
  }
  .wrong-currency {
    padding: 10px 0;
  }
  .wrong-currency > h6 {
    color: grey;
  }
  .grey {
    color: grey;
  }
  .deposit-termial-field {
    margin-bottom: 1rem;
  }
  .body-container {
    overflow: auto;
    height: calc(100% - 37px - 85px);
    padding-top: 10px;
  }
  .btn-container {
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    border-top: 1px solid #ced4da;
  }
  .btn-container > button {
    width: 100%;
  }
  .body-container-terminal {
    padding: 1rem;
  }
  .btn-container-terminal {
    padding: 1rem;
    width: 100%;
    border: none;
  }
  .print-receipt {
    width: 30px;
  }
  .print-receipt-container {
    display: flex;
    align-items: center;
  }
  .print-receipt-label {
    color: var(--text-for-material);
    padding: 10px;
  }
</style>
