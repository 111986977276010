<script setup>
  import { computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const store = useStore();

  const errorLoginData = ref(false);

  const loginInputConfig = {
    labelText: t('login'),
    validation: function() {return !(errorLoginData.value && this.model === '')},
    width: '100%',
    style: { width: '100%' },
    model: '',
  };


  if (localStorage['terminal_login_cashier']) {
    loginInputConfig.model = localStorage['terminal_login_cashier'];
  }

  const passInputConfig = ref({
    type: 'password',
    labelText: t('password'),
    validation: function() { return !(errorLoginData.value && this.model === '') },
    width: '100%',
    style: { width: '100%' },
    model: '',
    button: {
      btnIcon: '#visibility-off',
      func: () => {
        if (passInputConfig.value.type === 'password') {
          passInputConfig.value.type = 'text';
          passInputConfig.value.button.btnIcon = '#visibility-on';
        } else {
          passInputConfig.value.type = 'password';
          passInputConfig.value.button.btnIcon = '#visibility-off';
        }
      }
    }
  });

  const loginBtnConfig = {
    text: computed(() => t('login')),
      func: () => {
      errorLoginData.value = false;
      if (loginInputConfig.model !== '' && passInputConfig.value.model !== '') {

        localStorage.setItem('terminal_login_cashier', loginInputConfig.model);

        store.dispatch('cashierStore/LOGIN_CASHIER', {
          login: loginInputConfig.model,
          password: passInputConfig.value.model
        })
      } else {
        errorLoginData.value = true;
      }
    }
  }

</script>

<template>
  <section>
    <div class="header-big-text">Cashier Dashboard</div>
    <div style="margin-bottom: 10px">
      <ui-input :baseInputConfig="loginInputConfig" />
    </div>
    <div style="margin-bottom: 10px">
      <ui-input type="password" :baseInputConfig="passInputConfig" />
    </div>
    <div>
      <ui-button class="btn-brand" :baseBtnConfig="loginBtnConfig" />
    </div>
  </section>
</template>
