<template>
  <div v-if="!transferCreated">
    <div class="flex-between out-input-container">
      <ui-input  :baseInputConfig="cashInputConfig" />
      <ui-button class="btn-brand" :baseBtnConfig="confirmConfig"/>
    </div>
  </div>
  <div v-else>
    <div class="created-header-container">
      <h3>{{ $t("transfer_created") }}</h3>
    </div>
    <div>
      <div
        class="point"
      >
        <div class="point-row">
          <span class="point-row__item point-row__item--title">{{ $t("from") }}</span>
          <span class="point-row__item">{{ createdData.from.name }}</span>
        </div>
        <div class="point-row">
          <span class="point-row__item point-row__item--title">{{ $t("to") }}</span>
          <span class="point-row__item">{{ createdData.to.name }}</span>
        </div>
        <div class="point-row">
          <span class="point-row__item point-row__item--title">{{ $t("amount") }}</span>
          <span class="point-row__item" style="color: #01b64f; font-weight: 700">+ {{ amountInDollars(+createdData.amount) }}</span>
        </div>
        <ui-button class="btn-brand" :baseBtnConfig="okBtnConfig" @click="transferCreated = false"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { errorCodeObject } from '@/entities';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';

import Cashdesk from '@/shared/api/cashdesk/cashdesk.js';
const cashdesk = new Cashdesk();
export default {
  name: 'moneyOut',
  props: [],
  emits: ['updateBalance'],

  setup(props, { emit }) {
    const transferCreated = ref(false);
    const createdData = ref(null);
    const {t} = useI18n();
    const store = useStore();

    const cashInputConfig = {
      labelText: computed(() => t("cash_amount")),
      model: ref(''),
      width: 'calc(100vw - 155px)',
      type: 'number'
    };

    const cashAmount = ref(cashInputConfig.model);

    watch(() => cashAmount.value, () => {
      cashAmount.value = String(cashAmount.value).replace(',', '.')
    })

    const tetsAmount = computed(() => {
      const t = /[^\d.]/g;
      const findDot = String(cashAmount.value).indexOf('.');
      let afterDot = ''
      if (findDot != -1) {
        afterDot = cashAmount.value.substring(findDot + 1, cashAmount.value.length)
      }
      return cashAmount.value != '0'
          && cashAmount.value !== ''
          && !t.test(+cashAmount.value)
          && afterDot.length <= 2;
    })

    const confirm = () => {
      if (tetsAmount.value) {
        cashdesk.transferCashOut({
          amount: +cashAmount.value * 100
        })
        .then(resp => {
          console.log('resp', resp);
          createdData.value = resp.data;
          cashAmount.value = 0;
          transferCreated.value = true;
          emit('updateBalance');
        })
        .catch(err => {
          errorCodeObject(err);
        })
      }
    }

    const focusListner = () => {
      if (+cashAmount.value == 0) cashAmount.value = '';
    }

    const blurListner = () => {
      if (cashAmount.value ==  '') cashAmount.value = 0;
    }

    const currencyInputConfig = {
      labelText: computed(() => t("currency")),
      disabled: true,
      model: computed(() => store.getters['cashdeskDataStore/GET_CASHDESK_DATA'].currency),
    }

    return {
      amountInDollars,
      props,
      cashAmount,
      confirm,
      tetsAmount,
      transferCreated,
      createdData,
      focusListner,
      blurListner,
      cashdeskData: store.getters['cashdeskDataStore/GET_CASHDESK_DATA'],
      round: data => (data / 100).toFixed(2),
      cashInputConfig,
      currencyInputConfig,
      confirmConfig: {
        func: confirm,
        text: computed(() => t('create')),
        style: {width: '190px'},
        disabled: computed(() => !tetsAmount.value)
      },
      okBtnConfig: {
        text: computed(() => t('ok')),
        func: () => {}
      }
    }
  },
}
</script>

<style scoped>
  .out-input-container {
    padding: 1rem 10px;
  }
  .btn-container {
    padding: 5px 0;
    display: flex;
    justify-content: flex-end;
  }
  .created-header-container {
    display: flex;
    justify-content: center;
    color: var(--text-for-material);
  }
  .point {
    color: var(--text-for-material);
    padding: 0 10px;
  }
  .point-row {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
  .point-row__item {
    flex: 3;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .point-row__item--title {
    font-weight: 700;
    flex: 1;
    text-align: start;
  }
</style>
