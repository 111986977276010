<template>
  <sunmi-header
      :componentData="componentData"
      :selectedComponent="selectedComponent"
      @goHome="goHome"
  />
  <component
      :is="selectedComponent"
      :componentData="componentData"
      @goTo="goTo"
  />
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from "vue-router";

import PATHS from '@/app/route/PATHS.js';

import sunmiHome from '@/components/cashdesk/sunmiComponents/sunmiHome.vue';
import sunmiHeader from '@/components/cashdesk/sunmiComponents/sunmiHeader.vue';
import sunmiPlaceBet from '@/components/cashdesk/sunmiComponents/sunmiPlaceBet.vue';
import sunmiScanCoupon from '@/components/cashdesk/sunmiComponents/sunmiScanCoupon.vue';
import sunmiTransactions from '@/components/cashdesk/sunmiComponents/sunmiTransactions.vue';
import sunmiMoneyTransfer from '@/components/cashdesk/sunmiComponents/sunmiMoneyTransfer.vue';
import { deposit, withdraw } from '@/features';

export default {
  components: {
    'sunmi-header': sunmiHeader ,
    'sunmi-home': sunmiHome,
    'sunmi-place-bet': sunmiPlaceBet,
    'sunmi-scan-coupon': sunmiScanCoupon,
    'sunmi-transactions': sunmiTransactions,
    'sunmi-money-transfer': sunmiMoneyTransfer,
    deposit,
    withdraw
  },
  setup() {
    const selectedComponent = ref('sunmi-home');
    const componentData = ref({
      component: 'sunmi-home'
    })

    const store = useStore();
    const route = useRoute();
    const id = route.params.id;
    const router = useRouter();

    const appType = computed(() => store.getters['APP_TYPE']);

    if (appType.value != 'terminal' && route.name === 'sunmi-terminal') {
      router.push(PATHS.NOT_FOUND)
    }

    const updateBalance = () => {
      store.dispatch('balanceState/GET_BALANCE_DATA', { id: id, currency: localStorage['currency'] })
    }

    const goTo = (data) => {
      selectedComponent.value = data.component;
      componentData.value = data;
    }

    const goHome = () => {
      selectedComponent.value = 'sunmi-home';
      componentData.value = {};
      componentData.value.component = 'sunmi-home';
    };

    onMounted(() => {
      updateBalance();
      setInterval(updateBalance, 180000);
      store.dispatch('sportsbookConfigStore/LOAD_SPORTSBOOK_CONFIG');
    })
    return {
      selectedComponent,
      componentData,
      goTo,
      goHome
    }
  }
}
</script>

<style scoped>
  @media print {
    .no-print, .no-print * {
      display: none !important;
    }
  }
</style>
