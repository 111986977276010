<script setup>
import { computed, defineEmits, shallowRef } from 'vue';// ref
import { useStore } from 'vuex';


// eslint-disable-next-line
import moneyIn from '@/components/cashdesk/sunmiComponents/moneyIn.vue';
// eslint-disable-next-line
import moneyOut from '@/components/cashdesk/sunmiComponents/moneyOut.vue';

import { useI18n } from 'vue-i18n';

const emit = defineEmits(['close', 'updateBalance'])

const store = useStore();
const { t } = useI18n()
const selectedTab = shallowRef(moneyIn);

const tabs = [
  {
    value: moneyIn,
    title: 'in'
  },
  {
    value: moneyOut,
    title: 'out'
  }
];

const changeTab = (tab) => {
  selectedTab.value = tab;
}

const updateBalance = () => {
  const getId = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
  store.dispatch('balanceState/GET_BALANCE_DATA', { id: getId.id, currency: localStorage['currency'] });
  emit('updateBalance');
}

const btnsConfig = computed(() => [{
  text: t('close'),
  disabled: false,
  variant: 'btn-danger',
  func: () => emit('close'),
  style: { 'width': '100%' },
  icon: null,
  fullW: true
}]);
</script>

<template>
  <div class="body-container-terminal">
    <ui-tabs :selectedTab="selectedTab" :tabs="tabs" @changeTab="changeTab"/>
    <component
      :is="selectedTab"
      @updateBalance="updateBalance"
    />
  </div>
  <div class="btn-container btn-container-terminal">
    <ui-button-factory :buttonFactoryConfig="btnsConfig" />
  </div>
</template>

<style scoped>
  .no-data-container {
    text-align: center;
    color: grey;
    padding: 1rem;
  }
  .body-container {
    overflow: auto;
    height: calc(100% - 37px - 85px);
    padding-top: 10px;
  }
  .btn-container {
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    border-top: 1px solid #ced4da;
  }
  .btn-container > button {
    width: 100%;
  }
  .body-container-terminal {
    padding: 1rem;
    overflow: auto;
    height: 75vh;
  }
  .btn-container-terminal {
    padding: 1rem;
    width: 100%;
    border: none;
  }
</style>
