<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();

const clearAllBets = () => {
  store.commit('oddsBasketStore/CLEAR_ODDS_BASKET');
  store.commit('oddsBasketStore/BOOKING_CHANGE', false);
}
const oddsBasketLength = computed(() => store.getters['oddsBasketStore/GET_OUTCOME_LENGTH']);

const baseBtnConfig = {
  text: t('clear_all_bets'),
  variant: 'btn-danger',
  func: clearAllBets,
  style: { padding: '4px 16px', 'font-size': '14px', 'min-height': 'unset', 'height': '36px' }
}
</script>

<template>
  <div v-if="oddsBasketLength">
    <ui-button :baseBtnConfig="baseBtnConfig" />
  </div>
</template>
