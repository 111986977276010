import printLine from './print-line';
import pageNotFound from './page-not-found';
import login from './login';
import cashdeskTerminal from './cashdesk-terminal';
import cashdesk from './cashdesk';

export default {
    printLine,
    pageNotFound,
    login,
    cashdeskTerminal,
    cashdesk
}
