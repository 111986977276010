<script setup>
import { ref, onMounted, watch, computed, defineEmits, inject } from 'vue';
import { errorCodeObject } from '@/entities';
import { createCuoponObj } from '@/components/cashdesk/bet/print/createObj/cuoponObj.js';
import { useStore } from 'vuex';
import { OutcomeService } from '@atlas/core';

import { useI18n } from 'vue-i18n';

import shared from '@/shared';

const { amountInDollars, getErrorCode } = inject('sharedModel');

const emit = defineEmits(['close']);

const {Cashdesk} = shared.api;

const {t} = useI18n();
const jsonData = ref([]);
const history_table = ref(null);
const respData = ref(null);
const store = useStore();
const selectedBet = ref(null);
const selectedBetInfo = ref(null);
const showInfo = ref(false);

const getData = async () => {
  try {
    await store.dispatch('cashdeskDataStore/LOAD_CASHDESK_TRANSACTIONS_HISTORY');
    respData.value = store.getters['cashdeskDataStore/TRANSACTIONS_HISTORY'];
    if (!jsonData.value.length) {
      jsonData.value = respData.value;
    } else {
      respData.value.forEach((item) => {
        const isItemInList = jsonData.value.find((transfer) => (
          transfer.transferId === item.transferId
        ))
        if (!isItemInList) {
          jsonData.value.push(item);
        }
      });
    }
  } catch(err) {
    console.log('err', err);
    if (getErrorCode(err) !== '404') {
      errorCodeObject(err);
    }
  }
}

const showBetInfo = async (bet, index) => {
  selectedBetInfo.value = null;

  if (selectedBet.value == index) {
    showInfo.value = !showInfo.value;
    selectedBet.value = null;
  } else {
    const response = await store.dispatch('bet/LOAD_BET_DATA', bet.betId);

    selectedBet.value = index;
    selectedBetInfo.value = response.data.betsInfo;
  }
};

onMounted(async () => {
  store.commit('cashdeskDataStore/CLEAR_TRANSACTION_STATE');
  await getData();
})

watch(() => history_table.value, () => {
  if (history_table.value) {
    history_table.value.addEventListener('scroll', () => {
      if (history_table.value.scrollTop + history_table.value.clientHeight >= history_table.value.scrollHeight) {
        if (respData.value.length) getData();
      }
    })
  }
})

const oddsFormat = (odds) => {
  const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
  return OutcomeService.formatOdds(odds, { oddsFormat })//, { zeroes }
}

const printShiftReport = async () => {
  const {data} = await Cashdesk.getShiftStatistic();

  const printData = createCuoponObj({
    respData: data,
    cuponType: 'transactions',
  })

  store.commit('couponStore/ADD_COUPON_DATA', printData);
}

const btnsConfig = [
  {
    text: computed(() => t('close')),
    disabled: false,
    variant: 'btn-danger',
    func: () => emit('close'),
    style: { 'width': '100%' },
    icon: null,
    fullW: true
  },
  {
    text: computed(() => t('print')),
    disabled: false,
    variant: 'btn-brand',
    func: printShiftReport,
    style: { 'width': '100%' },
    icon: null,
    fullW: true
  },
];

const regex = /\d+/g;

const formattedDetails = (details) => {
  if (details.includes('CLIENT_DEPOSIT')) {
    const matches = details.match(regex);
    return t('transactions_dep_details', {clientId: matches[0]})
  } else if (details.includes('CLIENT_DEPOSIT_BET_WITHDRAW')) {
    const matches = details.match(regex);
    return t('transactions_payout_details', {clientId: matches[0]})
  } else if (details.includes('Withdraw from client account')) {
    const matches = details.match(regex);
    return t('transactions_withdraw_details', {clientId: matches[0]})
  } else if (details.includes('CANCEL BET')) {
    const matches = details.match(regex);
    return t('transactions_cancel_bet', {betId: matches[1]})
  }
  const regexBetshopId = /\d+(\.\d+)*/g;
  const matches = details.match(regexBetshopId);
  if (matches) {
    const betshops = details.replace('Money transfer from:' , '').split(' to ');
    return t('transactions_money_trans_details', {from: betshops[0], to: betshops[1]})
  } else {
    return t(details.toLowerCase().split(' ').join('_'));
  }
};
</script>

<template>
  <div class="body-container body-container-terminal">
    <div
      v-if="jsonData != null"
      class="table-scroll table-scroll--terminal"
      ref="history_table"
    >
      <table  v-if="jsonData.length" class="table table--terminal">
        <thead class="thead-scroll thead-scroll--terminal">
          <tr>
            <th></th>
            <th>
              {{ $t("date") }}
            </th>
            <th>
              {{ $t("type") }}
            </th>
            <th>
              {{ $t("cash_amount") }}
            </th>
            <th>
              {{ $t("details") }}
            </th>
            <th>
              {{ $t("balance_after") }}
            </th>
            <th>
              {{ $t("balance_before") }}
            </th>
            <th>
              {{ $t("transfer_id") }}
            </th>
          </tr>
        </thead>
        <tbody style="height: 120px;">
          <template
            v-for="(item, index) in jsonData"
          >
          <!-- eslint-disable-next-line -->
            <tr>
              <td @click="item.betId && showBetInfo(item, index)"
                style="cursor: pointer"
              >
                <div v-if="item.betId" :style="selectedBetInfo && selectedBet === index ? 'transform: rotate(180deg); transition: 0.2s':'transform: rotate(90deg); transition: 0.2s'">
                  <svg fill="var(--text-for-material)" width="10" height="10">
                    <use :href="'#triangle-icon'"></use>
                  </svg>
                </div>
              </td>
              <td>
                {{ $d(item.date, 'couponDate') }}
              </td>
              <td>
                {{ $t(item.type.toLowerCase()) }}
              </td>
              <td>
                {{ amountInDollars(item.amount) }}
              </td>
              <td>
                <span style="display: block">{{ formattedDetails(item.details) }}</span>
                <span style="display: block; white-space: nowrap" v-if="item.betId">{{ `${$t('bet_id')}: ${item.betId}` }}{{ !!item.betType ? `, ${$t('bet_type')}: ${$t(`coupon.${item.betType.toLowerCase()}`)}` : '' }}</span>
              </td>
              <td>
                {{ amountInDollars(item.balanceAfter) }}
              </td>
              <td>
                {{ amountInDollars(item.balanceBefore) }}
              </td>
              <td>
                {{ item.transferId }}
              </td>
            </tr>
            <!-- eslint-disable-next-line -->
            <tr v-if=" selectedBetInfo && selectedBet === index">
              <td colspan="9">
                <!-- border: 2px solid black; border-radius: 10px -->
                <table style="width: 100%;">
                  <thead style="height: 3rem" class="thead-scroll thead-scroll--terminal">
                    <tr>
                      <th>
                        {{ $t("status") }}
                      </th>
                      <th>
                        {{ $t("odds") }}
                      </th>
                      <th>
                        {{ $t("event") }}
                      </th>
                      <th>
                        {{ $t("market") }}
                      </th>
                      <th>
                        {{ $t("outcome") }}
                      </th>
                      <th>
                        {{ $t("sport_service") }}
                      </th>
                      <th>
                        {{ $t("sport") }}
                      </th>
                      <th>
                        {{ $t("category") }}
                      </th>
                      <th>
                        {{ $t("tournament") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="sub-table">
                    <tr
                      v-for="(outcome, index) in selectedBetInfo"
                      :key="index"
                    >
                      <td>
                        {{ $t(`coupon.${outcome.outcomeStatus.toLowerCase()}`) }}
                      </td>
                      <td>
                        {{ oddsFormat(outcome.odds) }}
                      </td>
                      <td>
                        {{ outcome.eventName }}
                      </td>
                      <td>
                        {{ outcome.marketName }}
                      </td>
                      <td>
                        {{ outcome.outcome }}
                      </td>
                      <td>
                        {{ outcome.sportService }}
                      </td>
                      <td>
                        {{ outcome.sportName }}
                      </td>
                      <td>
                        {{ outcome.categoryName }}
                      </td>
                      <td>
                        {{ outcome.tournamentName }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <div v-else class="no-data-container">
        <h5>{{ $t("no_data") }}</h5>
      </div>
    </div>
  </div>
  <div class="btn-container btn-container-terminal">
    <ui-button-factory :buttonFactoryConfig="btnsConfig" />
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }
  th {
    background-color: var(--material-hover);
  }
  td, th {
    text-align: center;
    vertical-align: middle;
    height: fit-content;
    padding: 5px;
  }

  td {
    height: 50px;
  }

  .table-scroll {
    height: 75vh;
    overflow: auto;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .table-scroll--terminal {
    height: 79vh;
    border: none;
  }
  .table {
    width: 100%;
  }
  .table--terminal {
    color: var(--text-for-material);
  }
  .thead-scroll {
    position: sticky;
    top: 0;
    background: black;
    color: var(--text-for-material);
  }

  .thead-scroll--terminal {
    background: none;
  }

  .no-data-container {
    text-align: center;
    color: grey;
    padding: 1rem;
  }
  .body-container {
    overflow: auto;
    height: calc(100% - 37px - 85px);
    padding-top: 10px;
  }
  .btn-container {
    position: absolute;
    bottom: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
  }
  .btn-container > button {
    width: 100%;
  }
  .body-container-terminal {
    padding: 1rem;
  }
  .btn-container-terminal {
    padding: 1rem;
    width: 100%;
    border: none;
  }

  .sub-table {
    background-color: var(--bg);
  }
</style>
