<script setup>
  import { ref, computed, watch, nextTick } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import shared from '@/shared';
  import { errorCodeObject } from '@/entities';

  const { SportData } = shared.api;

  const store = useStore();
  const {t} = useI18n();
  const sportId = computed(() => store.getters['sportsStore/GET_SELECTED_ID']);
  const topCompetitions = ref(null);

  watch(() => store.getters['tournamentsStore/TOP_TOURNAMENTS'], () => {
    const allTops = store.getters['tournamentsStore/TOP_TOURNAMENTS'];
    topCompetitions.value = allTops.filter(tournament => tournament.sportId === sportId.value);
  })

  const chooseTopCompetition = (competitionData) => {
    let id;
    let competitionDataObj;
    if (competitionData.model) {
      id = competitionData.model;
      competitionDataObj = competitionData.options.find(option => option.id == id);
    } else {
      id = competitionData.id;
      competitionDataObj = competitionData;
    }

    SportData.getTournament({
      filter: {
        tournamentId: id,
        fromDate: null,
        sportServices: ["LIVE", "PREMATCH"],
        timeHours: null,
        toDate: null
      }
    })
    .then(() => {
      store.commit('sportsStore/SET_SELECTED_SPORT_ID', competitionDataObj.sportId);
      nextTick(() => {
        store.commit('categoriesStore/SET_SELECTED_CATEGORIES_ID', competitionDataObj.categoryId);
        nextTick(() => {
          store.commit('tournamentsStore/SET_SELECTED_TOURNAMENT_ID', id);
          store.commit('tournamentsStore/SET_SELECTED_SPORT_FORM_ID', competitionDataObj.sportForm.id);
        })
      })
    })
    .catch(err => {
      errorCodeObject(err);
    })
  }

  const baseDropDown = {
    options: computed(() => topCompetitions.value),
    baseText: computed(() => t('top_competitions')),
    disabled: computed(() => !topCompetitions.value || (topCompetitions.value && !topCompetitions.value.length)),
    style: {height: '48px', width: '100%'}
  }

  const showTopCompetitionsView = async () => {
    try {
      await store.dispatch('tournamentsStore/LOAD_TOP_TOURNAMENTS', true);
    } catch(err) {
      errorCodeObject(err);
    }
  }

  const topCompetitionsBtn = {
    icon: '#unfold-icon',
    style: {
      width: '25%',
      'max-width': '60px',
      'border-radius': '0 4px 4px 0',
      padding: '10px'
    },
    func: showTopCompetitionsView
  }
</script>

<template>
  <div style="display: flex; width: 30%; align-items: flex-end;">
    <ui-dropdown
      v-if="baseDropDown.options"
      :baseDropDown="baseDropDown"
      @selectedItem="chooseTopCompetition"
    />
    <ui-button
      class="btn-brand"
      :baseBtnConfig="topCompetitionsBtn"
    />
  </div>
</template>
