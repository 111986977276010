<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const themeSwitch = ref(false);

themeSwitch.value = store.getters['cashdeskConfigStore/GET_THEME'];


const changetheme = (theme) => {
  store.commit('cashdeskConfigStore/SET_THEME', theme);
  const $theme = JSON.stringify(theme);
  if (!localStorage['dashboard_theme']) {
    localStorage.setItem('dashboard_theme', $theme);
  } else {
    localStorage['dashboard_theme'] = $theme;
  }
}
</script>

<template>
  <div class="items-sm-container">
    <span>{{ $t('theme') }}</span>
    <ui-checkbox  :state="themeSwitch" @switch="changetheme" />
  </div>
</template>
