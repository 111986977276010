import { computed } from 'vue';
import { store } from '@/app/store/store.js';
import i18n from '@/app/i18n.js';
import { createCuoponObj } from '@/components/cashdesk/bet/print/createObj/cuoponObj';
import { errObj } from '@/components/cashdesk/bet/print/createObj/errorObj';
import { SET_TIMEOUT } from '@/shared/constants/constants';


const endBet = () => {
  const balanceData = computed(() => store.getters['balanceState/BALANCE']);

  store.commit('oddsBasketStore/CLEAR_ODDS_BASKET');
  store.commit('oddsBasketStore/SET_BET_AMOUNT', '');
  store.commit('oddsBasketStore/BOOKING_CHANGE', false);
  store.dispatch('balanceState/GET_BALANCE_DATA', { id: balanceData.value.id, currency: localStorage['currency'] })
}

export const getBetResp = async () => {
  if (localStorage['placeBetRequestId']) {
    const resp = await store.dispatch('oddsBasketStore/BET_RESPONSE');

    if (resp.requestStatus === 'PLACED') {
      localStorage.removeItem('placeBetRequestId');

      const createData = createCuoponObj({
        respData: resp.bets[0],
        possibleWin: store.getters['oddsBasketStore/GET_POSSIBLE_WIN'],
        userId: store.getters['cashierStore/USER_DATA'].userId,
        cuponType: 'placeBet'
      })

      store.commit('couponStore/ADD_COUPON_DATA', createData);
      localStorage.setItem('lastPlacedBet', resp.bets[0].betId);

      endBet();
    }
    else if (resp.requestStatus === 'REJECTED' || resp.requestStatus === 'ERROR') {
      const errFunc = () => {
        localStorage.removeItem('placeBetRequestId');
      }
      if (resp.rejectCode === 'INTERNAL_ERROR') {
        store.commit('notificationsStore/ADD_ERROR_DATA', {
          header: resp.rejectCode.replace('_', ' '),
          body: `${ i18n.global.t('error_500') } Request ID ${ resp.requestId }`,
        });
        errFunc()
      } else {
        store.commit('notificationsStore/ADD_ERROR_DATA', errObj(resp));
        errFunc();
      }

    } else {
      setTimeout(() => {
        getBetResp()
      }, SET_TIMEOUT);
    }
  }
}
