<script setup>
import {
  ref,
  onMounted,
  watch,
  defineProps,
  defineEmits,
  computed
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { OutcomeService } from '@atlas/core';
import dateFormat from 'dateformat';

import { watchData, findOutright, findSportForms } from '../model';


const props = defineProps(['eventSportEvents', 'tournament_id']);
const emit = defineEmits(['goToDetails']);

const { t } = useI18n();
const store = useStore();
const data = ref(null);
// const headShortName = ref(null);
const rowLength = ref(null);
const headWidth = ref(0);

const eventsListData = computed(() => {
  return store.getters['eventsState/GET_EVENTS_LIST'].sortedEvents;
})

const mainTypes = findSportForms();

const oldListData = ref(null);

watch(() => eventsListData.value, () => {
  watchData(
    data,
    eventsListData,
    oldListData,
    mainTypes
  )
})

const goFunc = (id) => {
  emit('goToDetails', id)
}

if (eventsListData.value) {
  findOutright(
    data,
    eventsListData,
    mainTypes
  )
}

const formatDate = (date) => {
  const d = new Date(date);
  return `<span>${ dateFormat(d, 'mmm dS') } ${ dateFormat(d, 'h:MM TT') }</span>`;
}

const addOutcome = (oddsData) => {
  if (oddsData.outcome.status === 'ACTIVE') {
    if (selectedOutcome({
      event_id: oddsData.event.id,
      market_type: oddsData.outcome.marketId,
      outcome: oddsData.outcome
    })) {
      store.commit('oddsBasketStore/ODDS_BASKET_DELETE_ITEM', {
        item: {
          event_id: oddsData.event.id,
        },
        marketId: oddsData.outcome.marketId
      });
    } else {
      store.commit('oddsBasketStore/ODDS_BASKET_ADD_ITEM', oddsData,
      );
    }
  } else {
    store.commit('notificationsStore/ADD_ERROR_DATA', {
      header: t('validation_error'),
      body: t('outcome_not_active'),
    });
  }
}

const selectedOutcome = (data) => {
  const findEvent = store.getters["oddsBasketStore/ODDS_BASKET"].find(id => id.event_id === data.event_id);

  if (findEvent) {
    const findMarket = findEvent.market.find(id => id.marketId === data.market_type);

    if (findMarket) {
      return +findMarket.outcomes.id === data.outcome.id;
    }
  }

  return false;
}

const headTournamentName = computed(() => data.value[0].tournamentName);

const maxLength = Math.max(...data.value.map(e => e.grid.length));
const findGrid = data.value.find(g => g.grid.length === maxLength);

rowLength.value = maxLength;
// headShortName.value = findGrid.greedHeader;

let index = 0;

const headShortName = computed(() => {
  const header = findGrid.greedHeader;

  header.forEach((headItem) => {
    index = index + headItem.outcomes.length;
    data.value.forEach(dataItem => {
      const findInGreedMarket = dataItem.grid.find(i => i.marketId === headItem.outcomes[0].marketId);
      if (!findInGreedMarket) {
        for (let j = 0; j < headItem.outcomes.length; j++) {
          dataItem.grid.splice(
            index - headItem.outcomes.length + j,
            0,
            {
              odds: null,
              marketId: headItem.outcomes[0].marketId,
              status: 'NONE',
              orderPosition: j + 1
            }
          )
        }
      }
    })
  })

  return header
})

// headShortName.value.forEach((headItem) => {
//   index = index + headItem.outcomes.length;
//   data.value.forEach(dataItem => {
//     const findInGreedMarket = dataItem.grid.find(i => i.marketId === headItem.outcomes[0].marketId);
//     if (!findInGreedMarket) {
//       for (let j = 0; j < headItem.outcomes.length; j++) {
//         dataItem.grid.splice(
//           index - headItem.outcomes.length + j,
//           0,
//           {
//             odds: null,
//             marketId: headItem.outcomes[0].marketId,
//             status: 'NONE',
//             orderPosition: j + 1
//           }
//         )
//       }
//     }
//   })
// })

let divArr;
const getOffsetWidth = () => {
  try {
    if (divArr[0].offsetWidth)
      headWidth.value = divArr[0].offsetWidth;
  } catch(err) {
    // console.log(err);
  }
}

const tournament_ID = ref(props.tournament_id);

onMounted(() => {
  divArr = document.querySelectorAll('.td-item' );//+ tournament_ID.value
  getOffsetWidth();
  oldListData.value = eventsListData.value;
})

window.addEventListener('resize', () => {
  getOffsetWidth();
});

const oddsFormat = (odds) => {
  const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
  return OutcomeService.formatOdds(odds, { oddsFormat })
}

const getEventName = (event, key) => {
  const slicedName = () => {
    return key === 'home'
      ? event.name.slice(0, event.name.indexOf(' vs '))
      : event.name.slice(event.name.indexOf(' vs ') + 3, event.name.length)
  }

  return event.participants[key].fullName
    ? event.participants[key].fullName
    : event.name
      ? slicedName()
      : '-'
}
</script>

<template>
  <div v-if="data">
    <div class="head-container">
        <div class="tournament-name-container">
          <h4 v-if="headTournamentName">{{ headTournamentName }}</h4>
        </div>
        <div v-if="headTournamentName" class="short-name-container">
          <div
            v-for="(headerItem, index) in headShortName"
            :key="index"
          >
            <div class="centered-text">
              {{ headerItem.name }}
            </div>
            <div class="flex-between">
              <div
                v-for="name in headerItem.outcomes"
                class="outcome__specifier"
                :key="name.id"
                :style="headWidth ? { 'width': `${ headWidth }px` } : {}"
              >
                {{ name.shortName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    <div v-for="(event, index) in data" :key="index">
        <div class="list-group">
          <div
            class="list-group-item"
            :class="{ 'color-red': event.status == null || event.status !== 'ACTIVE' }"
          >
            <div class="flex-between">
              <div @click="goFunc(event.id)" class="event-li">
                <div class="font-size-12">
                  <span
                    v-if="event.sportService === 'PREMATCH' || event.sportService == null"
                    v-html="formatDate(event.startTime)"
                  ></span>
                    <b v-else class="color-red">{{ event.sportService }}</b>
                    <span>
                    | {{ event.id }}
                  </span>
                </div>
                <div class="event-list-container">
                  <div>
                    <template v-if="!event.outright">
                      <div class="name-and-score-conteiner">
                        <span>
                          {{ getEventName(event, 'home') }}
                        </span>
                        <span>{{ event.info.score.homeScore }}</span>
                      </div>
                      <div class="name-and-score-conteiner">
                        <span>
                          {{ getEventName(event, 'away') }}
                        </span>
                        <span>{{ event.info.score.awayScore }}</span>
                      </div>
                    </template>
                    <template v-else>
                      <b>{{ $t("outright") }}</b><br>
                      {{ event.name ? event.name : '-' }}
                    </template>
                  </div>
                </div>
              </div>

              <div class="standard-event" v-if="!event.outright">
                <div
                  v-for="outcome in event.grid"
                  :key="outcome.id"
                  :class="[
                    `td-item${tournament_ID}`,
                    {'active-item': selectedOutcome({
                      event_id: event.id,
                      market_type: outcome.marketId,
                      outcome: outcome
                    })},
                    { 'color-odd': outcome.status === 'ACTIVE' }
                  ]"
                  class="td-item"
                  ref="td_item"
                  :style="{'width': `calc(100% / ${ rowLength })`}"

                  @click="addOutcome({ outcome, event })"
                >
                  <template v-if="!event.outright">
                    <span v-if="outcome.status === 'ACTIVE'">
                      <div class="outcome__specifier">{{outcome.specifiers}}</div>
                      <b
                        :class="{ 'color-green': outcome.up, 'color-red': outcome.down }"
                      >
                        {{ oddsFormat(outcome.odds) }}
                      </b>
                      <div v-if="outcome.up" class="triangle-up"></div>
                      <div v-if="outcome.down" class="triangle-down"></div>
                    </span>
                      <span v-else>
                        <svg class="base-svg-size">
                          <use xlink:href="../../../shared/assets/lock.svg#lock"></use>
                        </svg>
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
