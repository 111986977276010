<template>
  <div
    class="overflow-container"
    ref="overflow_container"
  >
    <div class="betslip-conteiner">
      <div v-if="betSlipData.length">
        <div ref="outcomes_overflow" class="outcomes-overflow" style="overflow: auto">
          <div v-for="item in betSlipData" :key="item.marketId" class="outcom-container"><!---->
            <div
              v-for="market in item.market"
              :key="market.marketId"
              id="betslip-card"
              style="font-size: 14px; border-bottom: 1px solid #000; padding: 10px 0"
              :style="!market.outcomes.status || market.outcomes.status === 'ACTIVE' ? {} : {'background': '#c4c7d4'}"
            >
              <div style="display: flex; justify-content: space-between; position: relative">
                <div>
                  <span>
                    <b v-if="item.event_sportService != 'LIVE'" style="color: #fff; font-size: 14px">
                      {{`${d(new Date(item.event_startTime), "shortDate")} ${d(new Date(item.event_startTime), "time")}`}}
                    </b>
                    <span v-else style="color: #fff; font-size: 14px">
                      <b>
                        {{ item.event_liveStatus }}&nbsp;
                      </b>
                      {{ item.event_matchClock }}
                    </span>
                  </span>
                </div>
                <div @click="deleteOdds({ item: item, marketId: market.marketId,  })" style="padding: 0 5px 0 0; color: #fff; cursor: pointer">
                  <img alt="close" src="../../../shared/assets/close.svg">
                </div>
              </div>
              <div
                class="odds-container"
              >
              <!-- :style="market.outcomes.status == 'ACTIVE' ? {} : {'background': '#bbbbbb'}" -->
                <template v-if="market.outcomes.specifiers">
                  <b>{{ buildSpecifierStr(item, market) }}</b>
                </template>
                <!-- <b>{{ market.outcomes.specifiers ? market.outcomes.name.replace(`${ market.outcomes.specifiers }`, '').replace('()', '') : market.outcomes.name }}&nbsp;</b> -->
                <b v-else>{{ market.outcomes.name || market.outcomes.shortName || market.outcomes.type }}&nbsp;</b>

                <div style="display: flex">
                  <template v-if="market.outcomes.status == 'ACTIVE' && market.outcomes.initialOdds !== market.outcomes.odds">
                    <b style="text-decoration: line-through; color: #fff; display: inline-block; padding: 0 10px">{{ market.outcomes.initialOdds }}</b>
                  </template>

                  <span style="display: flex; align-items: center;" v-show="market.outcomes.oddsChange && market.outcomes.status == 'ACTIVE'">
                    <div  class="triangle-up" v-if="market.outcomes.oddsChange === 'UP'"></div>
                    <div v-if="market.outcomes.oddsChange === 'DOWN'" class="triangle-down"></div>
                  </span>
                  <b v-if="market.outcomes.status == 'ACTIVE'">{{ market.outcomes.odds }}</b>
                  <img
                    v-if="market.outcomes.status != 'ACTIVE'"
                    class="betslip-odds-container__lock-icon base-svg-size"
                    src="../../../shared/assets/lock.svg"
                    alt="lock"
                  />
                </div>
              </div>
              <div>
                <span v-if="item.event_sportService == 'LIVE'">{{item.event_currentScore}}&nbsp;</span>
                <span>
                  {{ market.marketName
                    .replace('(Competitor1)', item.participants?.home?.fullName)
                    .replace('(Competitor2)', item.participants?.away?.fullName)
                  }}
                </span>
              </div>
              <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                <b style="color: #fff">{{ item.event_name }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="betslip-footer">
      <div class="clear-bet-container">
        <div>
          <ui-button class="btn-primary" :baseBtnConfig="clearBetsBtn" />
        </div>
        <div style="height: 52px; width: 130px" v-if="outcomeLength >= 3">
          <ui-dropdown :baseDropDown="selectSystemConfig" @selectedItem="(e) => {betVariant = e.id}"/>
        </div>
      </div>
      <div class="max-bet-container">
          <ui-input :baseInputConfig="amountFieldConfig"/>
          <ui-button class="btn-secondary" :baseBtnConfig="maxBetBtnConfig" />
      </div>

      <div>
        <div v-if="betVariant == 'MULTIPLE' || betVariant == 'SINGLE'">
          {{ $t("total_odds") }}: {{ possibleWin.totalOdds }}
        </div>
        <div>{{ $t("possible_win") }}: {{ possibleWin.possibleWin }}</div>
      </div>

      <div style="color: red; font-size: 14px; text-align: center" v-if="invalidBetSlipText">{{ invalidBetSlipText }}</div>

      <div style="padding: 10px 0; text-align: center;">
        <ui-button class="btn-brand" :baseBtnConfig="placeBetBtnConfig" />
      </div>
    </footer>

    <loading-text v-if="placeBetInProgress" />
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, onBeforeUnmount, onUpdated } from 'vue';
import { useStore } from 'vuex';
import { betObj } from '@/components/cashdesk/bet/print/createObj/betObj.js';
import { errObj } from '@/components/cashdesk/bet/print/createObj/errorObj.js';
import { createCuoponObj } from '@/components/cashdesk/bet/print/createObj/cuoponObj.js';
import { errorCodeObject } from '@/entities/notifications/model/errorCodeObject.js';
import { SET_TIMEOUT } from '@/shared/constants/constants.js';
import { countSystemCombinationK } from '../modals/sportsBookFunc.js';
import { couponPrint } from '@/entities';//'@/entities/coupon/model/couponPrint.js';

import { useI18n } from 'vue-i18n';

import Cashdesk from '@/shared/api/cashdesk/cashdesk.js';
const cashdeskApi = new Cashdesk();

export default {
  props: ['preOrserId'],
  emits: ['goBack'],
  components: {
    //loadingText
  },
  setup(props, { emit }) {
    const {t, d} = useI18n();
    const store = useStore();
    const betVariant = ref('MULTIPLE');
    const loading = ref(false);
    const chengeCefs = ref('YES');
    const invalidBetSlipText = ref(null);
    const amountFieldConfig = {
      labelText: computed(() => t('bet_amount')),
      model: ref(''),
      width: 'calc(100vw - 155px)',
      type: 'number'
    }

    const placeBetInProgress = ref(false);

    const betAmount = ref(amountFieldConfig.model);

    const deleteOdds = item => {
      store.commit('oddsBasketStore/ODDS_BASKET_DELETE_ITEM', item);
    }

    watch(() => store.getters['couponStore/COUPON_DATA'], () => {
      if (store.getters['couponStore/COUPON_DATA']) {
        couponPrint(store.getters['couponStore/COUPON_DATA']);
      }
    })

    watch(() => store.getters["oddsBasketStore/BOOKING"], () => {
      if (store.getters["oddsBasketStore/BOOKING"]) {
        chengeCefs.value = 'NO';
        betVariant.value = store.getters["oddsBasketStore/BOOKING"];
      } else {
        chengeCefs.value = 'YES';
      }

    })

    const clearAllBets = () => {
      store.commit('oddsBasketStore/CLEAR_ODDS_BASKET');
      invalidBetSlipText.value = null;
    };

    watch(() => betAmount.value, () => {
      betAmount.value = String(betAmount.value).replace(',', '.')
    })

    const amountState = computed(() => {
      const t = /[^\d.]/g;
      const findDot = String(betAmount.value).indexOf('.');
      let afterDot = ''
      if (findDot != -1) {
        afterDot = betAmount.value.substring(findDot + 1, betAmount.value.length)
      }
      return betAmount.value != '0' && !t.test(+betAmount.value) && afterDot.length <= 2;
    })

    const buildSpecifierStr = (event, market) => {
      const outcomeType = market.outcomes.type;

      return outcomeType.replace('{participant2}', event.participants?.away?.shortName).replace('{participant1}', event.participants?.home?.shortName).replace('{specifier}', '').replace('()', '').replace('{participant}', '').replace('{player}', '') + ' ' + market.outcomes.specifiers;
    };


    const outcomeLength = computed(() => {
        return store.getters["oddsBasketStore/ODDS_BASKET"].reduce((count, item) => {
          return count = count + item.market.length;
        }, 0)
    })

    const possibleWin = computed(() => {
      if (store.getters["oddsBasketStore/ODDS_BASKET"].length) {
        if (isNaN(betVariant.value)) {
          const factor = store.getters["oddsBasketStore/ODDS_BASKET"].reduce((factor, item) => {
            const outcomeSumm = item.market.reduce((marketSumm, market) => {
              if (market.outcomes)
                return marketSumm =  marketSumm * market.outcomes.odds
            }, 1);

            return factor = factor * outcomeSumm;
          }, 1)

          return {
            totalOdds: factor.toFixed(2),
            possibleWin: (betAmount.value * factor).toFixed(2)
          }
        } else {
          const outcomesArr = store.getters["oddsBasketStore/ODDS_BASKET"].reduce((arr, item) => {
            const o = item.market.reduce((o, i) => {
              o.push(i.outcomes);
              return o;
            }, [])
            arr.push(...o);
            return arr;
          }, [])

          const N_M = countSystemCombinationK(betVariant.value, outcomesArr);

          return {
            possibleWin: (N_M * betAmount.value).toFixed(2),
            totalOdds: N_M,
          }
        }

      }
      return {
        totalOdds: 0,
        possibleWin: 0
      }
    })

    const sistemArrFunc = () => {
      const marketCounter = [];
      marketCounter.length = outcomeLength.value - 1;

      for (let index = 0; index < marketCounter.length; index++) {
        marketCounter.splice(index, 1, {
          value: index + 1,
          text: `System${ index + 1 }/${ outcomeLength.value }`
        })
      }

      return marketCounter;
    }

    const errorData = computed(() => store.getters["notificationsStore/ERROR_DATA"]);

    const balanceData = computed(() => store.getters["balanceState/BALANCE"]);

    const getBetResp = () => {
      if (localStorage['placeBetRequestId']) {
        cashdeskApi.getBetResponse(localStorage['placeBetRequestId'])
        .then(resp => {
          console.log('resp.data.requestStatus', resp.data.requestStatus)
          if (resp.data.requestStatus == 'PLACED') {
            placeBetInProgress.value = false;
            console.log("placed", resp.data);
            localStorage.removeItem('placeBetRequestId');
            loading.value = false;
            try {
              const createData = createCuoponObj({
                respData: resp.data.bets[0],
                possibleWin: possibleWin.value,
                userId: store.getters["cashierStore/USER_DATA"].userId,
                cuponType: 'placeBet'
              })
              store.commit('couponStore/ADD_COUPON_DATA', createData);
              localStorage.setItem('lastPlacedBet', resp.data.bets[0].betId);
            } catch (err) { console.log('errr', err)}

            const end = () => {
              clearAllBets();
              betAmount.value = 0;
              store.dispatch('balanceState/GET_BALANCE_DATA', { id: balanceData.value.id, currency: localStorage['currency'] })
              emit('goBack')
            }
            end();
          }
          else if (resp.data.requestStatus == 'REJECTED' || resp.data.requestStatus == 'ERROR') {
            placeBetInProgress.value = false;
            const errFunc = () => {
              localStorage.removeItem('placeBetRequestId');
              loading.value = false;
            }
            if (resp.data.rejectCode == 'INTERNAL_ERROR') {
              store.commit('notificationsStore/ADD_ERROR_DATA', {
                header: resp.data.rejectCode.replace('_', ' '),
                body: `${ t('error_500') } Request ID ${ resp.data.requestId }`,
              });
              errFunc()
            } else {
              store.commit('notificationsStore/ADD_ERROR_DATA', errObj(resp.data));
              errFunc();
            }

          } else {
            setTimeout(() => {
              getBetResp()
            }, SET_TIMEOUT);
          }
        })
        .catch(err => {
          loading.value = false;
          placeBetInProgress.value = false;
          errorCodeObject(err);
        })
      }
    }

    const validBetSlip = (betType) => {
      const lowerCaseType = betType.toLowerCase();
      const sportsbookConfig = store.getters['sportsbookConfigStore/SPORTSBOOK_CONFIG'];
      console.log(betType, sportsbookConfig)
      const maxNumOfOutcomes = sportsbookConfig[lowerCaseType].maxOutcomes;

      const result = outcomeLength.value <= maxNumOfOutcomes;

      if (result) {
        invalidBetSlipText.value = null;
      } else {
        invalidBetSlipText.value = t('invalid_betslip_text', {num: maxNumOfOutcomes})
      }

      return result;
    }

    const getPlaceBet = () => {
      if (amountState.value && store.getters["oddsBasketStore/ODDS_BASKET"].length) {
        loading.value = true;
        let betType = 'SINGLE';
        let sistemNum = null;

        placeBetInProgress.value = true;

        if (outcomeLength.value == 2) {
          betType = 'MULTIPLE'
        } else if (outcomeLength.value >= 3) {
          if (Number.isInteger(betVariant.value)) {
            betType = 'SYSTEM';
            sistemNum = betVariant.value;
          } else {
            betType = 'MULTIPLE';
          }

          if (!validBetSlip(betType, outcomeLength.value)) {
            console.log('INVALID BET');
            loading.value = false;
            return
          }
        }

        const betData = betObj({
          betAmount: betAmount.value,
          betType: betType,
          arr: store.getters["oddsBasketStore/ODDS_BASKET"],
          sistemNum: sistemNum,
          acceptOddsChanges: chengeCefs.value
        });

        cashdeskApi.sendBet(betData)
          .then(resp => {
            localStorage.setItem('placeBetRequestId', resp.data.placeBetRequestId);
            localStorage.setItem('cancelBetTimeout', resp.data.cancelTimeTtl);
            getBetResp();
          })
          .catch(err => {
            loading.value = false;
            placeBetInProgress.value = false
            errorCodeObject(err);
          })
      }
    }

    let oddsTimeOut;

    const watchOdds = () => {
      store.commit("SET_LOADER_DISABLE", true);
      if (store.getters["oddsBasketStore/ODDS_BASKET"].length > 0) {
        const filter = store.getters["oddsBasketStore/ODDS_BASKET"].reduce((eventIdArr, item) => {
          eventIdArr.push(item.event_id);
          return eventIdArr;
        }, []);
        try {
          store.dispatch('oddsBasketStore/SELECTED_ODDS', filter)
          .then(() => {
            oddsTimeOut = setTimeout(() => {
              watchOdds()
            }, SET_TIMEOUT);
          });
        } catch(err) {
          console.log(err);
        }
      } else {
        window.clearTimeout(oddsTimeOut);
      }
    }

    onBeforeUnmount(() => {
      store.commit('oddsBasketStore/CLEAR_ODDS_BASKET');
    })

    onMounted(() => {
      getBetResp();

      if (store.getters["oddsBasketStore/ODDS_BASKET"].length) watchOdds();
    })

    watch(() => store.getters["oddsBasketStore/ODDS_BASKET"].length, () => {
      watchOdds();

      if (outcomeLength.value < 3) {
        betVariant.value = 'MULTIPLE';
      }

      let betType = 'SINGLE';

      if (outcomeLength.value >= 3) {
        if (Number.isInteger(betVariant.value)) {
          betType = 'SYSTEM';
        } else {
          betType = 'MULTIPLE';
        }

        validBetSlip(betType, outcomeLength.value);
      }

      if (!store.getters["oddsBasketStore/ODDS_BASKET"].length)
        store.commit('oddsBasketStore/BOOKING_CHANGE', false);
    })

    watch(() => betVariant.value, () => {
      let betType = 'SINGLE';

      if (outcomeLength.value >= 3) {
        if (Number.isInteger(betVariant.value)) {
          betType = 'SYSTEM';
        } else {
          betType = 'MULTIPLE';
        }

        validBetSlip(betType, outcomeLength.value);
      }
    })

    const calcMaxBet = () => {
      let betType = 'SINGLE';
      let sistemNum = null;

      if (outcomeLength.value == 2) {
        betType = 'MULTIPLE'
      } else if (outcomeLength.value >= 3) {
        if (Number.isInteger(betVariant.value)) {
          betType = 'SYSTEM';
          sistemNum = betVariant.value;
        } else {
          betType = 'MULTIPLE';
        }
      }

      const outcomes = store.getters["oddsBasketStore/ODDS_BASKET"].reduce((arr, item) => {
        const outcomeObj = item.market.reduce((obj, market) => {
          if (market.outcomes.status == 'ACTIVE') {
            obj.push({
              odds: market.outcomes.odds,
              outcomeId: market.outcomes.id
            })
          }
          return obj;
        }, [])

        arr.push(...outcomeObj)
        return arr;
      }, [])

      const bets = [{
        acceptOddsChanges: chengeCefs.value,
        acceptParameterChanges: chengeCefs.value,
        betType: betType,
        outcomes: [...outcomes],
        locale: localStorage['lang'],
        betMoney: {
          betAmount: 1,
          currency: localStorage['currency']
        },
        requestId: 1
      }];

      if (sistemNum != null) {
        bets[0].winCombinationSize = sistemNum;
      }

      const requestData = {
        bets
      }

      cashdeskApi.getMaxBet(requestData).then(resp => {
        betAmount.value = resp.data.maxBetLimit / 100;
      })
    };

    const placeBetBtn = computed(() => {
      let message;

      if (betAmount.value == 0) {
        message = t('enter_amount_to_bet');
      } else {
        message = t('place_bet');
      }

      return message;
    })

    const selectSystemConfig = {
      options: computed(() => {
        let res = sistemArrFunc().map(obj => ({name: obj.text, id: obj.value}));
        res.unshift({id: 'MULTIPLE', name: 'MULTIPLE'});
        return res;
      }),
      baseText: computed(() => {
        if (typeof betVariant.value === 'number') {
          return `System${ betVariant.value }/${ outcomeLength.value }`
        } else {
          return betVariant.value
        }
      })
    }

    const notActive = computed(() => {
      let bool = false
      store.getters["oddsBasketStore/ODDS_BASKET"].forEach(eventItem => {
        eventItem.market.forEach(marketItem => {
          if (marketItem.outcomes.status != 'ACTIVE') {
            bool = true;
          }
        })
      })
      return bool;
    })

    const appType = computed(() => store.getters['APP_TYPE'])

    onUpdated(() => {
      if (appType.value === 'terminal') {
        store.getters["oddsBasketStore/ODDS_BASKET"].forEach((item) => {
          item.market.forEach(m => {
            if (m.outcomes?.oddsChange && m.outcomes?.oddsChange !== '') {
              const odds = m.outcomes.odds ? m.outcomes.odds : 'No active'
              try {
                window
                    .Android
                    .alertMessage(`${m.outcomes.name} ${m.outcomes.initialOdds} -> ${odds}`)
              } catch (err) {
                console.log(err)
              }
            }
          })
        })
      }

    })

    return {
      invalidBetSlipText,
      calcMaxBet,
      buildSpecifierStr,
      d,
      chengeCefs,
      betAmount,
      deleteOdds,
      betVariant,
      clearAllBets,
      amountState,
      getPlaceBet,
      loading,
      sistemArrFunc,
      outcomeLength,
      possibleWin,
      errorData,
      betSlipData: computed(() => {
        return store.getters["oddsBasketStore/ODDS_BASKET"]
      }),
      notActive,
      placeBetBtn,
      placeBetBtnConfig: {
        text: placeBetBtn,
        func: getPlaceBet,
        disabled: computed(() => (loading.value
            || betAmount.value == 0
            || betAmount.value == ''
            || outcomeLength.value == 0
            || !amountState.value
            || notActive.value
            || placeBetInProgress.value
            )
        )
      },
      amountFieldConfig,
      maxBetBtnConfig: {
        text: 'MAX BET',
        func: calcMaxBet,
        style: { width: '130px'}
      },
      clearBetsBtn: {
        text: computed(() => t('clear_all_bets')),
        func: clearAllBets,
      },
      selectSystemConfig,
      placeBetInProgress
    }
  },
}
</script>

<style scoped>
  .outcomes-overflow {
    position: relative;
  }
  .betslip-footer {
    background-color: #14151e;
    position: fixed;
    height: 250px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 10px;
  }
  .overflow-container {
    height: 100%;
  }
  .betslip-conteiner {
    padding: 0.5rem 1rem;
    overflow: auto;
    height: calc(100vh - 65px - 234px);
  }

  .odds-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .max-bet-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 0px;
    /* background: rgb(217, 217, 255); */
  }
  .clear-bet-container {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 1rem; */
  }
  .clear-bet-container > div {
    /*width: 50%;*/
    width: calc(100vw - 155px);
    padding: 2px 0;
  }
  .clear-bet-container > div > button {
    width: 100%;
  }
</style>
