<script setup>
  import { ref, nextTick, watch, onMounted, computed } from 'vue';
  import { errorCodeObject } from '@/entities';
  import { useStore } from 'vuex';
  import { bookingSearch } from '@/features';
  import shared from '@/shared';
  import { useI18n } from 'vue-i18n';

  const { SportData } = shared.api;

  const store = useStore();
  const {t} = useI18n();
  const topCompetitions = ref(null);
  const topCompetitionsMore = ref(null);
  const showMoreTC = ref(false);
  const showTopCompetition = ref(false);

  onMounted(async () => {
    await store.dispatch('tournamentsStore/LOAD_TOP_TOURNAMENTS');
    if (localStorage['showTC']) {
      showTopCompetition.value = JSON.parse(localStorage['showTC'])
    }
  });

  watch(() => store.getters['tournamentsStore/TOP_TOURNAMENTS_VIEW'], () => {
    showTopCompetition.value = store.getters['tournamentsStore/TOP_TOURNAMENTS_VIEW'];
  })

  watch(() => store.getters['tournamentsStore/TOP_TOURNAMENTS'],() => {
    const tcList = store.getters['tournamentsStore/TOP_TOURNAMENTS'];

    if (tcList.length <= 12) {
      topCompetitions.value = tcList;
    } else {
      topCompetitions.value = tcList.slice(0, 12);
      topCompetitionsMore.value = tcList.slice(12);
    }
  })

  const hideTC = () => {
    localStorage.setItem('showTC', false);
    showMoreTC.value = false;
    store.commit('tournamentsStore/TOP_TOURNAMENTS_VIEW', false);
  }

  const chooseTopCompetition = (competitionData) => {
    let id = competitionData.id;
    let competitionDataObj = competitionData;

    SportData.getTournament({
      filter: {
        tournamentId: id,
        fromDate: null,
        sportServices: ["LIVE", "PREMATCH"],
        timeHours: null,
        toDate: null
      }
    })
    .then(() => {
      store.commit('sportsStore/SET_SELECTED_SPORT_ID', competitionDataObj.sportId);
      nextTick(() => {
        store.commit('categoriesStore/SET_SELECTED_CATEGORIES_ID', competitionDataObj.categoryId);
        nextTick(() => {
          store.commit('tournamentsStore/SET_SELECTED_TOURNAMENT_ID', id);
          store.commit('tournamentsStore/SET_SELECTED_SPORT_FORM_ID', competitionDataObj.sportForm.id);
        })
      })
    })
    .catch(err => {
      errorCodeObject(err);
    })
  }
  const hideTCBtn = computed(() => ({
    func: hideTC,
    style: { border: 'none', transform: 'rotate(180deg)', color: 'var(--text-for-material)' },
    icon: '#unfold-icon',
  }))

  const moreBtn = computed(() => ({
    text: t('more'),
    func() {
      showMoreTC.value = !showMoreTC.value;
    },
    style: { 'min-height': '30px' }
  }));

  const loadSportImg = (id) => {
    return require(`@/shared/assets/sportIcons/sportIcon-${id}.svg`);
  }
</script>

<template>
  <div class="top-competitions top-competitions--designed" :style="{transform: showTopCompetition ? 'none' : 'scale(0)', transition: '.2s', position: 'absolute', top: '0', bottom: '0', left: '0', right: '0'}">
    <div class="top-competitions__header">
      <div>{{$t('top_competitions')}}</div>
      <ui-button
        class="hide-top"
        :baseBtnConfig="hideTCBtn"
        @click="hideTC"
      />
    </div>

    <div style="display: flex; height: calc(100% - 35px); position: relative">
      <div class="top-competitions__filter">
        <button @click="chooseTopCompetition(tournament)" class="top-competitions__item top-competitions__item--designed" v-for="tournament in topCompetitions" :key="tournament.id">
          <img width="24" height="24" :src="loadSportImg(tournament.sportId)">
          <div class="top-competition__name" style="width: 100%; text-align: center">{{tournament.name}}</div>
        </button>
      </div>

      <div class="top-competitions__actions">
        <ui-button
          v-if="topCompetitionsMore && topCompetitionsMore.length"
          class="btn-brand-outline"
          :baseBtnConfig="moreBtn"
          style="width: 40%; border-radius: 17px; padding: 3px;"
        >
          <template #button-body>
            <div style="height: 100%; display: flex; justify-content: center; align-items: center; padding: 5px">
              <div :class="{'more-arrow--active': showMoreTC}" class='more-arrow'></div>
            </div>
          </template>
        </ui-button>
        
        <bookingSearch style="width: 100%"/>
      </div>
    </div>
  </div>
  <div v-if="showMoreTC" class="top-competitions__filter top-competitions__filter--more top-competitions__filter--more--designed">
    <button @click="chooseTopCompetition(tournament)" class="top-competitions__item top-competitions__item--designed" v-for="tournament in topCompetitionsMore" :key="tournament.id">
      <img width="24" height="24" :src="loadSportImg(tournament.sportId)">
      <div class="top-competition__name" style="width: 100%; text-align: center">{{tournament.name}}</div>
    </button>
  </div>
</template>

<style scoped>
  .top-competitions__header {
    height: 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 0 10px;
  }
  .top-competitions {
    overflow: hidden;
    z-index: 1;
    color: var(--text-for-material);
    padding: 0 10px;
    transform-origin: 'bottom left';
    background-color: #fff;
  }
  .top-competitions--designed {
    background-color: var(--material);
  }
  .top-competitions__filter {
    display: flex;
    align-items: center;
    flex: 70;
    flex-wrap: wrap;
  }
  .top-competitions__filter--more {
    /* padding: 10px; */
    background-color: #fff;
    z-index: 100;
    position: absolute;
    top: 101%;
    right: 29%;
    left: 0;
    border-radius: 20px;
    margin: 0 10px;
  }
  .top-competitions__filter--more--designed {
    padding: 9px;
    background-color: var(--material);
    z-index: 100;
    position: absolute;
    top: 105%;
    right: 29%;
    left: 0;
    border-radius: 20px;
    margin: 0;
  }
  .top-competitions__filter--more > .top-competitions__item {
    width: 23%;
    margin: 0.5% 1%;
    margin-bottom: 1px;
  }
  .top-competitions__item {
    font-size: 14px;
    display: flex;
    align-items: center;
    /* color: grey; */
    border: 1px solid #ccc;
    padding: 3px 10px;
    border-radius: 17px;
    width: 23%;
    margin: 0 1%;
    margin-bottom: 1px;
    background-color: #fff;
    height: 30px !important;
    min-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .top-competitions__item--designed {
    color: var(--text-for-brand);
    background-color: var(--brand);
    border: none;
  }
  .top-competition__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-competitions__actions {
    flex: 29;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  .hide-top {
    background-color: transparent;
    width: 5%;
  }
  .more-arrow {
    width: 10px; 
    height: 10px; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transform: translateY(-2px);
    
    border-bottom: 7px solid var(--brand);
    transition: 0.2s;
  }
  .more-arrow--active {
    transform: rotateZ(180deg) translateY(-3px);
  }
</style>
