<script setup>
import { computed, onMounted, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();
const store = useStore();
const coefficientVariant = ref('');

const changeCoefficientVariant = computed(() => [
  {
    id: 0,
    name: computed(() => t('always_accept')),
    value: 'YES',
  },
  {
    id: 1,
    name: computed(() => t('accept_increased')),
    value: 'UP',
  },
  {
    id: 2,
    name: computed(() => t('always_ask')),
    value: 'NO',
  }
]);

const changeVariant = (variant) => {
  const electedVariant = variant?.value ? variant.value : variant;
  coefficientVariant.value = electedVariant;
  store.commit('oddsBasketStore/SET_CHANGE_COEFFICIENT', electedVariant);
}

onMounted(() => {
  coefficientVariant.value = store.getters['oddsBasketStore/GET_CHANGE_COEFFICIENT'];
})

const baseDropDown = {
  options: changeCoefficientVariant.value,
  style: { width: '100%' },
  model: computed(() => {
    return coefficientVariant.value
  }),
}
</script>

<template>
  <ui-dropdown
    :baseDropDown="baseDropDown"
    @selectedItem="changeVariant"
  />
</template>
