import { gridFormatData } from './gridFormatData';

export function findOutright(data, eventsListData, mainTypes) {
  const formatData = gridFormatData(mainTypes, eventsListData.value);

  const findOutright = formatData.find((i) => i.outright === true);

  if (findOutright) {
    const index = formatData.findIndex((i) => i.outright === true);
    formatData.splice(index, 1);
    formatData.push(findOutright);
  }
  data.value = formatData;
}
