<template>
  <div class="transactions-container">
    <div class="transactions-balance-info">
      <headerBalance />
    </div>
    <div class="transactions-content-container" v-if="jsonData" ref="history_table">
      <div
          class="transaction-item-container"
          v-for="item in jsonData"
          :key="item.transferId"
          @click="showBetInfo(item)"
      >
        <div class="transaction-item">
          <div style="width: 50%; display: flex; align-items: center">
            <div
                class="arrow-right"
                :style="selectedBet === item.transferId ? { 'transform': 'rotate(90deg)', 'transition': '0.2s' } : {}"
            ></div>
            {{ dateFormat(item.date, 'mmm dd h:MM TT') }}
          </div>
          <div class="flex-between" style="width: 50%">
            <div>
<!--              {{ item.details.toLowerCase() }}-->
              {{ formatDetails(item.details) }}
            </div>
            <div
                :style="item.balanceAfter > item.balanceBefore ? {'color': '#00b74e'} : {'color': '#f66'}"
                class="ff-bold"
            >
              {{ round(item.amount) }}
            </div>
          </div>
        </div>

        <transition name="details">
          <div class="transaction-item-detail" v-if="selectedBetInfo && selectedBet === item.transferId">
            <div>
              <span class="ff-bold">{{ $t('transfer_id') }}:&nbsp;</span>
              <span>{{ item.transferId }}</span>
            </div>
            <template v-if="item.betId && item.betType">
              <div>
                <span class="ff-bold">{{ $t('bet_id') }}: </span>
                <span>{{ item.betId }}</span>
              </div>
              <div>
                <span class="ff-bold">{{ $t('bet_type') }}: </span>
                <span>{{ item.betType }}</span>
              </div>
            </template>
            <template v-else>
              {{ item.details }}
            </template>
            <div
                v-for="(outcome, index) in selectedBetInfo"
                :key="index"
                class="transaction-item-detail-outcome"
            >
              <div class="flex-between">
                <span>{{ outcome.outcome }}</span>
                <span>{{ outcome.odds }}</span>
              </div>
              <div>{{ outcome.marketName }}</div>
              <div>
                {{ $t('status') }}:
                <span :style="outcome.outcomeStatus === 'WIN' ? {'color': '#00b74e'} : {'color': '#f66'}">
                  {{ outcome.outcomeStatus.replace('_', ' ').toLowerCase() }}
                </span>
              </div>
              <div>
                 {{ outcome.sportName }} | {{ outcome.categoryName }} | {{ outcome.tournamentName }}
              </div>
            </div>
          </div>
        </transition>

      </div>
    </div>
    <div style="display: flex; width: 100%; justify-content: center; padding: 10px;">
      <ui-button class="btn-brand" :baseBtnConfig="placeBetBtnConfig" />
    </div>
    <ui-popup v-if="showPopupModal" @y="printReport" @n="showPopupModal = false">
      <template v-slot:modal-popup-body>
        <span>{{ $t('are_you_sure_you_want_to_print_report') }}</span>
      </template>
    </ui-popup>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import { errorCodeObject } from "@/entities/notifications/model/errorCodeObject";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { createCuoponObj } from '@/components/cashdesk/bet/print/createObj/cuoponObj.js';
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';
import { couponPrint } from '@/entities';

import dateFormat from "dateformat";

import { headerBalance } from '@/features';

import shared from '@/shared';

const {Cashdesk} = shared.api;

export default {
  components: {
    headerBalance
  },
  props: ['componentData'],
  setup() {
    const jsonData = ref([]);
    const history_table = ref(null);
    const respData = ref(null);
    const store = useStore();
    const selectedBet = ref(null);
    const selectedBetInfo = ref(null);
    const showInfo = ref(false);
    const showPopupModal = ref(false);
    const { t } = useI18n();

    const req = {
      limit: 100,
      offset: 0
    }

    watch(() => store.getters['couponStore/COUPON_DATA'], () => {
      if (store.getters['couponStore/COUPON_DATA']) {
        couponPrint(store.getters['couponStore/COUPON_DATA']);
      }
    })

    const getData = () => {
      Cashdesk.cashierHistory(req)
          .then(resp => {
            respData.value = resp.data.histories;
            jsonData.value.push(...respData.value);
            req.offset = resp.data.paging.offset;
          })
          .catch(err => {
            const codeNumber = err.message.replace(/[^\d]/g, '');
            if (codeNumber != '404') {
              errorCodeObject(err);
            }
          })
    }

    const showBetInfo = (bet) => {
      selectedBetInfo.value = null;
      if (selectedBet.value == bet.transferId) {// bet.betId
        showInfo.value = !showInfo.value;
        selectedBet.value = null;
      } else if (bet.betId) {
        Cashdesk.getBetData(bet.betId).then(response => {
          selectedBet.value = bet.transferId;//bet.betId;
          selectedBetInfo.value = response.data.betsInfo;
        })
      } else {
        selectedBet.value = bet.transferId;
        selectedBetInfo.value = [];
      }
    };

    onMounted(() => {
      getData();
    })

    watch(() => history_table.value, () => {
      if (history_table.value) {
        history_table.value.addEventListener('scroll', () => {
          if (history_table.value.scrollTop + history_table.value.clientHeight >= history_table.value.scrollHeight) {

            if (respData.value.length) getData();
          }
        })
      }
    })

    const printReport = async () => {
      const {data} = await Cashdesk.getShiftStatistic();

      const printData = createCuoponObj({
        respData: data,
        cuponType: 'transactions',
      })

      store.commit('couponStore/ADD_COUPON_DATA', printData);

      showPopupModal.value = false;
    }

    return {
      showPopupModal,
      printReport,
      history_table,
      jsonData,
      respData,
      selectedBetInfo,
      selectedBet,
      dateFormat,
      showBetInfo,
      round: data => (data / 100).toFixed(2),
      formatDetails: (str) => {
        const loverCaseStr = str.toLowerCase();
        if (loverCaseStr.includes('deposit')) {
          return t('deposit');
        } else {
          return loverCaseStr.split('from')[0];
        }
      },
      balanceInfo: computed(() => store.getters["balanceState/BALANCE"]),
      amountInDollars,
      t,
      placeBetBtnConfig: {
        text: computed(() => t('print')),
        func() {showPopupModal.value = true},
        // disabled: computed(() => !!jsonData.value),
        style: { width: '90%' }
      },
    }
  }
}
</script>

<style scoped>
 .transactions-container {
   padding: 1px;
   background-color: #222430;
 }
 .transactions-content-container {
   background-color: #222430;
   color: #e8e9ee;
   height: calc(100vh - 175px);
   overflow: auto;
   display: block;
 }
 .transaction-item {
   display: flex;
   padding: 10px 3px;
   font-size: 14px;
 }
 .transaction-item-detail {
   padding-left: 5px;
   background: #14151e;
   font-size: 12px;
   color: #868c98;
 }
 .ff-bold {
   font-weight: bolder;
 }
 .transaction-item-detail-outcome {
   border-top: 1px solid black;
   border-bottom: 1px solid black;
   padding: 5px 0;
 }
 .transactions-balance-info {
   padding: 5px 0;
   text-align: center;
   font-weight: bold;
   background-color: #222430;
   color: #e8e9ee;
 }
 .arrow-right {
   width: 0;
   height: 0;
   border: 0 solid transparent;
   border-bottom-width: 6px;
   border-top-width: 5px;
   border-left: 6px solid #fff;
   display: inline-block;
   margin-right: 2px;
 }
 .details-enter-active {
   animation: details-in 0.2s;
 }
 .details-leave-active {
   animation: details-in 0.25s reverse;
 }
 @keyframes details-in {
   0% {
     transform: scale(0);
     opacity: 0;
   }
   100% {
     transform: scale(1);
     opacity: 1;
   }
 }
</style>
