<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const store = useStore();

  store.dispatch('sportsStore/SPORT_LIST_DATA');

  const optionSelected = async (option) => {
    const id = isNaN(option) ? option.id :option;
    store.commit('sportsStore/SET_SELECTED_SPORT_ID', id)
    await store.dispatch('tournamentsStore/LOAD_TOP_TOURNAMENTS');
  }

  const baseDropDown = {
    options: computed(() => store.getters['sportsStore/GET_SPORT_LIST']),
    baseText: computed(() => t('sport'))
  }
</script>

<template>
  <ui-dropdown
    v-if="baseDropDown.options"
    :baseDropDown="baseDropDown"
    @selectedItem="optionSelected"
  />
</template>
