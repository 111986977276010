import { errorCodeObject } from '@/entities';
import shared from '@/shared';
const { SportData } = shared.api

// import SportData from '@/shared/api/sport-data/sportData.js';
// const sportData = new SportData();

export default async function requestForPrintData(dataArr, dateFilter) {
  return await Promise.all(
    dataArr.map(async item => {
      return await Promise.all(
        item.ids.tournamentIds.map(id => {
          return SportData.eventsInSport({
            filter: {
              ...dateFilter,
              sportId: item.ids.sportId,
              categoryId: item.ids.categoryId,
              tournamentId: id.id,
              sportServices: ["PREMATCH"],
              onlyMainMarketTypes: true,
            }
          })
            .then(resp => resp.data.data)
        })
      )
    })
  )
    .catch(err => {
      errorCodeObject(err);
    })
}
