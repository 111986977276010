import { store } from '@/app/store/store';
const findSportFormIDBySelectedTournament = () => {
  const tournamentID = store.getters['tournamentsStore/GET_SELECTED_TOURNAMENT_ID'];

  const findTournament = store.getters['tournamentsStore/GET_TOURNAMENTS_LIST']
    .find((tournament) => tournament.id === tournamentID);

  return findTournament.sportForm.id;
}
export function findSportForms() {
  const sportForms = store.getters['tournamentsStore/GET_SPORT_FORMS'];
  const selectedSportFormID = store.getters['tournamentsStore/GET_SELECTED_SPORT_FORM_ID'];
  const sportFormID = selectedSportFormID
    ? selectedSportFormID
    : findSportFormIDBySelectedTournament();

  return sportForms[sportFormID]?.MAIN || []
}
