// import { roundOdds } from '../outcome/index.js'
// import SystemCombinationsMap from './system.combinations.js'

export function rFact (num) {
  if (num === 0) {
    return 1
  } else {
    return num * rFact(num - 1)
  }
}

/*
  Highest common factor / Greatest common divisor
*/
export function gcd (a, b) {
  // Since there is a limited precision we need to limit the value.
  if (b < 0.01) return a

  // Discard any fractions due to limitations in precision.
  return gcd(b, a % b)
}

export function toFraction (decimal) {
  const [
    ldp,
    rdp
  ] = decimal.toFixed(3).split('.')

  const numerator = (ldp + rdp)
  const denominator = Math.pow(10, 3)

  const divisor = gcd(numerator, denominator)

  return (numerator / divisor) + '/' + (denominator / divisor)
}

/*
  Method for calculating N/M system odds
*/
export const countSystemCombinationK = (k, bets) => {
  let count = 0;
  var tempTotalOdd = 0
  var indexArray = []
  const betsCount = bets.length

  var indexMaxArray = []
  for (let i = 0; i < k; i++) {
    indexArray[i] = i
    indexMaxArray[i] = betsCount - i
  }

  indexMaxArray = indexMaxArray.reverse()

  let tempIterator = k - 1

  while (indexArray[0] <= indexMaxArray[0]) {
    if (indexArray[tempIterator] < indexMaxArray[tempIterator]) {
      if (tempIterator !== k - 1) {
        tempIterator = k - 1
        continue
      }

      let tempOdd = 1

      for (let m = 0; m < k; m++) {
        const bet = bets[indexArray[m]]
        tempOdd *= (bet.odds)
      }

      count++

      tempTotalOdd = (tempTotalOdd * 10 * 10 + (tempOdd * 10 * 10)) / 100;
      indexArray[tempIterator]++
    } else {
      tempIterator--
      indexArray[tempIterator]++

      for (let j = tempIterator; j < k - 1; j++) {
        indexArray[j + 1] = indexArray[j] + 1
      }
    }
  }

  return tempTotalOdd / count;
}

/*
  Method for counting system combinations amount (VComb)
  Using prepeared combinations map
  Begins from 2 win comb size and 3 outcomes amount
*/
// export const countSystemCombinations = (winCombinationSize, outcomesQuantity) => {
//   const xn = SystemCombinationsMap[winCombinationSize - 2]
//   if (xn === undefined) { return 0 }

//   const yn = xn[outcomesQuantity - 3]
//   if (yn === undefined) { return 0 }

//   return yn
// }

// export function getIntNumberFromDivision (n, divider) {
//   return Math.trunc((n % divider ? n : (n - 1)) / divider)
//}