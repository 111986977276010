<script setup>
import { ref, computed, onMounted, defineProps } from 'vue';
import { useStore } from 'vuex';

import dateFormat from 'dateformat';
import { formatData } from './formatData.js';
import { OutcomeService } from '@atlas/core';

import { useI18n } from 'vue-i18n';

const props = defineProps(['arrData', 'selectedPeriod']);
const { t } = useI18n();
const allData = ref(null);
const formatPrintData = ref(null);
const store = useStore();

const projectID = computed(() => store.getters['PROJECT_ID']);

allData.value = formatData(props.arrData);
const getItemObject = (item) => {
  return item.forEach(itemObj => {
    const findOutright = itemObj.events.find(i => i.outright === true);

    if (findOutright) {
      const index = itemObj.events.findIndex(i => i.outright === true);
      itemObj.events.splice(index, 1);
      itemObj.events.push(findOutright);
    }

    itemObj.events.forEach(event => {
      Object.values(event.markets).forEach(market => {
        if (market?.markets) {
          Object.values(market.markets).forEach(i => {
            i.outcomes = Object.values(i.outcomes).sort((a, b) => {
              return a.orderPosition - b.orderPosition;
            })
          })
        }
      })
    })
  })
}
const sortEvents = () => {
  allData.value.forEach(item => {
    if (item.length) getItemObject(item)
  })
}

try {
  sortEvents()
  formatPrintData.value = allData.value.reduce((arr, item) => {
    if (item.length) {
      const header = [...item[0].events].sort((a, b) => b.markets.length - a.markets.length)[0].markets;
      item.forEach(events => {
        events.events.forEach(evntItem => {
          const marketsTemplate = header.map(placeholder => {
            const market = evntItem.markets.find(m => m.id == placeholder.id);
            if (market) {
              return market
            } else {
              return { id: Number(new Date) }
            }
          })
          evntItem.markets = marketsTemplate;
        })
      })

      if (header.length > 4) {
        header.length = 4;
      }

      const obj = {
        header: header,
        events: { ...item }
      }

      arr.push(obj);
    }
    return arr;
  }, [])
} catch(err) {
  console.log(err);
  store.commit('notificationsStore/ADD_ERROR_DATA', {
    header: t("error"),
    body: t('error_default'),
  });
}

const period = computed(() => {
  let title = '';
  switch (props.selectedPeriod) {
    case '24h':
      title = `${t("daily_offer")} ${dateFormat(new Date(), 'dd/mm/yyyy')}`;
      break;
    case 'week':
      title = `${t("weekly_offer")} ${dateFormat(new Date(), 'dd/mm/yyyy')} - ${dateFormat(new Date(new Date().setDate(new Date().getDate() + 6)), 'dd/mm/yyyy')}`;
      break;
  }
  return title
})

const oddsFormat = (odds) => {
  const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
  return OutcomeService.formatOdds(odds, { oddsFormat })
}

const printLogoUrl = computed(() => store.getters['cashdeskConfigStore/GET_PRINT_LOGO_URL']);

onMounted(() => {
  if (projectID.value === 121) {
    let tds = document.querySelectorAll('td');
    let ths = document.querySelectorAll('th');

    [...tds, ...ths].forEach(cell => {
      cell.classList.add('toj-cell-style');
    });
  }
});

</script>
<template>
  <div v-if="printLogoUrl" class="print-logo-container print-line_logo-container">
    <img alt="logo" :src="printLogoUrl" />
  </div>

  <h3 class="center-content">{{ period }}</h3>

  <template v-if="formatPrintData.length">
    <div v-for="(tournament, index) in formatPrintData" :key="index">
      <h4>
        {{ tournament.events[0].events[0].sportName }}
      </h4>

      <table class="table print-line_table">
        <thead class="print-line_thead">
        <tr>
          <th>
            {{ $t("date") }}
          </th>
          <th>
            {{ $t("time") }}
          </th>
          <th>
            {{ $t("id") }}
          </th>
          <th style="max-width: 150px">
            {{ $t("name") }}
          </th>
          <th v-for="header in tournament.header" :key="header.id">
            <div style="text-align: center;">
              {{ header?.name }}
            </div>
            <div style="position: relative; width: 100%">
              <div class="odds-position">
                <div
                  v-for="outcome in Object.values(header.markets)[0].outcomes"
                  :key="outcome.id"
                  :style="{flex: Object.values(header.markets)[0].outcomes.length}"
                  :class="{'center-content': true , 'toj-outcome-style': projectID === 121 }"
                >
                  {{ outcome.shortName }}
                </div>
              </div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody
          v-for="(event, index) in tournament.events"
          :style="projectID === 121 && 'border: none'"
          :key="event.id"
          class="print-line_tbody"
        >
        <tr colspan="2">
          <td :colspan="4 + tournament.header.length">
            <b>
              {{ tournament.events[index].events[0].categoryName }} - {{ tournament.events[index].tournamentName }}
            </b>
          </td>
        </tr>
        <tr v-for="e in event.events" :key="e.id">
          <td>
            {{ dateFormat(e.startTime, 'dd/mm') }}
          </td>
          <td>
            {{ dateFormat(e.startTime, 'HH:MM') }}
          </td>
          <td>
            {{ e.id  }}
          </td>
          <td style="max-width: 150px">
            {{ Object.values(e.participants).reverse().map(participant => participant.shortName).join(' - ') }}
          </td>
          <td v-for="market in e.markets.filter((e, i) => i < 4)" :key="market.id">
            <div v-if="tournament.header.find(h => +h.id === +market.id)"
                 class="odds-position"
                 :style="e.outright ? { 'flex-wrap': 'wrap' } : {}"
            >
              <div
                v-for="r in Object.values(market.markets)[0].outcomes"
                :key="r.id"
                :class="{'center-content': true, 'toj-outcome-style': projectID === 121 }"
                style="font-size: 0.8rem; line-height: 1.5; padding: 3px"
                :style="e.outright ? { 'width': '20%', 'text-align': 'unset' } : {}"
              >
                <span style="font-size: 0.65rem" v-if="r.specifiers !== '' && r.odds">({{ r.specifiers }})</span>
                {{ r.odds && r.odds !== '' ? oddsFormat(r.odds)  : '-' }}
              </div>
            </div>
            <div v-else class="center-content">
              <b>---</b>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
  <template v-else>
    <h3 class="center-content">{{ $t('no_data') }}</h3>
  </template>
</template>

<style scoped>
  td, th {
    vertical-align: middle;
    position: relative;
    font-size: 0.6rem;
    width: max-content;
  }
  .center-content  {
    text-align: center;
    width: 100%;
  }
  .odds-position {
    display: flex;
    justify-content: space-between;
  }
  .print-logo-container {
    text-align: center;
  }
  .print-logo-container > img {
    height: 20px;
  }
  .toj-cell-style {
    margin: -1px;
    padding: 0 5px;
    border: 1px solid #ccc;
  }
  .toj-outcome-style {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .toj-outcome-style:not(:last-child) {
    border-right: 1px solid #ccc;
  }
</style>
