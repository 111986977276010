import { store } from '@/app/store/store';
import { getSiteAdress } from './getSiteAdress';
import i18n from '@/app/i18n.js';
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';

const dateTimeFormat = new Intl.DateTimeFormat('eu', {
  year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"
});

export const createShiftReportTemplate = (data) => {
  const auth = store.getters['cashierStore/AUTH'];
  const cashdeskData = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
  const logo = store.getters['cashdeskConfigStore/GET_LOGO'];
  const rows = [];
  const {respData} = data;

  if (logo && logo.logo) {
    rows.push(
      {
        type: 'logo',
        content: logo,
        yPadding: 0,
      }
    )
  }

  rows.push(
    {
      type: 'text',
      content: getSiteAdress(auth.sessionProject),
      prevContentHeight: logo && logo.height,
      yPadding: 1,
      xPadding: 1,
      font: 'titleBold',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: cashdeskData.betShopDto.name + ', ' + cashdeskData.betShopDto.address,
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: 'center'
    }
  )

  rows.push(
    {
      type: 'text',
      content: i18n.global.t('shift_report').toUpperCase(),//i18n.global.t('recharge_rec').toUpperCase(),
      yPadding: 2,
      xPadding: 1,
      font: 'fontBold',
      position: 'center'
    }
  )

  const date = dateTimeFormat.format(new Date());

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('date')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: date
    }
  )

  rows.push(
    {
      type: 'text',
      content: date,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('coupon.cashier_id')}`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: respData.cashierId
    }
  )

  rows.push(
    {
      type: 'text',
      content: respData.cashierId,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('coupon.cashier_name')}`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: respData.cashierName
    }
  )

  rows.push(
    {
      type: 'text',
      content: respData.cashierName,
      yPadding: 0,
      xPadding: 1,
      font: 'fontNormal',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('balance_before')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontBold',
      position: null,
      sameRowData: amountInDollars(respData.balanceBefore)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(respData.balanceBefore),
      yPadding: 0,
      xPadding: 1,
      font: 'fontBold',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('coupon.bet_amount')}`,
      yPadding: 4,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: amountInDollars(respData.betPlace)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(respData.betPlace),
      yPadding: 0,
      xPadding: 1,
      font: 'fontBold',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('deposit')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: amountInDollars(respData.deposit)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(respData.deposit),
      yPadding: 0,
      xPadding: 1,
      font: 'fontBold',
      position: 'right'
    }
  )

  // if(store.store.getters['APP_TYPE'] != 'terminal') {}
  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('payout_of_winning')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontNormal',
      position: null,
      sameRowData: amountInDollars(respData.payoutOfWinning)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(respData.payoutOfWinning),
      yPadding: 0,
      xPadding: 1,
      font: 'fontBold',
      position: 'right'
    }
  )
  
  // if (store.getters['APP_TYPE'] !== 'terminal') {
    rows.push(
      {
        type: 'text',
        content: `${i18n.global.t('payout_to_account')}:`,
        yPadding: 2,
        xPadding: 1,
        font: 'fontNormal',
        position: null,
        sameRowData: amountInDollars(respData.payoutToClientAccount)
      }
    )
  
    rows.push(
      {
        type: 'text',
        content: amountInDollars(respData.payoutToClientAccount),
        yPadding: 0,
        xPadding: 1,
        font: 'fontBold',
        position: 'right'
      }
    )
  // }

  rows.push(
    {
      type: 'line',
      content: '',
      yPadding: 2,
      xPadding: 1,
      font: 'fontBold',
      position: null
    }
  )

  rows.push(
    {
      type: 'text',
      content: `${i18n.global.t('balance_after')}:`,
      yPadding: 2,
      xPadding: 1,
      font: 'fontBold',
      position: null,
      sameRowData: amountInDollars(respData.balanceAfter)
    }
  )

  rows.push(
    {
      type: 'text',
      content: amountInDollars(respData.balanceAfter),
      yPadding: 0,
      xPadding: 1,
      font: 'fontBold',
      position: 'right'
    }
  )

  rows.push(
    {
      type: 'text',
      content: '',
      yPadding: 1,
      xPadding: 1,
      font: 'fontNormal',
      position: null
    }
  )

  return rows;
}
