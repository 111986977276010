<script setup>
import { ref, nextTick, defineEmits } from 'vue';
import {
  betslipOddsContainer,
  clearOddsBasket,
  betVariantSelector,
  betAmount,
  placeBetButton,
  changeCoefficientSelector,
  validBetType,
  cancelLastBetButton
} from '@/features';

const isValidBetType = ref(true);
const clearInputBetAmount = ref(false);
const emit = defineEmits(['cancelLastBet']);

const getPlaceBet = () => {
  document.querySelector('#placeBetButtonRef').click();
}
const validBetTypeResult = (result) => {
  isValidBetType.value = result;
}

const betFinished = () => {
  clearInputBetAmount.value = true;
  nextTick(() => clearInputBetAmount.value = false);
}

const cancelLastBet = (betId) => {
  emit('cancelLastBet', betId)
};


</script>

<template>
  <ui-card>
    <template v-slot:card-header>
      <div class="flex-between">
        <div class="flex-between">
          <span class="mr-right-12">
            {{ $t("betslip") }}
          </span>

          <ui-navbar>
            <template v-slot:ui-navbar-body>
              <changeCoefficientSelector />
            </template>
          </ui-navbar>
        </div>

        <div>
          <cancelLastBetButton @cancelLastBet="cancelLastBet" />
        </div>
      </div>
    </template>

    <template v-slot:card-body>
      <betslipOddsContainer />
      <div class="items-sm-container">
        <clearOddsBasket />
        <betVariantSelector />
      </div>
      <betAmount :clearInputBetAmount="clearInputBetAmount" @getPlaceBet="getPlaceBet" />
      <validBetType @validBetTypeResult="validBetTypeResult" />

      <placeBetButton
        id="placeBetButtonRef"
        :isValidBetType="isValidBetType"
        @betFinished="betFinished"
      />

    </template>
  </ui-card>
</template>
