<template>
  <div v-if="points">
    <div v-if="points.length">
      <div
        class="point"
        v-for="point in points"
        :key="point.id"
      >
        <div class="point-row">
          <span class="point-row__item point-row__item--title">{{ $t("from") }}</span>
          <span class="point-row__item">{{ point.from.name }}</span>
        </div>
        <div class="point-row">
          <span class="point-row__item point-row__item--title">{{ $t("to") }}</span>
          <span class="point-row__item">{{ point.to.name }}</span>
        </div>
        <div class="point-row">
          <span class="point-row__item point-row__item--title">{{ $t("amount") }}</span>
          <span class="point-row__item" style="color: #01b64f; font-weight: 700">+ {{ amountInDollars(+point.amount) }}</span>
        </div>
        <ui-button class="btn-brand" :baseBtnConfig="confirmBtnConfig" @click="confirm(point.id)"/>
      </div>
    </div>

    <div v-else class="no-data-container">
      <h5>{{ $t("no_data") }}</h5>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { errorCodeObject } from '@/entities/notifications/model/errorCodeObject.js';
import { useI18n } from 'vue-i18n';
import { amountInDollars } from '@/shared/model/amount-in-dollars/amountInDollars.js';

import Cashdesk from '@/shared/api/cashdesk/cashdesk.js';
const cashdesk = new Cashdesk();
export default {
  name: 'moneyIn',
  props: [],
  emits: ['updateBalance', 'closeWindow'],

  setup(props, { emit }) {
    const points = ref(null);
    const {t} = useI18n();

    const getData = () => {
      return new Promise((resolve, reject) => {
        cashdesk.transferData({
          limit: 100,
          offset: 0
        })
        .then(resp => {
          points.value = resp.data.points;
          resolve(resp.data.points);
        })
        .catch(err => {
          errorCodeObject(err);
          reject();
        })
      })

    }

    onMounted(() => {
      getData();
    })

    const confirm = id => {
      cashdesk.confirmTransactions(id)
      .then(resp => {
        if (resp) {
          emit('updateBalance');
          getData()
          .then(arr => {
            if (arr.length == 0) {
              emit('closeWindow');
            }
          })
        }
      })
      .catch(err => {
        errorCodeObject(err);
      })
    }

    return {
      amountInDollars,
      props,
      confirm,
      points,
      round: data => (data / 100).toFixed(2),
      confirmBtnConfig: {
        text: computed(() => t('confirm')),
        func: () => {}
      }
    }
  },
}
</script>

<style scoped>
  .no-data-container {
    text-align: center;
    color: grey;
    padding: 1rem;
  }
  .point {
    color: var(--text-for-material);
    padding: 10px;
    margin: 5px 0;
  }
  .point:not(:last-child) {
    border-bottom: 1px solid #4f63d3;
  }
  .point-row {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
  .point-row__item {
    flex: 3;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .point-row__item--title {
    font-weight: 700;
    flex: 1;
    text-align: start;
  }
</style>
