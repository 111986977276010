<script setup>
import {
  computed,
  onMounted,
  onBeforeUnmount
} from 'vue';
import { useStore } from 'vuex';

import grid from './grid.vue';

const store = useStore();

const tournamentId = computed(() => store.getters['tournamentsStore/GET_SELECTED_TOURNAMENT_ID']);
const sportId = computed(() => store.getters['sportsStore/GET_SELECTED_ID']);
const categoryId = computed(() => store.getters['categoriesStore/GET_SELECTED_CATEGORIES_ID']);

const eventList = computed(() => store.getters['eventsState/GET_EVENTS_LIST']);

let greedTimeOut = true;

const getEventsData = async () => {
  await store.dispatch('eventsState/SPORT_LIST', {
    onlyMainMarketTypes: true,
    sportId: sportId.value,
    categoryId: categoryId.value,
    tournamentId: tournamentId.value,
  })

  if (greedTimeOut) {
    greedTimeOut = setTimeout( () => getEventsData(), 5000);
  }
}

onMounted(() => {
  getEventsData();
});
onBeforeUnmount( () => {
  window.clearTimeout(greedTimeOut);
  greedTimeOut = false;
  store.commit('eventsState/SET_EVENTS_LIST', null);
})

</script>

<template>
  <grid v-if="eventList" />
</template>
