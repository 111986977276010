import i18n from '@/app/i18n.js';

export default function() {
  return {
    BET_ID: i18n.global.t('coupon.bet_id'),
    TOTAL_ODDS: i18n.global.t('coupon.total_odds'),
    POSSIBLE_WIN: i18n.global.t('coupon.possible_win'),
    BET_AMOUNT: i18n.global.t('coupon.bet_amount'),
    BET_TIME: i18n.global.t('coupon.bet_time'),
    CASHIER_ID: i18n.global.t('coupon.cashier_id'),
    EVENT_TIME: i18n.global.t('coupon.event_time'),
    BOTTOM_TEXT: i18n.global.t('coupon.bottom_text'),
    BET_INFO: i18n.global.t('coupon.bet_info'),
    TYPE: i18n.global.t('coupon.type'),
    STATUS: i18n.global.t('coupon.status'),
    WIN: i18n.global.t('coupon.win'),
    LOSE: i18n.global.t('coupon.lose'),
    VOID: i18n.global.t('coupon.void'),
    UNSETTLED: i18n.global.t('coupon.unsettled'),
    SINGLE: i18n.global.t('coupon.single'),
    MULTIPLE: i18n.global.t('coupon.multiple'),
    SYSTEM: i18n.global.t('coupon.system')
  };
}
