<script setup>
import { computed, defineEmits, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useStore();

const emit = defineEmits(['tournamentOptionSelected']);

const baseDropDown = {
  options: computed(() => store.getters['tournamentsStore/GET_TOURNAMENTS_LIST']),
  baseText: computed(() => t('tournament'))
}

const getSportId = computed(() => store.getters['sportsStore/GET_SELECTED_ID']);
const getCategoryId = computed(() => store.getters['categoriesStore/GET_SELECTED_CATEGORIES_ID']);

watch(
  [() => getSportId.value, () => getCategoryId.value],
  () => {
    store.commit('tournamentsStore/SET_SELECTED_TOURNAMENT_ID', null);
    store.commit('tournamentsStore/SET_TOURNAMENTS_LIST', []);
    if (getSportId.value && getCategoryId.value) {
      store.dispatch('tournamentsStore/TOURNAMENT_LIST_DATA', {
        sportId: getSportId.value,
        categoryId: getCategoryId.value
      })
    }
  }
)

const optionSelected = (option) => {
  const id = isNaN(option) ? option.id :option;
  store.commit('tournamentsStore/SET_SELECTED_TOURNAMENT_ID', id)
  emit('tournamentOptionSelected', id)
}

</script>

<template>
  <ui-dropdown
    v-if="baseDropDown.options"
    :baseDropDown="baseDropDown"
    @selectedItem="optionSelected"
  />
</template>
