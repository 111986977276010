<template>
  <div style="color: #fff;">
    <customBetSlip @goBack="goBack" :preOrserId="preOrderId"/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { errorCodeObject } from '@/entities/notifications/model/errorCodeObject.js';
import customBetSlip from '@/components/cashdesk/sunmiComponents/customBetSlip.vue';

import Booking from '@/shared/api/public/booking.js';
const booking = new Booking();

export default {
  components: {
    customBetSlip
  },
  props: ['componentData'],
  emits: ['goTo'],
  setup(props, {emit}) {
    const store = useStore();
    const preOrderId = ref(null);

    onMounted(() => {
      findPreOrder(props.componentData.inputID);
    });

    const goBack = () => {
      emit('goTo', {component: 'sunmi-home'})
    }

    const findPreOrder = id => {
      preOrderId.value = id;
      store.commit('oddsBasketStore/CLEAR_ODDS_BASKET');

      booking.getPreOrder(id)
        .then(resp => {
          const betData = resp.data.bets;

          betData.forEach(item => {
            const betType = item.betSize ? item.winCombinationSize : item.betType;
            store.commit('oddsBasketStore/BOOKING_CHANGE', betType);

            item.outcomes.forEach(outcomeData => {
              const bookingData = {
                outcome: {
                  id: outcomeData.outcomeId,
                  odds: outcomeData.odds,
                  name: outcomeData.outcomeInfo.localizedOutcomeName || outcomeData.outcomeInfo.outcomeName
                },
                event: outcomeData.outcomeInfo
              }

              store.commit('oddsBasketStore/ODDS_BASKET_ADD_ITEM', bookingData);
            })
            store.commit('oddsBasketStore/INIT_BOOKING', item.outcomes);
          })
        })
        .catch(err => {
          errorCodeObject(err);
          goBack()
        })
    }

    return {
      props,
      preOrderId,
      goBack
    }
  }
}
</script>
