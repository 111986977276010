<script setup>
import {
  computed,
  onMounted,
  ref, 
  inject,
  defineProps,
  defineEmits
} from 'vue';
import { errorCodeObject } from '@/entities';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { createCuoponObj } from '@/components/cashdesk/bet/print/createObj/cuoponObj.js';
import { deposit } from '@/features';

import dateFormat from "dateformat";
import shared from '@/shared';

const {Cashdesk} = shared.api;

const props = defineProps(['componentData']);

const emit = defineEmits(['goTo']);

const couponData = ref(null);
const err404 = ref(false);
const showPopupModal = ref(false);
const showDepConfirm = ref(false);
const showPayoutAccount = ref(false);
const clientIdForDep = ref(null);
const err404Client = ref(false);
const successful = ref(false);
const { t } = useI18n();
const store = useStore();
const {amountInDollars, getErrorCode} = inject('sharedModel');

const findCoupon = id => {
  Cashdesk.findCoupon(id)
    .then(resp => {
      couponData.value = resp.data;
      err404.value = false;
    })
    .catch(err => {
      if (getErrorCode(err) == '404') {
        err404.value = true;
        couponData.value = false;
      } else {
        errorCodeObject(err);
      }
    })
}

const possibleWin = data => {
  if (data.betType != 'SYSTEM') {
    const allOdds = data.outcomes.reduce((odds, item) => {
      return odds = odds * item.odds;
    }, 1)

    return (allOdds * (data.amount / 100)).toFixed(2);
  } else {
    return ((couponData.value.details.betOdds * couponData.value.details.betMoney.betAmount) / 100).toFixed(2)
  }
}

const findClient = (id, event) => {
  Cashdesk.clientInfo(+id)
  .then(() => {
    err404Client.value = false;
    showDepConfirm.value = false;
    showPayoutAccount.value = true;
  })
  .catch(err => {
    if (getErrorCode(err) == '404') {
      clientIdForDep.value = '';
      err404Client.value = true;
      event.target.disabled = false;
    } else {
      errorCodeObject(err);
    }
  })
}

const printDepositReceipt = (jsonData, amount, id, clientId) => {
  const printData = createCuoponObj({
    respData: {
      ...jsonData,
      clientId
    },
    amount: +amount * 100,
    cuponType: 'deposit',
    completePaymentId: id
  })

  store.commit('couponStore/ADD_COUPON_DATA', printData);
};

const payoutDep = (data) => {
  const {clientId, print, jsonData, amount} = data;
  const betId = couponData.value.betId;

  Cashdesk.payoutDep({
    clientId,
    betId
  }).then((success) => {
    if (print) {
      printDepositReceipt(jsonData, amount, success.data.id, clientId);
    }
    store.commit('notificationsStore/MESSAGE_INFO_MODAL', t('transaction_success'));
    findCoupon(betId);
  }).catch(err => {
    errorCodeObject(err);
  }).finally(() => {
    showPayoutAccount.value = false;
  })
}

const disabledBtn = computed(() => {
  return couponData.value == null ||
    couponData.value.settlementStatus == null ||
    couponData.value.settlementStatus != 'WIN' &&
    couponData.value.settlementStatus != 'VOID' ||
    couponData.value.payoutInfoDto != null
})

const btnText = computed(() => `${ t('pay_out_cash') } ${ couponData.value?.details?.betMoney?.payout ? (couponData.value.details.betMoney.payout / 100).toFixed(2) : '' }`);
const cashdeskData = computed(() => store.getters["balanceState/BALANCE"]);

const payOutBtnConfig = {
  text: btnText,
  variant: 'btn-brand',
  disabled: computed(() => disabledBtn.value
    || +(cashdeskData.value.depositNodeBalance / 100).toFixed(2)
    < +(couponData.value?.details?.betMoney?.payout / 100).toFixed(2)),
  func: () => {
    showPopupModal.value = true
  },
  style: {width: '49%'}
}

const payOutAccountBtnConfig = {
  text: t('pay_out_account'),
  disabled: disabledBtn,
  func: () => showDepConfirm.value = true,
  variant: 'btn-brand',
  style: {width: '49%'}
}

const goDeposit = (event) => {
  let sum = (couponData.value?.details?.betMoney?.payout / 100).toFixed(2);

  if(sum && clientIdForDep.value){
    findClient(clientIdForDep.value, event);
  } else {
    event.target.disabled = false;
  }
}

const goPayOut = () => {
  Cashdesk.payout({ betId: couponData.value.betId })
  .then(resp => {
    console.log('resp pay out', resp);
    store.dispatch('balanceState/GET_BALANCE_DATA', {
      id: store.getters['balanceState/BALANCE'].id,
      currency: localStorage['currency']
    });
    showPopupModal.value = false;
    successful.value = true;
  })
  .catch(err => {
    showPopupModal.value = false;
    errorCodeObject(err);
  })
}

onMounted(() => {
  findCoupon(props.componentData.inputID);
})

const scanCouponInputConfig = {
  labelText: computed(() => t('bet_id')),
  validation: (value) => !isNaN(value),
  disabled: false,
  model: '',
  width: 'calc(100vw - 155px)',
  type: 'number',
  autofocus: true
};

const scanCouponBtnConfig = {
  text: computed(() => t('scan')),
  variant: 'btn-brand',
  style: {width: '130px'},
  func: () => {
    if (scanCouponInputConfig.validation(scanCouponInputConfig.model))
      findCoupon(scanCouponInputConfig.model)
  },
}

const depositData = computed(() => ({
  clientId: clientIdForDep.value,
  sum: (couponData.value?.details?.betMoney?.payout / 100).toFixed(2)
}));

const ok = () => {
  successful.value = false;
  emit('goTo', {
    component: 'sunmi-home',
  })
};
</script>

<template>
  <div v-if="!showPayoutAccount" class="scan-coupon-container">
    <div class="flex-between sunmi-sc-inputs-container">
      <ui-input :baseInputConfig="scanCouponInputConfig" />
      <ui-button :baseBtnConfig="scanCouponBtnConfig" />
    </div>
    <div v-if="err404" class="err-404-container">
      {{ $t('bet_not_found_check_bet_id') }}
    </div>

    <div class="pay-data-container" v-if="couponData" data-testid="pay-data-container">
      <h3>{{ $t('bet_info') }}</h3>
      <h5>
        {{ couponData.details.betType }} {{ couponData.details.betSize ? couponData.details.betSize : '' }}
        <b :style="couponData.settlementStatus && couponData.settlementStatus == 'WIN' ? {'color': 'green'} : {'color': 'red'}">
          {{ couponData.settlementStatus ? couponData.settlementStatus : 'No status' }}
        </b>
      </h5>
      <hr>
      <div class="money-container">
        <div>
          <span>{{ $t('bet_amount') }}</span> <b>{{ (couponData.details.betMoney.betAmount / 100).toFixed(2) + couponData.details.betMoney.currency }}</b>
        </div>
        <div style="padding: 0 20px">
              <span>
                {{ couponData.settlementStatus == 'UNSETTLED' ? $t('possible_win') : $t('pay_out') }}
              </span>
          <b>
            <template v-if="couponData?.details?.betMoney?.payout != null">
              {{ (couponData.details.betMoney.payout / 100).toFixed(2) + couponData.details.betMoney.currency }}
            </template>
            <template v-else>
              {{ possibleWin(couponData.details) + couponData.details.betMoney.currency }}
            </template>
          </b>
        </div>
      </div>

      <div class="outcome-container" v-for="outcome in couponData.details.outcomes" :key="outcome.outcomeId">
        <div>
          <div class="flex-between">
            <div>
              <b>{{ outcome.outcomeInfo.localizedOutcomeName ? outcome.outcomeInfo.localizedOutcomeName : outcome.outcomeInfo.outcomeName }}</b><br>
              <span>{{ outcome.outcomeInfo.localizedMarketName ? outcome.outcomeInfo.localizedMarketName : outcome.outcomeInfo.marketName }}</span>
            </div>
            <b style="padding: 0 20px">{{ outcome.odds }}</b>
          </div>
          <div>
            <b style="color: #6868fd;">{{ outcome.outcomeInfo.localizedEventName ? outcome.outcomeInfo.localizedEventName : outcome.outcomeInfo.eventName }}</b>
            <div>{{ dateFormat(outcome.outcomeInfo.eventStartTime, 'yyyy mmm dS h:MM TT') }}&nbsp;</div>
          </div>
          <h6 v-if="couponData.details.betType != 'SINGLE'">
            {{ $t('status') }}:
            <span :style="outcome.outcomeInfo.outcomeStatus == 'WIN' ? {'color': 'green'} : {'color': 'red'}">
                {{ outcome.outcomeInfo.outcomeStatus.toLowerCase().replace('_', ' ') }}
              </span>
          </h6>
        </div>
        <div>
          {{ outcome.outcomeInfo.localizedSportName }} |
          {{ outcome.outcomeInfo.localizedCategoryName }} |
          {{ outcome.outcomeInfo.localizedTournamentName }}
        </div>
      </div>

      <hr>
      <div v-if="couponData.details.betType != 'SYSTEM'">{{ $t('total_odds') }}: <b class="grey">{{ (couponData.details.betOdds).toFixed(2) }}</b></div>
      <div>{{ $t('bet_time') }}: <b class="grey">{{ $d(new Date(couponData.createTime), 'couponDate') }}</b></div>
      <div>{{ $t('bet_id') }}: <b class="grey">{{ couponData.betId }}</b></div>
      <div style="margin-bottom: 80px">
        <b v-if="couponData.payoutInfoDto">
          {{ $t('pay_out_info') }}:
          <span style="color: red">
              {{ $t('paid_out') }}
            </span>
        </b>
      </div>

      <div class="bottom-btn-container">
        <ui-button :baseBtnConfig="payOutBtnConfig" />
        <ui-button :baseBtnConfig="payOutAccountBtnConfig" />
      </div>
    </div>

    <ui-popup v-if="showPopupModal" @y="goPayOut" @n="showPopupModal = false">
      <template v-slot:modal-popup-body>
        <span>{{ $t('are_you_sure_you_want_to_pay_out') }}</span>
        <div>
          {{ (couponData?.details?.betMoney?.payout / 100).toFixed(2) + couponData?.details?.betMoney?.currency }}
        </div>
      </template>
    </ui-popup>

    <ui-popup v-if="showDepConfirm" @y="goDeposit" @n="showDepConfirm = false">
      <template v-slot:modal-popup-body>
          <div>
            <h4>
              {{ `${$t('pay_out')} ${amountInDollars(couponData?.details?.betMoney?.payout) } ${$t('to_clients_acc')}`}}
            </h4>
            <div style="display: flex; justify-content: center; align-items: center">
              <div>
                <b-form-input
                  v-model="clientIdForDep"
                  type="number"
                  style="border-radius: 5px; padding: 5px; text-align: center"
                  :placeholder="$t('client_id')"
                  autofocus
                />
              </div>
            </div>
            <b v-if="err404Client" class="grey">
              {{ $t('client_not_found_check_client_id') }}
            </b>
          </div>
        </template>
    </ui-popup>

    <ui-successful-popup v-if="successful" @ok="ok">
      <template v-slot:modal-popup-body>
        <span>{{ $t('transaction_success') }}</span>
      </template>
    </ui-successful-popup>
  </div>
  <deposit v-else :fromScanCoupon="depositData" @payout="payoutDep" />
</template>

<style scoped>
h3, h5 {
  margin: 0;
}
.sunmi-sc-inputs-container {
  padding: 1rem 10px;
}
.scan-coupon-container {
  padding: 1px;
}
.pay-data-container {
  background-color: #222430;
  color: #e8e9ee;
}
.money-container {
  /*width: 50%;*/
  display: flex;
}
.money-container > div {
  display: flex;
  flex-direction: column;
}
.outcome-container {
  padding: 10px 0;
}
.outcome-container > div {
  /*background: #f8f9fa;*/
  padding: 5px;
}
.bottom-btn-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 5px 0;
  background: #1c1e2b;
}
.err-404-container {
  color: #f66;
  font-weight: bold;
  text-align: center;
}
</style>
