<script setup>
  import { ref, computed, defineEmits } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  import { useSearchResult } from '../model';

  const store = useStore();
  const {t} = useI18n();
  const emit = defineEmits(['showResult']);

  const newSearchConfig = computed(() => ({
    labelText: computed(() => t('search_by_id_or_name')),
    model: ref(''),
  }));

  const findById = async (inputValue) => {
    await useSearchResult(inputValue);

    const searchResult = store.getters['eventState/GET_SEARCHED_DATA'];

    emit('showResult', searchResult);
  };
</script>

<template>
  <ui-search
    class="event-search-terminal"
    :config="newSearchConfig"
    @search="findById"
  />
</template>

