<script setup>
import {
  computed,
  ref,
  watch,
  defineEmits,
  defineProps,
  inject,
  onMounted
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { OutcomeService } from "@atlas/core";

import { calcMaxBet } from '../model';
import { countSystemCombinationK } from "@/components/cashdesk/modals/sportsBookFunc";

const { t } = useI18n();
const store = useStore();

const amountFieldConfig = {
  labelText: computed(() => t('bet_amount')),
  model: ref(''),
  width: 'calc(100% - 134px)',
  type: 'number',
  focus: computed(() => store.getters['oddsBasketStore/ODDS_BASKET'].length),
  id: 'bet-amount-input'
}

const betAmount = ref(amountFieldConfig.model);
const betAmountImputID = ref(null);
const maxBetValue = computed(() => store.getters['oddsBasketStore/GET_MAX_BET']);
const props = defineProps(['clearInputBetAmount'])
const emit = defineEmits(['getPlaceBet']);
const { amountInDollars } = inject('sharedModel');

const betVariant = computed(() => store.getters['oddsBasketStore/GET_BET_VARIANT']);

const oddsBasket = computed(() => store.getters['oddsBasketStore/GET_OUTCOME_LENGTH'])

const updateInputValue = () => {
  store.commit('oddsBasketStore/SET_BET_AMOUNT', betAmount.value);
}

watch(
  () => oddsBasket.value,
  () => {
    if (oddsBasket.value <= 2) {
      store.commit('oddsBasketStore/SET_BET_VARIANT', 'MULTIPLE');
    }
  }
)

watch(() => maxBetValue.value, () => {
  betAmount.value = maxBetValue.value
});

watch(() => betAmount.value, () => {
  updateInputValue();
});

const getPlaceBet = () => {
  emit('getPlaceBet')
};



const possibleWin = computed(() => {
  if (store.getters['oddsBasketStore/ODDS_BASKET'].length) {
    betAmountImputID.value ? betAmountImputID.value.focus() : null;
    if (isNaN(betVariant.value)) {
      const factor = store.getters['oddsBasketStore/ODDS_BASKET'].reduce((factor, item) => {
        const outcomeSumm = item.market.reduce((marketSumm, market) => {
          if (market.outcomes)
            return marketSumm * market.outcomes.odds;
        }, 1);

        return factor * outcomeSumm;
      }, 1)

      return {
        totalOdds: factor,
        possibleWin: betAmount.value * 100 * factor
      }
    } else {
      const outcomesArr = store.getters['oddsBasketStore/ODDS_BASKET'].reduce((arr, item) => {
        const o = item.market.reduce((o, i) => {
          o.push(i.outcomes);
          return o;
        }, [])
        arr.push(...o);
        return arr;
      }, [])

      const N_M = countSystemCombinationK(betVariant.value, outcomesArr);

      return {
        possibleWin: N_M * betAmount.value * 100,
        totalOdds: N_M,
      }
    }

  }
  return {
    totalOdds: 0,
    possibleWin: 0
  }
})

watch(
  () => [possibleWin.value.possibleWin, possibleWin.value.totalOdds],
  () => store.commit('oddsBasketStore/SET_POSSIBLE_WIN', possibleWin.value)
);

watch(
  () => props.clearInputBetAmount,
  () => props.clearInputBetAmount ? betAmount.value = '' : null
)

const oddsFormat = (odds) => {
  const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
  return OutcomeService.formatOdds(odds, { oddsFormat })
}

onMounted(() => {
  betAmountImputID.value = document.getElementById('bet-amount-input');
})

const getMaxBet = () => {
  store.commit('oddsBasketStore/SET_MAX_BET','');
  calcMaxBet();
}

const maxBetBtnConfig = {
  text: 'MAX BET',
  func: getMaxBet,
  style: { width: '130px'}
}

</script>

<template>
  <div class="items-sm-container">
    <ui-input
      :baseInputConfig="amountFieldConfig"
      @emitInputData="getPlaceBet"
    />

    <ui-button class="btn-brand" :baseBtnConfig="maxBetBtnConfig" />
  </div>
  <div style="font-size: 14px">
    <div v-if="betVariant === 'MULTIPLE' || betVariant === 'SINGLE'">
      {{ $t("total_odds") }}: {{ possibleWin.totalOdds === 0 ? possibleWin.totalOdds : oddsFormat(possibleWin.totalOdds) }}
    </div>
    <div>{{ $t("possible_win") }}: {{ amountInDollars(possibleWin.possibleWin) }}</div>
  </div>
</template>
