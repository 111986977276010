<script setup>
import { computed, defineEmits, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useStore();

const emit = defineEmits(['categoryOptionSelected']);

const baseDropDown = {
  options: computed(() => store.getters['categoriesStore/GET_CATEGORIES_LIST']),
  baseText: computed(() => t('category'))
}

const getSportId = computed(() => store.getters['sportsStore/GET_SELECTED_ID'])
watch(
  () => getSportId.value,
  () => {
    store.commit('categoriesStore/SET_SELECTED_CATEGORIES_ID', null)
    store.commit('categoriesStore/SET_CATEGORIES_LIST', []);
    if (getSportId.value) {
      store.dispatch('categoriesStore/GET_CATEGORIES_LIST', {
        sportId: getSportId.value
      })
    }
  }
)

const optionSelected = (option) => {
  const id = isNaN(option) ? option.id :option;
  store.commit('categoriesStore/SET_SELECTED_CATEGORIES_ID', id)
  emit('categoryOptionSelected', id)
}

</script>

<template>
  <ui-dropdown
    v-if="baseDropDown.options"
    :baseDropDown="baseDropDown"
    @selectedItem="optionSelected"
  />
</template>
