<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const time = ref();
const { d } = useI18n();

setInterval(() => {
  let now = new Date();
  time.value = d(now, 'headerDate');
})

const shiftEnd = computed(() => store.getters["cashierStore/SHIFT_END"])
const shortMonitorData = computed(() => store.getters['cashdeskConfigStore/SWITCH_MONITOR_VIEW']);
</script>

<template>
  <div class="shift-time-container">
    <div class="shift-time-container__time">
      {{ time }}
    </div>
    <div v-if="!shortMonitorData" class="shift-time-container__end-shift">
      {{ $t("end_of_shift") + shiftEnd }}
    </div>
  </div>
</template>
