import { createPlaceBetTemplate } from '@/components/cashdesk/bet/print/createObj/createPlaceBetTemplate.js';
import { createDepositTemplate } from '@/components/cashdesk/bet/print/createObj/createDepositTemplate.js';
import { createPayoutTemplate } from '@/components/cashdesk/bet/print/createObj/createPayoutTemplate.js';
import { createWithdrawTemplate } from '@/components/cashdesk/bet/print/createObj/createWithdrawTemplate.js';
import { createShiftReportTemplate } from '@/components/cashdesk/bet/print/createObj/createShiftReportTemplate.js';

const print = {
  placeBet: createPlaceBetTemplate,
  placeBetCopy: createPlaceBetTemplate,
  deposit: createDepositTemplate,
  payout: createPayoutTemplate,
  withdraw: createWithdrawTemplate,
  transactions: createShiftReportTemplate
};

export const createCuoponObj = data => {
  return {
    printData: print[data.cuponType](data),
    type: data.cuponType
  };
}