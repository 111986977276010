<script setup>
import { computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import dateFormat from 'dateformat';

const store = useStore();
const emit = defineEmits(['goToDetails']);
const formatDate = (date) =>  dateFormat(date, 'mmm dS h:MM TT')

const goToDetails = (id) => emit('goToDetails', id);

const data = computed(() => store.getters['eventState/GET_SEARCHED_DATA']);
</script>

<template>
  <div>
    <template v-if="data.length">
      <ui-card
        v-for="event in data"
        :key="event.id"
        :addCardHover="true"
        @click="goToDetails(event.id)"
        class="margin-bottom-8"
      >
        <template v-slot:card-header>
          <div class="flex-between">
            <i>
              {{ event.tournamentName }}
            </i>
            <div>
              {{ formatDate(event.startTime) }}
            </div>
          </div>
        </template>
        <template v-slot:card-body>
          <b>
            {{ event.name ? event.name.slice(0, event.name.indexOf(' vs ')) : '-' }} <br>
            {{ event.name ? event.name.slice(event.name.indexOf(' vs ') + 3, event.name.length) : '-' }}
          </b>
        </template>
      </ui-card>
    </template>
    <h5 v-else>
      {{ $t("nothing_was_found_according_to_the_search_parameters") }}
    </h5>
  </div>
</template>
