<template>
  <header :class="{'sunmi-header--with-frozen': selected === 'sunmi-home'}" class="header-container">
    <div
        v-if="cashdeskData && selected === 'sunmi-home'"
        class="flex-between w100"
    >
      <div>
        <div class="header-cashdesk-name">
          {{ cashdeskData.name }}
        </div>
        <headerBalance />
      </div>
      <switchLang/>
    </div>
    <div v-else-if="data.componentName" class="w100 else-container">
      <div class="flex-between back-container" @click="goHome">
        <div class="head-button-container"></div>
        &nbsp;<div>{{ $t('back') }}</div>
      </div>
      <div class="header-cashdesk-name hyphens-text_w_50">{{ $t(data.componentName) }}</div>
    </div>
  </header>
</template>

<script>
import { computed } from "vue";
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";

import switchLang from '@/features/switch-lang/components/switchLang.vue';
import { headerBalance } from '@/features';

export default {
  props: ['componentData', 'selectedComponent'],
  emits: ['goHome'],
  components: {
    headerBalance,
    switchLang
  },
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();

    const cashdeskData = computed(() => store.getters["balanceState/BALANCE"]);

    return {
      selected: computed(() => props.selectedComponent),
      data: computed(() => props.componentData),
      goHome: () => emit('goHome'),
      cashdeskData,
      t
    }
  }
}
</script>

<style scoped>
  /* .sunmi-header-height {
    height: 75px;
  } */
  .sunmi-header--with-frozen {
    height: 90px;
  }
  .w100 {
    width: 100%;
  }
  .header-cashdesk-name {
    font-size: 16px;
    font-weight: bold;
  }
  /* .heder-balance {
    font-size: 14px;
  } */
  .head-button-container {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 10px solid #e8e9ee;
  }
  .back-container {
    align-items: center;
  }
  .else-container {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .else-container > .back-container {
    position: absolute;
    left: 0;
  }
</style>
