<script setup>
import { ref, defineEmits, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const currentFilter = ref('all');
const store = useStore();
const emit = defineEmits(['useFilter']);
const {t} = useI18n();

const selectFilter = (key) => {
  currentFilter.value = key
  emit('useFilter', key);
}

const filtersList = ref(null);
const storeData = computed(() => store.getters['eventState/GET_EVENT_DATA']);

const createFilter = () => {
  const filterArr = Object.values(
    storeData.value.eventData.marketTypes
  ).reduce((arr, item) => {
    if (Object.values(item.markets).find(s => s.status === 'ACTIVE')) {
      item.groups.forEach(i =>
        arr.push({
          title: i.toLowerCase(),
          key: i.toLowerCase()
            .slice(0, i.indexOf('_') !== -1 ? i.indexOf('_') : i.length)
            .replace('football', 'players')
            .replace('yellow', 'cards')
            .replace('interim', 'minutes')
        })
      );
    }
    return arr;
  }, [])

  const uniqueKeys = new Set();
  const uniqueFilters = [];

  filterArr.unshift({
    title: 'all',
    key: 'all'
  });

  for (const item of filterArr) {
    if (!uniqueKeys.has(item.key)) {
      uniqueKeys.add(item.key);
      uniqueFilters.push(item);
    }
  }

  filtersList.value = uniqueFilters;
}

watch(
  () => storeData.value,
  () => {
    if (storeData.value) createFilter();
  }
)

</script>

<template>
  <div class="items-left-container">
    <div
      v-for="filter in filtersList"
      :key="filter.key"
      class="market-filter_item"
      :class="{ 'active-item': currentFilter === filter.key }"
      @click="selectFilter(filter.key)"
    >
      {{ t(filter.title) }}
    </div>
  </div>
</template>

