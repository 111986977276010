<script setup>
import { onMounted, ref, nextTick, watch } from 'vue';
import { useStore } from 'vuex';
import {
  dashboardHeader,
  buttonsNavbar,
  betslipCashierDashboard,
  cashdeskEventOrEvents,
  cashdeskBaseFilter
} from '@/widgets';
import { couponPrint } from "@/entities";

const store = useStore();

const showCancelLastBet = ref(null);

onMounted(() => {
  store.dispatch('sportsbookConfigStore/LOAD_SPORTSBOOK_CONFIG');
  store.dispatch('cashdeskConfigStore/GET_LOGO');
})

const cancelLastBet = (id) => {
  showCancelLastBet.value = id;
  nextTick(() => showCancelLastBet.value = null);
}

watch(() => store.getters['couponStore/COUPON_DATA'], () => {
  if (store.getters['couponStore/COUPON_DATA']) {
    couponPrint(store.getters['couponStore/COUPON_DATA']);
  }
})
</script>

<template>
  <dashboardHeader />
  <buttonsNavbar :showCancelLastBet="showCancelLastBet" />

  <div class="cashdesk_events-and-betslip">
    <div class="cashdesk_event-info-container-left-side">
      <cashdeskBaseFilter />
      <cashdeskEventOrEvents />
    </div>

    <div class="cashdesk_betslip-container-right-side">
      <div class="cashdesk_betslip">
        <betslipCashierDashboard @cancelLastBet="cancelLastBet" />
      </div>
    </div>
  </div>
</template>

