<script setup>
import { computed, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['cancelLastBet']);
const cancelLastBet = () => {
  let lastBetId = localStorage.getItem('lastPlacedBet');
  emit('cancelLastBet', lastBetId);
}

const baseBtnConfig = {
  text: computed(() => t('cancel_last_bet')),
  variant: 'btn-danger',
  func: cancelLastBet,
  style: { padding: '4px 16px', 'font-size': '14px', 'min-height': 'unset' }
}
</script>

<template>
  <ui-button :baseBtnConfig="baseBtnConfig" />
</template>
