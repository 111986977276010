<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();

const findBooking = (id) => {
  store.commit('oddsBasketStore/CLEAR_ODDS_BASKET');
  store.dispatch('oddsBasketStore/FIND_BOOKING_BY_ID', id);
}

const newSearchConfig = computed(() => ({
  labelText: computed(() => t('pre_order_id')),
  model: ref(''),
  style: { width: '30%' },
  validation(value) {
    return  !isNaN(+value) && (!value.toString().includes('.'))
  },
}));

</script>

<template>
  <ui-search
    :config="newSearchConfig"
    @search="findBooking"
  />
</template>
