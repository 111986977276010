<script setup>
  import { computed, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  const { amountInDollars } = inject('sharedModel');
  const store = useStore();
  const { t } = useI18n();
  const cashdeskData = computed(() => store.getters['balanceState/BALANCE']);
</script>

<template>
  <div class="header-balance balance-name-value">
    <span>{{ t('balance') }}:</span>
    <span>&nbsp;&nbsp;{{ amountInDollars(cashdeskData?.depositNodeBalance) }}</span>
  </div>
  <div v-if="cashdeskData?.withdrawalNodeBalance" class="header-balance header-balance--frozen">
    <span>{{ t('frozen_amount') }}:</span>
    <span>&nbsp;&nbsp;{{ amountInDollars(cashdeskData?.withdrawalNodeBalance) }}</span>
  </div>
</template>
