<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const betVariant = ref('MULTIPLE');
const store = useStore();
const { t } = useI18n();

const outcomeLength = computed(() => store.getters['oddsBasketStore/GET_OUTCOME_LENGTH']);
const isBooking = computed(() => store.getters['oddsBasketStore/BOOKING']);

const systemArrFunc = computed(() => {
  const marketCounter = [];

  if (outcomeLength.value) {
    marketCounter.length = outcomeLength.value - 1;

    for (let index = 0; index < marketCounter.length; index++) {
      marketCounter.splice(index, 1, {
        value: index + 1,
        name: `System${ index + 1 }/${ outcomeLength.value }`
      })
    }
  }

  return marketCounter
})

const changeBetVariant = (bv) => {
  const $betVariant = bv.value ? bv.value : bv;
  betVariant.value = $betVariant;
  store.commit('oddsBasketStore/SET_BET_VARIANT', $betVariant);
}

const decrementBetVariant = () => {
  betVariant.value--;
  store.commit('oddsBasketStore/SET_BET_VARIANT', betVariant.value);
}

watch(
  () => outcomeLength.value,
  () => {
    if (Number.isInteger(betVariant.value) && outcomeLength.value > 2) {
       betVariant.value > systemArrFunc.value.length
         ? decrementBetVariant()
         : null;
    }

    if (outcomeLength.value <= 2 || !outcomeLength.value) {
      betVariant.value = 'MULTIPLE';
      store.commit('oddsBasketStore/SET_BET_VARIANT', betVariant.value );
    }
  }
)

watch(
  () => isBooking.value,
  () => {
    if (isBooking.value) {
      store.commit('oddsBasketStore/SET_BET_VARIANT', isBooking.value);
      betVariant.value = isBooking.value;
    }
  }
)

const systemDelValueOne = computed(() => {
  const systems = systemArrFunc.value;
  const findFirst = systems.findIndex((i) => i.value === 1);

  if (findFirst !== -1) {
    systems.splice(findFirst, 1)
  }
  return [
    {
      value: 'MULTIPLE',
      name: t('multiple')
    },
    ...systems
  ];
})

const baseDropDown = ref({
  options: computed(() => {
    return systemDelValueOne.value
  }),
  style: { width: '100%' },
  model: computed(() => {
    return betVariant.value
  }),
})
</script>

<template>
  <ui-dropdown
    v-if="baseDropDown.options && outcomeLength >= 3"
    :baseDropDown="baseDropDown"
    @selectedItem="changeBetVariant"
  />
</template>
