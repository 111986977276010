<script setup>
import { computed, ref, inject, shallowRef, onMounted, defineProps, watch } from 'vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import {
  deposit,
  withdraw,
  transactions,
  moneyTransfer,
  scanCupon,
  cancelBet
} from '@/features';

import PATHS from "@/app/route/PATHS";
const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useI18n();
const showCancelBet = ref(false);
const shiftPressed = ref(false);
const currentModal = ref(null);
const props = defineProps(['showCancelLastBet']);

const { amountInDollars } = inject('sharedModel');

const id = route.params.id;

const projectID = computed(() => store.getters['PROJECT_ID']);
const cashdeskData = computed(() => store.getters['balanceState/BALANCE']);

const updateBalance = () => {
  store.dispatch('balanceState/GET_BALANCE_DATA', { id: id, currency: localStorage['currency'] })
}

const goScanCoupon = () => {
  currentModal.value = {
    title: computed(() => t('scan_coupon')),
    component: shallowRef(scanCupon)
  };
};

const goCancelBet = (lastBetId) => {
  showCancelBet.value = true;

  currentModal.value = {
    title: computed(() => t('cancel_bet')),
    component: shallowRef(cancelBet),
    props: {
      lastBet: lastBetId
    }
  };
}

onMounted(() => {
  updateBalance();
  window.addEventListener('keydown', e => {
    if (e.keyCode === 16)
      shiftPressed.value = true;
  })
  window.addEventListener('keyup', e => {
    if (e.keyCode === 16)
      shiftPressed.value = false;
  })
  window.addEventListener('keydown', e => {
    if (shiftPressed.value) {
      switch(e.keyCode) {
        case 68:
          e.preventDefault();
          if (projectID.value !== 121) {
            currentModal.value = {
              title: computed(() => t('deposit')),
              component: shallowRef(deposit)
            };
          }
          break;
        case 87:
          e.preventDefault();
          if (projectID.value !== 121) {
            currentModal.value = {
              title: computed(() => t('withdraw')),
              component: shallowRef(withdraw)
            };
          }
          break;
        case 84:
          e.preventDefault();
          currentModal.value = {
            title: computed(() => `${ t('transaction') } (${ t('balance') } ${ amountInDollars(cashdeskData.value.depositNodeBalance) })`),
            component: shallowRef(transactions),
            windowSize: 'lg'
          };
          break;
        case 77:
          e.preventDefault();
          currentModal.value = {
            title: computed(() => `${ t('money_transfer') } (${ t('balance') } ${ amountInDollars(cashdeskData.value.depositNodeBalance) })`),
            component: shallowRef(moneyTransfer),
          };
          break;
        case 83:
          e.preventDefault();
          goScanCoupon();
          break;
        case 80:
          router.push(PATHS.PRINT_LINE.replace(':id', id));
          break;
      }
    }
  })
})

const buttonFactoryConfig = [
  {
    show: projectID.value !== 121,
    text: computed(() => t('deposit')),
    variant: 'btn-brand',
    func: () => {
      currentModal.value = {
        title: computed(() => t('deposit')),
        component: shallowRef(deposit)
      }
    },
    title: 'shift + d'
  },
  {
    show: projectID.value !== 121,
    text: computed(() => t('withdraw')),
    disabled: false,
    variant: 'btn-brand',
    func: () => {
      currentModal.value = {
        title: computed(() => t('withdraw')),
        component: shallowRef(withdraw)
      }
    },
    title: 'shift + w'
  },
  {
    text: computed(() => t('transaction')),
    variant: 'btn-brand',
    func: () => {
      currentModal.value = {
        title: computed(() =>
          (`${t('transaction')} (${t('balance')} ${amountInDollars(cashdeskData.value.depositNodeBalance)})`)
        ),
        component: shallowRef(transactions),
        windowSize: 'lg'
      }
    },
    show: computed(() => !store.getters['cashdeskConfigStore/SWITCH_MONITOR_VIEW'])
  },
  {
    text: computed(() => t('money_transfer')),
    disabled: false,
    variant: 'btn-brand',
    func: () => {
      currentModal.value = {
        title: computed(() =>
          `${ t('money_transfer') } (${ t('balance') } ${ amountInDollars(cashdeskData.value.depositNodeBalance) })`
        ),
        component: shallowRef(moneyTransfer),
      };
    },
    title: 'shift + m',
    show: computed(() => !store.getters['cashdeskConfigStore/SWITCH_MONITOR_VIEW'])
  },
  {
    text: computed(() => t('scan_coupon')),
    disabled: false,
    variant: 'btn-brand',
    func: () => goScanCoupon(),
    style: null,
    title: 'shift + s'
  },
  {
    text: computed(() => t('print_line')),
    disabled: false,
    variant: 'btn-brand',
    func: () => router.push(PATHS.PRINT_LINE.replace(':id', id)),
    style: null,
    title: 'shift + p'
  },
  {
    text: computed(() => t('cancel_bet')),
    disabled: false,
    variant: 'btn-brand',
    func: () => goCancelBet(),
    style: null,
    title: 'shift + c'
  }
]

watch(
  () => props.showCancelLastBet,
  () => {
    if (props.showCancelLastBet) {
      goCancelBet(props.showCancelLastBet)
    }
  }
)


</script>

<template>
  <div class="button-navbar_container">
    <ui-button-factory
      :buttonFactoryConfig="buttonFactoryConfig"
    />
    <ui-dialog
      v-if="currentModal"
      :size="currentModal.windowSize || 'md'"
      :title="currentModal.title"
      @close="currentModal = null"
    >
      <template #main>
        <component
          @close="currentModal = null"
          :is="currentModal.component"
          v-bind="currentModal.props"
        />
      </template>
    </ui-dialog>
  </div>
</template>
