import { computed } from 'vue';
import { store } from '@/app/store/store';
import { outcomes } from './outcomes';

const betVariant = computed(() => store.getters['oddsBasketStore/GET_BET_VARIANT']);
const changeCoefficient = computed(() => store.getters['oddsBasketStore/GET_CHANGE_COEFFICIENT']);
const betType = computed(() => store.getters['oddsBasketStore/GET_BET_TYPE'])


export const calcMaxBet = () => {
  let systemNum = null;

  if (Number.isInteger(betVariant.value)) {
    systemNum = betVariant.value;
  }

  const bets = [{
    acceptOddsChanges: changeCoefficient.value,
    acceptParameterChanges: changeCoefficient.value,
    betType: betType.value,//betType,
    outcomes: [...outcomes.value],
    locale: localStorage['lang'],
    betMoney: {
      betAmount: 1,
      currency: localStorage['currency']
    },
    requestId: 1
  }];

  if (systemNum != null) {
    bets[0].winCombinationSize = systemNum;
  }


  store.dispatch('oddsBasketStore/MAX_BET', { bets });
};
