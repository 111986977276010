<script setup>
import {
  ref,
  computed,
  onMounted,
  defineProps,
  watch,
  onBeforeUnmount
} from 'vue';
import { OutcomeService } from '@atlas/core';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

/* eslint-disable */
const { t } = useI18n();
const store = useStore();
const props = defineProps({ selectedFilter: String })
const selectedFilters = ref(props.selectedFilter);
const hiddenMarkets = ref({});
const storeData = computed(() => store.getters['eventState/GET_EVENT_DATA']);


watch(() => props.selectedFilter, () => {
  selectedFilters.value = props.selectedFilter;
})

onMounted(() => {
  let mybutton = document.getElementById('scrollTop');

  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
});

const toggleMarkets = () => {
  if (Object.keys(hiddenMarkets.value).length) {
    hiddenMarkets.value = {};
  } else {
    for (let market of storeData.value?.formattedMarketTypes) {
      hiddenMarkets.value[market.id] = true;
    }
  }
};

const hideMarket = (marketType) => {
  if (hiddenMarkets.value[marketType.id]) {
    delete hiddenMarkets.value[marketType.id]
  } else {
    hiddenMarkets.value[marketType.id] = true;
  }
};

const topFunction = () => {
  window.scrollTo({top: 0, behavior: 'smooth'});
}

const market_type = (id) => {
  let a;
  Object.values(storeData.value.eventData.marketTypes)
    .forEach(item => {
      const f = Object.values(item.markets).find(a => a.id === id);
      if (f) {
        a = item;
      }
    })
  return a;
}

const addOdds = (data) => {
  if (data.status === 'ACTIVE') {
    const m_t = market_type(data.marketId)

    const oddsData = {
      event: storeData.value.eventData,
      outcome: {
        ...data,
        bet_groups: m_t.betGroups,
        marketName: m_t.name,
        market_id: data.marketId,
        marketId: m_t.id
      }
    }

    if (selectedOutcome(data)) {
      store.commit('oddsBasketStore/ODDS_BASKET_DELETE_ITEM', {
        item: {
          event_id: storeData.value.eventData.id,
        },
        marketId: m_t.id
      });
    } else {
      store.commit('oddsBasketStore/ODDS_BASKET_ADD_ITEM', oddsData)
    }
  } else {
    store.commit('notificationsStore/ADD_ERROR_DATA', {
      header: t('validation_error'),
      body: t('outcome_not_active'),
    });
  }
}

const selectedOutcome = (data) => {
  const findEvent = store.getters["oddsBasketStore/ODDS_BASKET"]
    .find(id => id.event_id === storeData.value.eventData.id);
  const m_t = market_type(data.marketId);

  if (findEvent) {
    const findMarket = findEvent.market.find(id => +id.marketId === +m_t.id);

    if (findMarket) {
      return +findMarket.outcomes.id === +data.id;
    }
  }

  return false;
}

const showMarketType = (marketType) => {
  console.log('marketType', marketType)
  if (selectedFilters.value !== 'all') {
    try {
      const marketId = marketType.rows[0][1].marketId;
      const market = market_type(marketId);
      const findeGroup = market.groups
        .find(g => (g)
          .toLowerCase()
          .slice(0, g.indexOf('_') != '-1' ? g.indexOf('_') : g.length)
          .replace('football', 'players')
          .replace('yellow', 'cards')
          .replace('interim', 'minutes') === selectedFilters.value);
      if (findeGroup) {
        return true;
      }
      return false;
    } catch {
    }
  } else {
    return true;
  }
}

const findParticipants = name => {
  const participants = storeData.value.eventData.participants;
  return name.replace('(Competitor1)', participants?.home?.fullName)
    .replace('(Competitor2)', participants?.away?.fullName)
}

onBeforeUnmount(() => {
  store.commit('eventState/SET_EVENT_DATA', null);
})

const oddsFormat = (odds) => {
  const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
  return OutcomeService.formatOdds(odds, { oddsFormat })
}

</script>

<template>
  <div class="layout-actions">
      <div class="layout-toggle-all-markets" @click="toggleMarkets">
        <svg class="base-svg-size">
          <use xlink:href="../../../shared/assets/show.svg#show"></use>
        </svg>
      </div>
    </div>
  <div class="layout-two-column" >
      <template v-for="(market_type, index) in storeData?.formattedMarketTypes">
        <ui-card
          :key="index"
          v-if="showMarketType(market_type) && market_type.rows.length"
          class="column-item"
        >
          <template v-slot:card-header>
            <div class="market-header" @click="hideMarket(market_type)">
              <div><b>{{ findParticipants(market_type.title) }}</b></div>
              <div class="market-header__right" v-if="market_type.betGroups.length">
                <div>
                  <svg class="base-svg-size">
                    <use xlink:href="../../../shared/assets/link.svg#link"></use>
                  </svg>
                </div>
                <div>{{ market_type.betGroups.join(",") }}</div>
                <div class="market-type_header_separator"></div>
                <div class="market-btn-hide">
                  <svg class="base-svg-size" :class="{ 'rotate-item-90': !hiddenMarkets[market_type.id] }">
                    <use xlink:href="../../../shared/assets/caret-left.svg#caret-left"></use>
                  </svg>
                </div>
              </div>
            </div>
          </template>
          <template v-if="!hiddenMarkets[market_type.id]" v-slot:card-body>
            <div
              class="market"
              style="margin-bottom: 4px;"
            >
              <div class="market-body" v-if="!hiddenMarkets[market_type.id]">
                <div class="layout_head-market-container" v-if="market_type.header">
                  <div
                    v-for="(head, index) in market_type.header"
                    :key="index"
                    class="layout_head-item-container"
                  >
                    <span v-if="head">
                      {{ head }}
                    </span>
                  </div>
                </div>
                <div
                  v-for="outcome in market_type.rows"
                  :key="outcome.id"
                  class="odds-container"
                >
                  <div
                    v-for="odds in outcome"
                    :key="odds.id"
                    class="odds-item-container"
                  >
                    <template v-if="!odds.isFake">
                      <div
                        class="odds_item"
                        @click="addOdds(odds)"
                        :class="{ 'active-item': selectedOutcome(odds) }"
                      >
                        <template v-if="odds.status === 'ACTIVE'">
                    <span>
                      {{ odds.name }}
                    </span>
                          <span>
                          <div v-if="odds.chengeOdds === 'UP'" class="triangle-up"></div>
                          <div v-if="odds.chengeOdds === 'DOWN'" class="triangle-down"></div>
                      <b
                        :class="{
                          'color-odd': !odds.chengeOdds,
                          'color-green': odds.chengeOdds === 'UP',
                          'color-red': odds.chengeOdds === 'DOWN'
                        }"
                      >
                        {{ oddsFormat(+odds.odds) }}
                      </b>
                    </span>
                        </template>
                        <template v-else>
                          <div class="lock-icon-container">
                            <svg class="base-svg-size">
                              <use xlink:href="../../../shared/assets/lock.svg#lock"></use>
                            </svg>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-else-if="odds.isFake && odds.name">
                      <div class="odds-fake-name">
                        {{ odds.name }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ui-card>
      </template>
    </div>
  <button id="scrollTop" type="button" @click="topFunction">
    <svg class="base-svg-size rotate-item-plus-90">
      <use xlink:href="../../../shared/assets/caret-left.svg#caret-left"></use>
    </svg>
  </button>
</template>
