import i18n from '@/app/i18n';
import { store } from '@/app/store/store.js';

const { t } = i18n.global;

export const useSearchResult = async (inputValue) => {
  if (!isNaN(inputValue)) {
    await store.dispatch('eventState/FIND_EVENT_BY_TAG', inputValue);

  } else {
    if (inputValue.length >= 3) {
      await store.dispatch('eventState/GET_EVENTS_BY_NAME', inputValue);
    } else {
      store.commit('notificationsStore/ADD_ERROR_DATA', {
        header: t('validation_error'),
        body: t('more_than_two_characters_required'),
      });
    }
  }
}
