<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();
const checked = ref(false);

checked.value = store.getters['cashdeskConfigStore/SWITCH_MONITOR_VIEW'];

const switchMessageTitle = computed(() =>
  checked.value ? t('switch_to_cashier_view') : t('switch_to_clients_view')
);

const text = computed(() =>
  checked.value ? t('clients_view') : t('cashiers_view')
)

const changeChecked = (value) => {
  checked.value = value;
  if (!localStorage['switchMonitorView']) {
    localStorage.setItem('switchMonitorView', JSON.stringify(checked.value));
  } else {
    localStorage['switchMonitorView'] = JSON.stringify(checked.value);
  }
  store.commit('cashdeskConfigStore/CHANGE_SWITCH_MONITOR_VIEW', checked.value);
}

</script>

<template>
  <div class="items-sm-container">
    <span>{{ text }}</span>
    <ui-checkbox :title="switchMessageTitle" :state="checked" @switch="changeChecked" />
  </div>
</template>
