<script setup>
import { ref, onMounted, nextTick, computed } from 'vue';
import { useRoute } from 'vue-router';
import { errorCodeObject } from '@/entities';
import { useStore } from 'vuex';

import PATHS from '@/app/route/PATHS.js';
import router from '@/app/route/route.js';
import { useI18n } from 'vue-i18n';

import requestForPrintData from './requestForPrintData/requestForPrintData.js';
import print from './print/print.vue';

import { dashboardHeader } from '@/widgets';

import SportData from '@/shared/api/sport-data/sportData.js';
const sportData = new SportData();

const { t } = useI18n();
const store = useStore();
const sportArr = ref(null);
const route = useRoute();
const id = route.params.id;
const printData = ref(null);
const selectedPeriod = ref('24h');
const showAttention = ref(false);

const allMainTypes = computed(() =>
  store.getters['tournamentsStore/GET_SPORT_FORMS']
);

const goBackToCashDesck = () => {
  router.push(PATHS.CASHDESK_ID.replace(':id', id));
}

const dateFilter = computed(() => {
  let filter;
  switch (selectedPeriod.value) {
    case 'week':
      filter = {
        fromDate: new Date(),
        timeHours: 24,
        toDate: new Date(new Date().setDate(new Date().getDate() + 6)),
        dateFilterType: null
      }
      break;
    default:
      filter = {
        fromDate: null,
        timeHours: 24,
        toDate: null,
        dateFilterType: '24h'
      }
  }

  return filter;
})

const requestArr = ref([]);

const updateBalance = () => {
  store.dispatch('balanceState/GET_BALANCE_DATA', { id: id, currency: localStorage['currency'] })
}

const addSport = () => {
  requestArr.value.push({
    id: Number(new Date()),
    ids: {
      sportId: '',
      categoryId: '',
      tournamentIds: []
    },
    filterArray: {
      categiriesArr: [],
      tournamentArr: []
    }
  })
}

const getSportList = () => {
  nextTick(() => {
    sportData.getSportList({
      filter: {
        ...dateFilter.value,
        sportServices: ["PREMATCH"],
      }
    })
    .then(resp => {
      sportArr.value = resp.data.data;
    })
    .catch(err => {
      errorCodeObject(err);
      console.log(err);
    })
  })
}

onMounted(() => {
  updateBalance();
  getSportList();

  requestArr.value.push({
    id: Number(new Date()),
    ids: {
      sportId: '',
      categoryId: '',
      tournamentIds: []
    },
    filterArray: {
      categiriesArr: [],
      tournamentArr: []
    }
  })
})

const addTournamentId = (tournament, row) => {
  const findId = row.ids.tournamentIds
    .findIndex((i) => i.id === tournament.id);

  if (findId === -1) {
    const addObj = {
      sportFormId: tournament?.sportForm.id,
      id: tournament.id,
      mainTypes: allMainTypes.value[tournament.sportForm.id]?.MAIN,
    }

    row.ids.tournamentIds.push(addObj)
  } else {

    row.ids.tournamentIds.splice(findId, 1)
  }

  const checkSportFormDifference = () => {
    const tournamentsSportforms = row.ids.tournamentIds.map(obj => obj.sportFormId);
    const uniqueSportForms = new Set(tournamentsSportforms);

    if (uniqueSportForms.size > 1) {
      const trueSportform = [...uniqueSportForms][0];

      row.ids.tournamentIds.forEach(obj => {
        if (obj.sportFormId !== trueSportform) {
          obj.dangerTypes = true;
        } else {
          if (obj.dangerTypes) {
            delete obj.dangerTypes;
          }
        }
      })
    } else {
      row.ids.tournamentIds.forEach(obj => {
        if (obj.dangerTypes) {
          delete obj.dangerTypes;
        }
      })
    }
  }

  checkSportFormDifference();

  const hasInvalidSportform = row.ids.tournamentIds.find((i) => i.dangerTypes);

  showAttention.value = !!hasInvalidSportform;
}

const clearArr = () => {
  requestArr.value = [
    {
      id: Number(new Date()),
      ids: {
        sportId: '',
        categoryId: '',
        tournamentIds: []
      },
      filterArray: {
        categiriesArr: [],
        tournamentArr: []
      }
    }
  ]
  showAttention.value = false;
}

const closePrint = () => {
  printData.value = null;
  clearArr();
}

const deleteItem = (id) => {
  const index =  requestArr.value.findIndex(i => i.id === id);
  if (index !== -1)
    requestArr.value.splice(index, 1);

  showAttention.value = false;
}

const findEmptyArr = computed(() => requestArr.value.find((i) => !i.ids.tournamentIds.length))

const printLogoUrl = computed(() => store.getters['cashdeskConfigStore/GET_PRINT_LOGO_URL']);

const sportDropdownConfig = (item) => {
  return {
    options: computed(() => sportArr.value),
    baseText: computed(() => t('sport')),
    style: { width: '100%' },
    disabled: computed(() => item.ids.categoryId !== '' && item.ids.sportId !== '')
  }
}

const categoryDropdownConfig = (item) => {
  return {
    options: computed(() => item.filterArray.categiriesArr),
    baseText: computed(() => t('category')),
    style: { width: '100%' },
    disabled: computed(() => {
      return item.ids.categoryId !== '' && item.ids.sportId !== ''
    })
  }
}

const addSportButtonConfig = {
  variant: 'btn-primary',
  func: addSport,
  text: computed(() => t('add_sport'))
}

const footerButtonsConfig = [
  {
    variant: 'btn-danger',
    func: clearArr,
    text: computed(() => t('clear'))
  },
  {
    text: t('print'),
    disabled: computed(() => !!findEmptyArr.value || !requestArr.value.length),
    variant: 'btn-brand',
    func: async () => {
      const getAllTournaments = requestArr.value.reduce((tournamens, item) => {
        tournamens.push(...item.filterArray.tournamentArr);
        return tournamens;
      }, [])

      store.commit('printLineStore/CHANGE_PRINT_LINE_TOURNAMENT_ARR', getAllTournaments)

      printData.value = await requestForPrintData(requestArr.value, dateFilter.value);
    },
  }
]

const dropDownGetCategories = (e, item) => {
  item.ids.sportId = e.id;
  nextTick(() => {
    sportData.getCategories({
      filter: {
        ...dateFilter.value,
        sportId: e.id,
        sportServices: ["PREMATCH"],
      }
    })
      .then(resp => {
        item.filterArray.categiriesArr = resp.data.data;
      })
      .catch(err => {
        errorCodeObject(err);
        console.log(err);
      })
  })
}

const dropdownGetTournaments = (e, item) => {
  item.ids.categoryId = e.id;
  nextTick(() => {
    sportData.getTournamentList({
      filter: {
        ...dateFilter.value,
        sportId: item.ids.sportId,
        categoryId: e.id,
        sportServices: ["PREMATCH"],
      }
    })
      .then(resp => {
        if (resp.data.data.length) {
          item.filterArray.tournamentArr = resp.data.data;
          console.log('resp.data.data', resp.data.data);
        } else {
          item.filterArray.tournamentArr = t('no_data');
        }
      })
      .catch(err => {
        errorCodeObject(err);
        console.log(err)
      })
  })
}

</script>

<template>
  <dashboardHeader class="header-container" />
  <div class="print-line-container">
      <div class="print-line_btn-logo-container">
        <svg @click="goBackToCashDesck">
          <use xlink:href="../../shared/assets/back-arrow.svg#back-arrow"></use>
        </svg>
        <img class="print-line_logo" alt="logo" :src="printLogoUrl" />
      </div>

      <div
        class="print-lint_request-arr-item"
        v-for="item in requestArr"
        :key="item.id"
      >
        <div class="print-lint_x-close" @click="deleteItem(item.id)">
          <img alt="close" src="../../shared/assets/close.svg" />
        </div>

        <div class="print-line_select-container">
          <ui-dropdown
            :baseDropDown="sportDropdownConfig(item)"
            @selectedItem="dropDownGetCategories($event, item)"
           />

          <ui-dropdown
            :baseDropDown="categoryDropdownConfig(item)"
            @selectedItem="dropdownGetTournaments($event, item)"
          />

          <div class="print-line_tournament-list">
            <div class="print-line_tournament-list-container">
              <template v-if="Array.isArray(item.filterArray.tournamentArr)">
                <div
                  class="print-line_tournament-item-container"
                  v-for="tournament in item.filterArray.tournamentArr"
                  :key="tournament.id"
                >
                  <div
                    class="print-line_tournament-item"
                    @click="addTournamentId(tournament, item)"
                    :class="{
                        'active-item': item.ids.tournamentIds.find(i => i.id === tournament.id),
                        'print-line_danger_types': item.ids.tournamentIds.find(i => i.id === tournament.id)?.dangerTypes
                      }"
                  >
                    {{ tournament.name }}
                  </div>
                </div>
                <span
                  v-if="item.ids.tournamentIds.filter(i => i.dangerTypes).length"
                  class="print-line_attention"
                >
                  {{ $t("attention_tournament_has_a_different_spore_form") }}
                </span>
              </template>
              <template v-else>
                <h6 style="color: grey; padding: 2px;">
                  {{ item.filterArray.tournamentArr }}
                </h6>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="add-btn-container">
        <ui-button :baseBtnConfig="addSportButtonConfig" />
      </div>

      <div class="print-line_footer-buttons">
        <ui-button-factory
          :buttonFactoryConfig="footerButtonsConfig"
        />
      </div>
    </div>

  <print v-if="printData"
     :selectedPeriod="selectedPeriod"
     :printData="printData"
     @close="closePrint"
  />
</template>
