const ERROR_BET = {
  MAX_BET_AMOUNT_EXCEEDED: 'Max bet amount exceeds',
  MIN_BET_AMOUNT_EXCEEDED: 'Min bet amount is',
  NEW_ODDS: 'new odds',
  OUTCOME_INACTIVE: 'Outcome inactive',
  ONLY_SINGLE_OUTCOME: 'Is available only in single',
  MAX_ODDS_AMOUNT_EXCEEDED: 'Max odds amount exceeds',
  MAX_OUTCOMES_SIZE_EXCEEDED: 'Max outcomes size exceeds',
  MAX_OUTCOMES_SIZE_IN_BLOCK_EXCEEDED: 'Max outcomes size in block exceeds',
  MAX_BLOCK_SIZE_EXCEEDED: 'Max block size exceeds',
  CLIENT_SELF_EXCLUDED: 'Client self exceeds',
  BET_TYPE_NOT_ALLOWED: 'Bet type not allowed',
  PREMATCH_DELAY_EXCEEDED: 'Prematch delay exceeds',
  CASH_OUT_AMOUNT_CHANGED: 'Cash out amount changed',
  MAX_CLIENT_WIN_PER_EVENT_EXCEEDED: 'Max client win per event exceeds',
  CASH_OUT_NOT_ALLOWED: 'Cash out not allowed',
  EDIT_NOT_ALLOWED: 'Edit not allowed',
  NOT_ENOUGH_FUNDS: 'Not enough funds',
  ODDS_VALUE_LESS_THEN_MIN: 'Odds value less then min'
}

export default ERROR_BET;